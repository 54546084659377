import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ActionPlanService {
  constructor(private readonly http: HttpClient) {}

  getActionPlan(inspectionId: string) {
    return this.http.get(
      `${environment.API_BASE}/actionplan/?inspectionId=${inspectionId}`,
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }
}
