import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Sort, MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { RecurrentInspectionAggregate } from '../../../models';

@Component({
  selector: 'backoffice-recurrent-aggregate-table',
  templateUrl: './recurrent-aggregate-table.component.html',
  styleUrls: ['./recurrent-aggregate-table.component.scss'],
})
export class RecurrentAggregateTableComponent implements OnInit {
  @Input() aggregates!: RecurrentInspectionAggregate[];
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  public selection = new SelectionModel<RecurrentInspectionAggregate>(true);

  readonly initialSort = {
    active: 'endDate',
    direction: 'desc',
  } as Sort;

  readonly displayedColumns: string[] = [
    'select',
    'internalNote',
    'atwId',
    'typeDisplayName',
    'name',
    'equipmentNumber',
    'building',
    'nextInspectionDate',
    'remainingDays',
  ];

  dataSource!: MatTableDataSource<RecurrentInspectionAggregate>;

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.aggregates);
    this.dataSource.sort = this.sort;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.areAllSelected()
      ? this.selection.clear()
      : this.aggregates.forEach((row) => {
          if (!(row.alreadyInLaterInspection || row.shutdown !== 0)) {
            this.selection.select(row);
          }
        });
  }

  areAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.aggregates.filter(
      (aggregate) =>
        !(aggregate.alreadyInLaterInspection || aggregate.shutdown !== 0)
    ).length;
    return numSelected === numRows;
  }
}
