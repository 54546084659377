<mat-form-field subscriptSizing="dynamic">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [matDatepicker]="pickerDate"
    [formControl]="dateControl"
    [matDatepickerFilter]="$any(dateFilter)"
    (keydown.enter)="$any($event.target).blur()"
  />
  <mat-datepicker #pickerDate></mat-datepicker>

  <span matSuffix>
    <button
      matTooltip="Feld zurücksetzen"
      *ngIf="dateControl.value"
      mat-icon-button
      (click)="clear()"
    >
      <mat-icon>clear</mat-icon>
    </button>
    <mat-datepicker-toggle [for]="pickerDate">
      <mat-icon matDatepickerToggleIcon>event</mat-icon>
    </mat-datepicker-toggle>
  </span>

  <mat-error *ngIf="dateControl.hasError('invalid')">
    Ungültiges Datum
  </mat-error>
</mat-form-field>
