<h1 class="mat-h1">
  Wiederholungsinspektion {{ (inspection$ | async)?.inspectionNumber }}
  <a mat-icon-button matTooltip="Zur Detailansicht springen" [routerLink]="['/inspections/all', inspectionId]" target="_blank">
    <mat-icon>open_in_new</mat-icon>
  </a>
</h1>

<div *ngIf="inspection$ | async as inspection; else loadingTemplate">
  <p>
    <backoffice-create-excel-button
      [isLoadingExcel]="isLoadingExcel"
      [disabled]="(planningExcelDownloadEnabled$ | async) === false || isLoadingExcel"
      (click)="generatePlanningExcel()"
    />
  </p>

  <mat-card class="RecurrentInspectionDetail__Overview">
    <div>
      <h3>Inspektions-Art</h3>
      <p class="RecurrentInspectionDetail__InspectionKind" [ngClass]="'RecurrentInspectionDetail__InspectionKind--VENTILATION_SYSTEMS'">
        <mat-icon class="material-symbols-outlined">{{ 'inspections.kind.icon' | enum }}</mat-icon>
        {{ 'inspections.kind.text' | enum }}
      </p>
    </div>
    <div>
      <h3>Inspektions-Datum</h3>
      <p>
        <backoffice-start-date-assignment [inspection]="inspection" />-
        {{ inspection.endDate | date }}
      </p>
    </div>
    <div>
      <h3>Inspekteure</h3>
      <backoffice-inspector-assignment [inspection]="inspection" />
    </div>

    <div class="RecurrentInspectionDetail__NextInspectionDueDate">
      <h3>Früheste Wiederholungs-inspektion</h3>
      <p>{{ inspection.hygieneData?.nextInspectionDueDate | date }}</p>
    </div>

    <div>
      <h3>Auftraggeber</h3>
      <p>
        {{ inspection.customer.name }}<br />
        {{ inspection.customer.street }}<br />
        {{ inspection.customer.zip }}<br />
        {{ inspection.customer.city }}
      </p>
    </div>
    <div>
      <h3>Anlagennutzer</h3>
      <p *ngIf="inspection.aggregates.length > 0">
        {{ inspection.aggregates[0].operator.name }}<br />
        {{ inspection.aggregates[0].operator.street }}<br />
        {{ inspection.aggregates[0].operator.zip }}<br />
        {{ inspection.aggregates[0].operator.city }}
      </p>
    </div>
    <div>
      <h3>Standort</h3>
      <p>
        {{ inspection.location.name }}<br />
        {{ inspection.location.street }}<br />
        {{ inspection.location.zip }}<br />
        {{ inspection.location.city }}
      </p>
    </div>
  </mat-card>

  <backoffice-internal-notes [inspection]="inspection" />

  <div>
    <h2 class="mat-h2">{{ inspection.aggregates.length }} Anlagen</h2>

    <backoffice-recurrent-aggregate-table [aggregates]="inspection.aggregates" />

    <div *ngIf="inspection.otherAggregates">
      <h2 class="mat-h2">{{ inspection.otherAggregates.length }} weitere Anlagen an diesem Standort</h2>

      <backoffice-recurrent-aggregate-table [aggregates]="inspection.otherAggregates" />
    </div>
  </div>
</div>

<ng-template #loadingTemplate> Lädt... </ng-template>
