<form [formGroup]="searchForm">
  <mat-card>
    <div formGroupName="inspectionData">
      <!-- Filters related to inspections data of aggregates -->
      <div class="permanent-filters">
        <button
          mat-stroked-button
          color="primary"
          [ngStyle]="{
            'background-color': isAnyInspectionFilterSet ? '#bcdef7' : 'transparent'
          }"
        >
          <mat-icon></mat-icon> Inspektion
        </button>

        <backoffice-numberfield-filter
          style="width: 160px"
          formControlName="inspectionNumber"
          label="Inspektionsnummer"
          suggestionField="inspection/inspectionNumber"
          [cognitiveSearchIndex]="aggregateIndex"
        >
        </backoffice-numberfield-filter>
        <backoffice-textfield-filter
          style="width: 300px"
          formControlName="customer"
          label="Auftraggeber"
          suggestionField="inspection/customer/name"
          [cognitiveSearchIndex]="aggregateIndex"
        ></backoffice-textfield-filter>
        <backoffice-textfield-filter
          style="width: 300px"
          formControlName="location"
          label="Standort"
          suggestionField="inspection/location/name"
          [cognitiveSearchIndex]="aggregateIndex"
        ></backoffice-textfield-filter>
        <backoffice-textfield-filter
          style="width: 300px"
          formControlName="operator"
          label="Anlagennutzer"
          suggestionField="inspection/operator/name"
          [cognitiveSearchIndex]="aggregateIndex"
        ></backoffice-textfield-filter>
      </div>
    </div>
    <div formGroupName="aggregateData">
      <!-- Filters related to aggregates -->
      <div class="permanent-filters">
        <button
          matTooltip="Blende weitere Filter auf Anlagenebene {{ expandAggregateFilters ? 'aus' : 'ein' }}"
          mat-stroked-button
          color="primary"
          (click)="toggleAggregateFilters()"
          [ngStyle]="{
            'background-color': isAnyAggregateGeneralFilterSet ? '#bcdef7' : 'transparent'
          }"
        >
          <mat-icon>{{ expandAggregateFilters ? 'expand_more' : 'expand_less' }}</mat-icon>
          Anlage
        </button>

        <backoffice-textfield-filter
          style="width: 200px"
          formControlName="name"
          label="Anlagenname"
          suggestionField="name"
          [cognitiveSearchIndex]="aggregateIndex"
        >
        </backoffice-textfield-filter>
        <backoffice-numberfield-filter
          style="width: 175px"
          formControlName="atwId"
          label="ATW-ID"
          suggestionField="atwId"
          [cognitiveSearchIndex]="aggregateIndex"
        >
        </backoffice-numberfield-filter>
        <backoffice-numberfield-filter
          style="width: 140px"
          formControlName="reportPdfId"
          label="Berichtsnummer"
          suggestionField="reportPdfId"
          [cognitiveSearchIndex]="aggregateIndex"
        >
        </backoffice-numberfield-filter>
        <backoffice-aggregate-type style="width: 200px" formControlName="type" label="Typ"></backoffice-aggregate-type>
        <backoffice-aggregate-sub-type style="width: 200px" formControlName="subType" label="Sub-Typ"> </backoffice-aggregate-sub-type>
      </div>
      <div [hidden]="!expandAggregateFilters">
        <div class="hidable-filters">
          <backoffice-aggregate-shutdown style="width: 90px" formControlName="shutdown" label="Aktiv"></backoffice-aggregate-shutdown>
          <backoffice-textfield-filter
            style="width: 190px"
            formControlName="equipmentNumber"
            label="Equipment-Nummer"
            suggestionField="equipmentNumber"
            [cognitiveSearchIndex]="aggregateIndex"
          >
          </backoffice-textfield-filter>
          <backoffice-textfield-filter
            style="width: 190px"
            formControlName="building"
            label="Gebäude"
            suggestionField="building"
            [cognitiveSearchIndex]="aggregateIndex"
          >
          </backoffice-textfield-filter>
          <backoffice-textfield-filter
            style="width: 190px"
            formControlName="floor"
            label="Stockwerk"
            suggestionField="floor"
            [cognitiveSearchIndex]="aggregateIndex"
          >
          </backoffice-textfield-filter>
          <backoffice-textfield-filter
            style="width: 190px"
            formControlName="installationSite"
            label="Aufstellungsort"
            suggestionField="installationSite"
            [cognitiveSearchIndex]="aggregateIndex"
          >
          </backoffice-textfield-filter>
        </div>
      </div>
    </div>

    <div formGroupName="hygieneAggregateData">
      <!-- Filters related to hygiene data of aggregates -->
      <div class="permanent-filters">
        <button
          matTooltip="Blende weitere hygienespezifische Filter auf Anlagenebene {{ expandAggregateFilters ? 'aus' : 'ein' }}"
          mat-stroked-button
          color="primary"
          (click)="toggleHygieneAggregateFilters()"
          [ngStyle]="{
            'background-color': isAnyAggregateHygieneFilterSet ? '#bcdef7' : 'transparent'
          }"
        >
          <mat-icon>{{ expandHygieneAggregateFilters ? 'expand_more' : 'expand_less' }}</mat-icon>
          Hygiene
        </button>
        <backoffice-aggregate-hygiene-inspection-type
          style="width: 200px"
          formControlName="inspectionTypes"
          label="Inspektionstyp"
        ></backoffice-aggregate-hygiene-inspection-type>
        <backoffice-aggregate-status
          style="width: 200px"
          formControlName="inspectionStatuses"
          label="Inspektionsstatus"
        ></backoffice-aggregate-status>
        <backoffice-textfield-filter
          style="width: 200px"
          formControlName="inspector"
          label="Inspekteur"
          suggestionField="hygieneData/inspector"
          [cognitiveSearchIndex]="aggregateIndex"
        >
        </backoffice-textfield-filter>
        <backoffice-aggregate-shutdown
          style="width: 140px"
          formControlName="shutdown"
          label="Prüfpflichtig"
        ></backoffice-aggregate-shutdown>
        <backoffice-checkbox-filter formControlName="isMostRecent" label="Nur letzte Inspektionen"></backoffice-checkbox-filter>
      </div>

      <div [hidden]="!expandHygieneAggregateFilters">
        <div class="hidable-filters">
          <backoffice-date formControlName="inspectionDateFrom" label="Inspektion von"></backoffice-date>
          <backoffice-date formControlName="inspectionDateTo" label="Inspektion bis"></backoffice-date>
          <backoffice-date style="width: 180px" formControlName="nextInspectionDateFrom" label="Wiederholung von"></backoffice-date>
          <backoffice-date style="width: 180px" formControlName="nextInspectionDateTo" label="Wiederholung bis"></backoffice-date>
        </div>
      </div>
    </div>

    <div formGroupName="energeticAggregateData">
      <!-- Filters related to energetic data of aggregates -->
      <div class="permanent-filters">
        <button
          matTooltip="Blende weitere energetischspezifische Filter auf Anlagenebene {{ expandAggregateFilters ? 'aus' : 'ein' }}"
          mat-stroked-button
          color="primary"
          (click)="toggleEnergeticAggregateFilters()"
          [ngStyle]="{
            'background-color': isAnyAggregateEnergeticFilterSet ? '#bcdef7' : 'transparent'
          }"
        >
          <mat-icon>{{ expandEnergeticAggregateFilters ? 'expand_more' : 'expand_less' }}</mat-icon>
          Energetische
        </button>
        <backoffice-aggregate-energetic-inspection-type
          style="width: 200px"
          formControlName="inspectionTypes"
          label="Inspektionstyp"
        ></backoffice-aggregate-energetic-inspection-type>
        <backoffice-aggregate-status
          style="width: 200px"
          formControlName="inspectionStatuses"
          label="Inspektionsstatus"
        ></backoffice-aggregate-status>
        <backoffice-textfield-filter
          style="width: 200px"
          formControlName="inspector"
          label="Inspekteur"
          suggestionField="energeticData/inspector"
          [cognitiveSearchIndex]="aggregateIndex"
        >
        </backoffice-textfield-filter>
        <backoffice-aggregate-shutdown
          style="width: 140px"
          formControlName="shutdown"
          label="Prüfpflichtig"
        ></backoffice-aggregate-shutdown>
        <backoffice-checkbox-filter formControlName="isMostRecent" label="Nur letzte Inspektionen"></backoffice-checkbox-filter>
      </div>

      <div [hidden]="!expandEnergeticAggregateFilters">
        <div class="hidable-filters">
          <backoffice-date formControlName="inspectionDateFrom" label="Inspektion von"></backoffice-date>
          <backoffice-date formControlName="inspectionDateTo" label="Inspektion bis"></backoffice-date>
          <backoffice-date style="width: 180px" formControlName="nextInspectionDateFrom" label="Wiederholung von"></backoffice-date>
          <backoffice-date style="width: 180px" formControlName="nextInspectionDateTo" label="Wiederholung bis"></backoffice-date>
        </div>
      </div>
    </div>

    <!-- Buttons at the bottom of the search -->
    <div class="searchbar-buttons">
      <button matTooltip="Filter erneut anwenden" mat-stroked-button color="primary" (click)="refresh()">
        <mat-icon>refresh</mat-icon> aktualisieren
      </button>
      <button matTooltip="Suchfelder zurücksetzen" mat-stroked-button color="primary" (click)="reset()">
        <mat-icon>replay</mat-icon> zurücksetzen
      </button>
    </div>
  </mat-card>
</form>
