import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';

import { BehaviorSubject, combineLatest, merge, Observable, Subscription } from 'rxjs';
import { map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { AggregateSearchBarComponent } from '../components/aggregate-search-bar/aggregate-search-bar.component';
import fileSaver from 'file-saver';
import { SearchableAggregateResponse } from '../../shared/models';
import { AggregateService } from '../../shared/services/aggregate.service';
import { ReportService } from '../../inspections/services/report.service';
import { PlanningExcelService } from '../../shared/services';
import Utils from '../../utils/utils';
import {
  SearchBarFormAggregateEnergetic,
  SearchBarFormAggregateGeneral,
  SearchBarFormAggregateHygiene,
  SearchBarFormAggregateInspection,
} from '../models/SearchBarFormAggregate';
import {
  SearchParameterAggregateEnergetic,
  SearchParameterAggregateGeneral,
  SearchParameterAggregateHygiene,
  SearchParameterAggregateInspection,
} from '../models/CognitiveSearchParameter';
import { NotificationService } from '../../services/notification.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import HttpHeadersUtils from '../../utils/HttpHeaderExtensions';

@Component({
  selector: 'backoffice-aggregates-page',
  templateUrl: './aggregates-page.component.html',
  styleUrls: ['./aggregates-page.component.scss'],
})
export class AggregatesPageComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly initialPage = {
    pageIndex: 0,
    pageSize: 100,
  } as PageEvent;
  readonly pageSizeOptions: number[] = [5, 10, 25, 50, 100, 1000];

  readonly initialSort = {
    active: 'hygieneData/inspectionDate',
    direction: 'desc',
  } as Sort;

  readonly displayedColumns: string[] = [
    'select',
    'inspection.inspectionNumber',
    'name',
    'atwId',
    'subType',
    'inspection.customer.name',
    'inspection.location.name',
    'inspection.location.zip',
    'inspection.location.city',
    'inspection.location.street',
    'installationSite',
    'hygieneData.inspectionDate',
    'hygieneData.nextInspectionDate',
    'actions',
  ];

  aggregates$!: Observable<SearchableAggregateResponse[]>;
  private readonly reloadSubject = new BehaviorSubject(true);
  totalItems$!: Observable<number>;

  private reportDownloadSubscription!: Subscription;

  isLoadingExcel = false;

  public selection = new SelectionModel<SearchableAggregateResponse>(true);

  aggregates!: SearchableAggregateResponse[];

  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(AggregateSearchBarComponent, { static: true })
  searchBar!: AggregateSearchBarComponent;

  constructor(
    private readonly aggregateService: AggregateService,
    private readonly reportService: ReportService,
    private readonly planningExcelService: PlanningExcelService,
    private readonly cdr: ChangeDetectorRef,
    private readonly notificationService: NotificationService
  ) {}
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  convertToSearchParameterAggregateGeneral(v: SearchBarFormAggregateGeneral): SearchParameterAggregateGeneral {
    return {
      name: Utils.toNullIfFalsy(v.name),
      atwId: Utils.toNullIfFalsy(v.atwId),
      reportPdfId: Utils.toNullIfFalsy(v.reportPdfId),
      type: Utils.toNullIfFalsy(v.type),
      subType: Utils.toNullIfFalsy(v.subType),
      shutdown: v.shutdown, // do not use toNullIfFalsy as it would convert 'false' to 'null'. But 'false' is a valid value
      equipmentNumber: Utils.toNullIfFalsy(v.equipmentNumber),
      building: Utils.toNullIfFalsy(v.building),
      floor: Utils.toNullIfFalsy(v.floor),
      installationSite: Utils.toNullIfFalsy(v.installationSite),
    };
  }

  convertToSearchParameterAggregateInspection(v: SearchBarFormAggregateInspection): SearchParameterAggregateInspection {
    return {
      inspectionNumber: Utils.toNullIfFalsy(v.inspectionNumber),
      customer: Utils.toNullIfFalsy(v.customer),
      location: Utils.toNullIfFalsy(v.location),
      operator: Utils.toNullIfFalsy(v.operator),
    };
  }

  convertToSearchParameterAggregateHygiene(v: SearchBarFormAggregateHygiene): SearchParameterAggregateHygiene {
    return {
      inspectionTypes: Utils.toNullIfFalsy(v.inspectionTypes),
      inspectionStatuses: Utils.toNullIfFalsy(v.inspectionStatuses),
      inspector: Utils.toNullIfFalsy(v.inspector),
      shutdown: v.shutdown, // do not use toNullIfFalsy as it would convert 'false' to 'null'. But 'false' is a valid value
      isMostRecent: Utils.toNullIfFalsy(v.isMostRecent), // 'false' must be converted to 'null', since we want to list all aggregates!
      inspectionDateFrom: Utils.formatOrNull(v.inspectionDateFrom),
      inspectionDateTo: Utils.formatOrNull(v.inspectionDateTo),
      nextInspectionDateFrom: Utils.formatOrNull(v.nextInspectionDateFrom),
      nextInspectionDateTo: Utils.formatOrNull(v.nextInspectionDateTo),
    };
  }

  convertToSearchParameterAggregateEnergetic(v: SearchBarFormAggregateEnergetic): SearchParameterAggregateEnergetic {
    return {
      inspectionTypes: Utils.toNullIfFalsy(v.inspectionTypes),
      inspectionStatuses: Utils.toNullIfFalsy(v.inspectionStatuses),
      inspector: Utils.toNullIfFalsy(v.inspector),
      shutdown: v.shutdown, // do not use toNullIfFalsy as it would convert 'false' to 'null'. But 'false' is a valid value
      isMostRecent: Utils.toNullIfFalsy(v.isMostRecent), // 'false' must be converted to 'null', since we want to list all aggregates!
      inspectionDateFrom: Utils.formatOrNull(v.inspectionDateFrom),
      inspectionDateTo: Utils.formatOrNull(v.inspectionDateTo),
      nextInspectionDateFrom: Utils.formatOrNull(v.nextInspectionDateFrom),
      nextInspectionDateTo: Utils.formatOrNull(v.nextInspectionDateTo),
    };
  }

  ngOnInit() {
    const reload$ = merge(this.reloadSubject.asObservable());

    const sortChange$ = this.sort.sortChange.pipe(startWith(this.initialSort));

    const pageChange$ = this.paginator.page.pipe(startWith(this.initialPage));

    const searchChange$ = this.searchBar.changed.pipe(
      tap(() => this.paginator.firstPage()), // always set pagination to page 1 after filter has been changed
      map((values) => ({
        // the name must match to the expected parameter in the backend
        aggregateGeneralParameter: Utils.toNullIfValuesNull(this.convertToSearchParameterAggregateGeneral(values.aggregateData)),
        aggregateInspectionParameter: Utils.toNullIfValuesNull(this.convertToSearchParameterAggregateInspection(values.inspectionData)),
        aggregateHygieneParameter: Utils.toNullIfValuesNull(this.convertToSearchParameterAggregateHygiene(values.hygieneAggregateData)),
        aggregateEnergeticParameter: Utils.toNullIfValuesNull(
          this.convertToSearchParameterAggregateEnergetic(values.energeticAggregateData)
        ),
      }))
    );

    const apiResult$ = combineLatest([sortChange$, pageChange$, reload$, searchChange$]).pipe(
      switchMap(([sort, page, _, search]) => {
        return this.aggregateService.getAggregates({
          page: page.pageIndex,
          size: page.pageSize,
          sort: `${sort.active},${sort.direction}`,
          ...search,
        });
      }),
      shareReplay()
    );

    this.totalItems$ = apiResult$.pipe(map((apiPage) => apiPage.totalElements));

    this.aggregates$ = apiResult$.pipe(
      tap((apiPage) => {
        this.aggregates = apiPage.content;
        this.selection.clear();
        this.masterToggle();
      }),
      map((apiPage) => apiPage.content)
    );
  }

  reloadList() {
    this.reloadSubject.next(true);
  }

  openInspectionNewTab(inspectionId: string) {
    if (inspectionId && inspectionId !== '') {
      window.open(window.location.origin + '/inspections/all/' + inspectionId);
    }
  }

  downloadReport(pdfId: string) {
    this.reportDownloadSubscription = this.reportService.getReport(pdfId).subscribe((data) => {
      const reportUrl = window.URL.createObjectURL(data);
      window.open(reportUrl, '_blank');
    });
  }

  ngOnDestroy() {
    if (this.reportDownloadSubscription) {
      this.reportDownloadSubscription.unsubscribe();
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.areAllSelected()
      ? this.selection.clear()
      : this.aggregates.forEach((row) => {
          if (!row.shutdown) {
            this.selection.select(row);
          }
        });
  }

  areAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.aggregates.filter((aggregate) => !aggregate.shutdown).length;

    return numSelected === numRows;
  }

  async generatePlanningExcel() {
    this.isLoadingExcel = true;

    const aggregateIds = this.selection.selected.map(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (aggregate) => aggregate.id!
    );

    this.planningExcelService.downloadPlanningExcel(aggregateIds).subscribe({
      next: (excelResponse) => {
        const body = excelResponse.body;
        if (body != null) {
          const filename = HttpHeadersUtils.getFilenameFromContentDisposition(excelResponse.headers);
          fileSaver.saveAs(body, filename);
        } else {
          console.error('Excel response body missing');
        }
      },

      error: (e) => {
        this.isLoadingExcel = false;
        const httpError = e as HttpErrorResponse;
        const defaultMsg = `Fehler beim Erzeugen des Planungs-Excels! Fehlercode: ${httpError.status}`;

        if (httpError.status === HttpStatusCode.BadRequest) {
          httpError.error.text().then((responseText: string) => {
            let msg = defaultMsg;
            try {
              const jsonPayload = JSON.parse(responseText);
              const keyMessage = 'message';
              if (keyMessage in jsonPayload && jsonPayload[keyMessage]) {
                msg = jsonPayload[keyMessage];
              }
            } catch (e) {
              // fallback to default message
            }
            this.notificationService.errorMessage(msg);
          });
        }
        this.notificationService.errorMessage(defaultMsg);
      },

      complete: () => {
        this.isLoadingExcel = false;
      },
    });
  }
}
