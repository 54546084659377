import { Component, OnInit } from '@angular/core';
import { KeycloakRole } from '../../models/KeycloakRole';
import { environment } from '../../../environments/environment';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'backoffice-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  environment = environment;

  userAllowedToPrintBarcodes = false;

  constructor(private readonly keycloak: KeycloakService) { }

  ngOnInit() {
    this.userAllowedToPrintBarcodes = this.keycloak.isUserInRole(KeycloakRole.BACKOFFICE_ADMIN) || this.keycloak.isUserInRole(KeycloakRole.BACKOFFICE_PLANNER);
  }
}
