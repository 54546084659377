<backoffice-aggregate-search-bar />

<p>
  <backoffice-create-excel-button
    [isLoadingExcel]="isLoadingExcel"
    [disabled]="!selection.hasValue() || isLoadingExcel"
    (click)="generatePlanningExcel()"
  />
  <label class="note-label">Hinweis: Beim Sortieren oder Eingabe neuer Filter wird die Selektion der Anlagen zurückgesetzt</label>
</p>

<div class="mat-elevation-z2">
  <table
    mat-table
    [dataSource]="aggregates$"
    matSort
    [matSortActive]="initialSort.active"
    matSortDisableClear
    [matSortDirection]="initialSort.direction"
  >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && areAllSelected()"
          [indeterminate]="selection.hasValue() && !areAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [disabled]="row.shutdown"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="inspection.inspectionNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Insp.-Nr.</th>
      <td mat-cell *matCellDef="let element">
        <label class="inspectionNumber" (click)="openInspectionNewTab(element.inspection.inspectionId)">{{
          element.inspection.inspectionNumber ? element.inspection.inspectionNumber : '-'
        }}</label>
      </td>
    </ng-container>

    <ng-container matColumnDef="inspection.customer.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Auftraggeber</th>
      <td mat-cell *matCellDef="let element">
        {{ element.inspection.customer ? element.inspection.customer.name : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="inspection.location.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Standort</th>
      <td mat-cell *matCellDef="let element">
        {{ element.inspection.location ? element.inspection.location.name : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="inspection.location.zip">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>PLZ</th>
      <td mat-cell *matCellDef="let element">
        {{ element.inspection.location ? element.inspection.location.zip : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="inspection.location.city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Ort</th>
      <td mat-cell *matCellDef="let element">
        {{ element.inspection.location ? element.inspection.location.city : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="inspection.location.street">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Straße</th>
      <td mat-cell *matCellDef="let element">
        {{ element.inspection.location ? element.inspection.location.street : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="installationSite">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Aufstellungsort</th>
      <td mat-cell *matCellDef="let element">
        {{ element.installationSite ? element.installationSite : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="subType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Sub-Typ</th>
      <td mat-cell *matCellDef="let element">
        {{ element.displayType ? element.displayType : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Anlagenname</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name ? element.name : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="atwId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>ATW-ID</th>
      <td mat-cell *matCellDef="let element">
        {{ element.atwId ? element.atwId : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="hygieneData.inspectionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Inspektions-Datum</th>
      <td mat-cell *matCellDef="let element">
        {{ element.hygieneData?.inspectionDate | date | unset }}
      </td>
    </ng-container>

    <ng-container matColumnDef="hygieneData.nextInspectionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Wiederholungs-Datum</th>
      <td mat-cell *matCellDef="let element">
        {{ element.hygieneData?.nextInspectionDate | date | unset }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button *ngIf="element.reportPdfId" mat-icon-button color="primary" (click)="downloadReport(element.reportPdfId)">
          <mat-icon>description</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="empty">
      <td mat-footer-cell *matFooterCellDef [colSpan]="displayedColumns.length">keine Ergebnisse</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="element-row" [class.shutdown]="row.shutdown"></tr>
    <tr mat-footer-row *matFooterRowDef="['empty']" [class.hidden]="(totalItems$ | async) ?? 0 > 0"></tr>
  </table>

  <mat-paginator [length]="totalItems$ | async" [pageSize]="initialPage.pageSize" [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
</div>
