import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'backoffice-create-excel-button',
  templateUrl: './create-excel-button.component.html',
  styleUrls: ['./create-excel-button.component.scss'],
})
export class CreateExcelButtonComponent {
  @Input() isLoadingExcel!: boolean;
  @HostBinding('class.disabled')
  @Input()
  disabled!: boolean;
}
