import {
  HygieneInspectionType,
  AggregateSubType,
  AggregateType,
  HygieneInspectionStatus,
  InspectionStatus,
  EnergeticInspectionType,
  EnergeticInspectionStatus,
} from '../../shared/models';
import moment from 'moment';

/**
 * Interface to bundle data for the inspection search index
 * The data is filled by the user when he applies filters in the corresponding fields
 */
export interface SearchBarFormInspection {
  inspectionData: SearchBarFormInspectionGeneral;
  aggregateData: SearchBarFormInspectionAggregateGeneral;
  hygieneAggregateData: SearchBarFormInspectionAggregateHygiene;
  energeticAggregateData: SearchBarFormInspectionAggregateEnergetic;
}

/**
 * Interface to bundle general inspection data in a FormGroup.
 * Currently the specific inspection data is also part of this interface. As it only contains a single value (statusHygiene)
 */
export interface SearchBarFormInspectionGeneral {
  inspectionNumber?: string | null;
  customer?: string | null;
  location?: string | null;
  operator?: string | null;
  status?: InspectionStatus[] | null;
  statusHygiene?: HygieneInspectionStatus[] | null;
  statusEnergetic?: EnergeticInspectionStatus[] | null;
  inspectors?: string | null;
  startAfterDate?: moment.Moment | null;
  startBeforeDate?: moment.Moment | null;
  endAfterDate?: moment.Moment | null;
  endBeforeDate?: moment.Moment | null;
  dueFromDate?: moment.Moment | null;
  dueUntilDate?: moment.Moment | null;
  numberOfAggregates?: string | null;
}

/**
 *
 * @returns an empty (all null) object of [SearchBarFormInspectionGeneral]
 */
export function emptySearchBarFormInspectionGeneral(): SearchBarFormInspectionGeneral {
  return {
    inspectionNumber: null,
    customer: null,
    location: null,
    operator: null,
    status: null,
    statusHygiene: null,
    statusEnergetic: null,
    inspectors: null,
    startAfterDate: null,
    startBeforeDate: null,
    endAfterDate: null,
    endBeforeDate: null,
    dueFromDate: null,
    dueUntilDate: null,
    numberOfAggregates: null,
  };
}

/**
 * Interface to bundle aggregate data of inspection in a FormGroup
 */
export interface SearchBarFormInspectionAggregateGeneral {
  name?: string | null;
  atwId?: string | null;
  reportPdfId?: string | null;
  type?: AggregateType[] | null;
  subType?: AggregateSubType[] | null;
  shutdown?: boolean | null;
  equipmentNumber?: string | null;
  building?: string | null;
  floor?: string | null;
  installationSite?: string | null;
}

/**
 *
 * @returns an empty (all null) object of [SearchBarFormInspectionAggregateGeneral]
 */
export function emptySearchBarFormInspectionAggregateGeneral(): SearchBarFormInspectionAggregateGeneral {
  return {
    name: null,
    atwId: null,
    reportPdfId: null,
    type: null,
    subType: null,
    shutdown: null,
    equipmentNumber: null,
    building: null,
    floor: null,
    installationSite: null,
  };
}

/**
 * Interface to bundle hygiene aggregate data of inspection in a FormGroup
 */
export interface SearchBarFormInspectionAggregateHygiene {
  inspectionType: HygieneInspectionType[] | null;
  inspector?: string | null;
  shutdown?: boolean | null;
  isMostRecent?: boolean | null;
  inspectionDateFrom?: moment.Moment | null;
  inspectionDateTo?: moment.Moment | null;
  nextInspectionDateFrom?: moment.Moment | null;
  nextInspectionDateTo?: moment.Moment | null;
}

/**
 *
 * @returns an empty (all null) object of [SearchBarFormAggregateHygiene]
 */
export function emptySearchBarFormInspectionAggregateHygiene(): SearchBarFormInspectionAggregateHygiene {
  return {
    inspectionType: null,
    inspector: null,
    shutdown: null,
    isMostRecent: null,
    inspectionDateFrom: null,
    inspectionDateTo: null,
    nextInspectionDateFrom: null,
    nextInspectionDateTo: null,
  };
}

/**
 * Interface to bundle energetic aggregate data of inspection in a FormGroup
 */
export interface SearchBarFormInspectionAggregateEnergetic {
  inspectionType: EnergeticInspectionType[] | null;
  inspector?: string | null;
  shutdown?: boolean | null;
  isMostRecent?: boolean | null;
  inspectionDateFrom?: moment.Moment | null;
  inspectionDateTo?: moment.Moment | null;
  nextInspectionDateFrom?: moment.Moment | null;
  nextInspectionDateTo?: moment.Moment | null;
}

/**
 *
 * @returns an empty (all null) object of [SearchBarFormInspectionAggregateEnergetic]
 */
export function emptySearchBarFormInspectionAggregateEnergetic(): SearchBarFormInspectionAggregateEnergetic {
  return {
    inspectionType: null,
    inspector: null,
    shutdown: null,
    isMostRecent: null,
    inspectionDateFrom: null,
    inspectionDateTo: null,
    nextInspectionDateFrom: null,
    nextInspectionDateTo: null,
  };
}
