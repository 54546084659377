import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export enum CognitiveSearchIndexes {
    Aggregates = 'aggregates',
    Inspections = 'inspections'
}
@Injectable({
    providedIn: 'root'
})
export class SuggestionsService {

    constructor(
        private http: HttpClient
    ) { }

    searchSuggestions(cognitiveSearchIndex: CognitiveSearchIndexes, suggestionField: string, searchString: string) {
        return this.http.get(`${environment.API_BASE}/cognitive-search/${cognitiveSearchIndex}/suggestion`, {
            params: {
                suggesterInput: searchString,
                suggesterField: suggestionField,
            }

        }) as Observable<string[]>;
    }
}
