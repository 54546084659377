import { EnergeticInspectionStatus, HygieneInspectionStatus, InspectionStatus } from '../../shared/models';
import { ClientEvent, ClientEventType } from '../models';

export const createUpdateInspectionStatus = (status: InspectionStatus): ClientEvent<{ status: InspectionStatus }> => ({
  createdAt: new Date(),
  eventType: ClientEventType.BACKOFFICE_UPDATE_INSPECTION_STATUS,
  payload: {
    status,
  },
});

export const createUpdateHygieneInspectionStatus = (status: HygieneInspectionStatus): ClientEvent<{ status: HygieneInspectionStatus }> => ({
  createdAt: new Date(),
  eventType: ClientEventType.BACKOFFICE_UPDATE_HYGIENE_INSPECTION_STATUS,
  payload: {
    status,
  },
});

export const createUpdateEnergeticInspectionStatus = (
  status: EnergeticInspectionStatus
): ClientEvent<{ status: EnergeticInspectionStatus }> => ({
  createdAt: new Date(),
  eventType: ClientEventType.BACKOFFICE_UPDATE_ENERGETIC_INSPECTION_STATUS,
  payload: {
    status,
  },
});

export const createUpdateInspectionStartDate = (startDate: string): ClientEvent<Partial<{ startDate: string }>> => ({
  createdAt: new Date(),
  eventType: ClientEventType.BACKOFFICE_UPDATE_INSPECTION_STARTDATE,
  payload: {
    startDate,
  },
});

export const createUpdateInspectionInspectors = (inspectorIds: string[]): ClientEvent<{ inspectorIds: string }> => ({
  createdAt: new Date(),
  eventType: ClientEventType.BACKOFFICE_UPDATE_INSPECTION_INSPECTORS,
  payload: {
    inspectorIds: inspectorIds.join(','),
  },
});

export const createDeleteInspection = (): ClientEvent<unknown> => ({
  createdAt: new Date(),
  eventType: ClientEventType.BACKOFFICE_DELETE_INSPECTION,
  payload: {},
});

export const createDeleteHygieneInspection = (): ClientEvent<unknown> => ({
  createdAt: new Date(),
  eventType: ClientEventType.BACKOFFICE_DELETE_HYGIENE_INSPECTION,
  payload: {},
});

export const createDeleteEnergeticInspection = (): ClientEvent<unknown> => ({
  createdAt: new Date(),
  eventType: ClientEventType.BACKOFFICE_DELETE_ENERGETIC_INSPECTION,
  payload: {},
});
