<h1 mat-dialog-title>Inspekteure zuweisen</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-chip-grid #chipList aria-label="Zugewiesene Inspekteure">
            <mat-chip-row *ngFor="let inspector of selectedInspectors" (removed)="remove(inspector)">
                {{inspector.name}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-row>
            <input cdkFocusInitial placeholder="Ausgewählte Inspekteure" #inspectorInput
                [formControl]="inspectorControl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                (keydown.enter)="onSubmit()">
        </mat-chip-grid>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" autoActiveFirstOption>
            <mat-option *ngFor="let inspector of filteredInspectors | async" [value]="inspector">
                {{inspector.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button type="button" mat-button mat-dialog-close>Abbrechen</button>
    <button mat-button color="primary" (click)="onSubmit()">Inspekteure zuweisen</button>
</div>
