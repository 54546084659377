import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import moment from 'moment';

@Directive({
  selector: '[backofficeRemainingDays]'
})
export class RemainingDaysDirective implements OnInit {
  @Input('backofficeRemainingDays') remainingDaysDate!: Date;

  static calculateRemainingDays = (date: Date) => {
    return moment.utc(date).diff(moment.utc(), 'days');
  }

  constructor(private el: ElementRef) {}

  ngOnInit() {
    const remainingDays = RemainingDaysDirective.calculateRemainingDays(this.remainingDaysDate);

    const text = isNaN(remainingDays) ? null : remainingDays.toString();
    let className = 'RemainingDays--';

    if (isNaN(remainingDays)) {
      className += 'NA';
    } else if (remainingDays > 90) {
      className += 'Good';
    } else if (remainingDays > 30) {
      className += 'Medium';
    } else {
      className += 'Bad';
    }

    if (text) {
      (this.el.nativeElement as HTMLElement).textContent = text;
    }
    (this.el.nativeElement as HTMLElement).classList.add('RemainingDays', className);
  }

}
