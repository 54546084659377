export enum ClientEventType {
  BACKOFFICE_DELETE_INSPECTION = 'BACKOFFICE_DELETE_INSPECTION',
  BACKOFFICE_UPDATE_INSPECTION_STATUS = 'BACKOFFICE_UPDATE_INSPECTION_STATUS',
  BACKOFFICE_UPDATE_INSPECTION_STARTDATE = 'BACKOFFICE_UPDATE_INSPECTION_STARTDATE',
  BACKOFFICE_UPDATE_INSPECTION_INSPECTORS = 'BACKOFFICE_UPDATE_INSPECTION_INSPECTORS',

  BACKOFFICE_DELETE_HYGIENE_INSPECTION = 'BACKOFFICE_DELETE_HYGIENE_INSPECTION',
  BACKOFFICE_UPDATE_HYGIENE_INSPECTION_STATUS = 'BACKOFFICE_UPDATE_HYGIENE_INSPECTION_STATUS',

  BACKOFFICE_DELETE_ENERGETIC_INSPECTION = 'BACKOFFICE_DELETE_ENERGETIC_INSPECTION',
  BACKOFFICE_UPDATE_ENERGETIC_INSPECTION_STATUS = 'BACKOFFICE_UPDATE_ENERGETIC_INSPECTION_STATUS',
}
