import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HygieneInspectionType } from '../../models';

@Component({
  selector: 'backoffice-aggregate-hygiene-inspection-type',
  templateUrl: './aggregate-hygiene-inspection-type.component.html',
  styleUrls: ['./aggregate-hygiene-inspection-type.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AggregateHygieneInspectionTypeComponent),
      multi: true,
    },
  ],
})
export class AggregateHygieneInspectionTypeComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() label!: string;

  aggregateInspectionTypes = [...Object.keys(HygieneInspectionType)];

  aggregateInspectionTypeControl!: FormControl;

  _aggregateInspectionType: HygieneInspectionType[] | null = null;
  get aggregateInspectionType() {
    return this._aggregateInspectionType;
  }
  set aggregateInspectionType(val) {
    this._aggregateInspectionType = val;
    this.propagateChange(this._aggregateInspectionType);
  }

  _destroy$ = new Subject<void>();

  ngOnInit() {
    this.aggregateInspectionTypeControl = new FormControl(null);

    this.aggregateInspectionTypeControl.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
      if (Array.isArray(value) && value.length > 0) {
        this.aggregateInspectionType = value;
      } else {
        this.aggregateInspectionType = null;
      }
    });
  }

  propagateChange = (_: unknown) => {
    /* empty on purpose */
  };

  registerOnChange(fn: (_: unknown) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
    /* empty on purpose */
  }

  writeValue(value: HygieneInspectionType[]) {
    this.aggregateInspectionType = value;
    if (this.aggregateInspectionTypeControl) {
      this.aggregateInspectionTypeControl.setValue(this.aggregateInspectionType);
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
