<h1 mat-dialog-title>{{ data.title ? data.title : 'Titel'}}</h1>
<mat-dialog-content [formGroup]="form">
  <p>{{ data.text ? data.text : 'Beschreibungstext?' }}</p>
  <mat-form-field>
    <input matInput placeholder="{{ data.labelName ? data.labelName : 'Eingabefeld'}}" formControlName="input" required>
    <mat-error class="error-message"
      *ngIf="form.get('input')?.hasError('pattern') && (form.get('input')?.dirty || form.get('input')?.touched)">
      Eingabe enthält ungültige Zeichen
    </mat-error>
    <mat-error class="error-message"
      *ngIf="form.get('input')?.hasError('minLength') && (form.get('input')?.dirty || form.get('input')?.touched)">
      Eingabe ist zu kurz
    </mat-error>
    <mat-error class="error-message"
      *ngIf="form.get('input')?.hasError('maxLength') && (form.get('input')?.dirty || form.get('input')?.touched)">
      Eingabe ist zu lang
    </mat-error>
    <mat-error class="error-message"
      *ngIf="form.get('input')?.hasError('required') && (form.get('input')?.dirty || form.get('input')?.touched)">
      Feld ist erforderlich
    </mat-error>
  </mat-form-field>
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">{{ data.cancel ? data.cancel : 'Abbrechen' }}</button>
  <button mat-button (click)="save()" [disabled]="!form.get('input')?.valid" color="primary" cdkFocusInitial>
    {{ data.save ? data.save : 'Herunterladen' }}
  </button>
</div>