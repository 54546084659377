import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, tap, finalize, shareReplay } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { HttpErrorResponse } from '@angular/common/http';
import { InspectionResponseDetailed, InspectionStatus } from '../../../shared/models';
import { ActionPlanService } from '../../services/action-plan.service';
import { InspectionService } from '../../services';
import { ReportService } from '../../services/report.service';
import { PerformanceReportService } from '../../services/performance-report.service';
import { NotificationService } from '../../../services/notification.service';
import HttpHeadersUtils from '../../../utils/HttpHeaderExtensions';

interface RouteParams {
  id: string;
  [k: string]: string;
}

@Component({
  selector: 'backoffice-inspection-detail',
  templateUrl: './inspection-detail.component.html',
  styleUrls: ['./inspection-detail.component.scss'],
})
export class InspectionDetailComponent implements OnInit {
  inspection$!: Observable<InspectionResponseDetailed>;
  private inspectionId!: string;
  canGeneratePerformanceReport = false;
  isGeneratingPerformanceReport = false;
  isLoadingDocumentation = false;
  isDocumentationAvailable = false;
  isLoadingActionPlan = false;
  isActionPlanAvailable = false;

  isGeneratingV2 = false;

  private reload$ = new BehaviorSubject(false);

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly actionPlanService: ActionPlanService,
    private readonly inspectionService: InspectionService,
    private readonly reportService: ReportService,
    private readonly performanceReportService: PerformanceReportService,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.inspectionId = (this.route.snapshot.params as RouteParams).id;

    this.inspection$ = this.reload$.asObservable().pipe(
      switchMap(() => this.inspectionService.getInspection(this.inspectionId)),
      tap((inspection) => {
        this.isDocumentationAvailable =
          inspection.aggregates.filter((aggregate) => aggregate.report && aggregate.report.lastGenerationTime).length > 0;
        this.canGeneratePerformanceReport = inspection.status === InspectionStatus.SHIPPED_TO_CUSTOMER;
        this.isActionPlanAvailable = inspection.status !== InspectionStatus.CREATED;
      }),
      shareReplay(1)
    );
  }

  downloadActionPlan() {
    this.isLoadingActionPlan = true;
    this.actionPlanService.getActionPlan(this.inspectionId).subscribe({
      next: (response) => {
        const filename = HttpHeadersUtils.getFilenameFromContentDisposition(response.headers);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        saveAs(response.body!, filename);
        this.isLoadingActionPlan = false;
      },
      error: (e) => {
        this.isLoadingActionPlan = false;
        console.error(e);
      },
    });
  }

  downloadLabRequestBundle() {
    this.inspectionService.getLabRequestBundle(this.inspectionId).subscribe({
      next: (response) => {
        const filename = HttpHeadersUtils.getFilenameFromContentDisposition(response.headers);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        saveAs(response.body!, filename);
      },
      error: (e) => console.error(e),
    });
  }

  // this should be done via reload, but mat-stepper has a bug and needs an update to current version
  // so as a workaround we reload the current route
  reloadPage() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['/inspections/all/', this.inspectionId]));
  }

  reload() {
    this.reload$.next(true);
  }

  generateDocumentation(version = '') {
    this.isGeneratingV2 = true;

    this.inspectionService
      .generateDocumentation(this.inspectionId, version)
      .pipe(
        switchMap(() => {
          return this.inspectionService.hasDocumentationBundle(this.inspectionId, version);
        }),
        finalize(() => {
          this.isGeneratingV2 = false;
          this.reload$.next(true);
        })
      )
      .subscribe(() => {
        /* empty on purpose */
      });
  }

  downloadDocumentation(version = '2') {
    this.isLoadingDocumentation = true;

    this.inspectionService.getDocumentation(this.inspectionId, version).subscribe({
      next: (response) => {
        const filename = HttpHeadersUtils.getFilenameFromContentDisposition(response.headers);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        saveAs(response.body!, filename);
        this.isLoadingDocumentation = false;
      },
      error: (error) => {
        this.isLoadingDocumentation = false;
        // extract the error message from the blob
        error.error.text().then((responseText: string) => {
          try {
            const jsonPayload = JSON.parse(responseText);
            const keyMessage = 'message';
            this.notificationService.errorMessage(jsonPayload[keyMessage]);
          } catch (e) {
            const httpError = error as HttpErrorResponse;
            this.notificationService.errorMessage('Fehler beim Herunterladen der Dokumentation: ' + httpError.message);
          }
        });
      },
    });
  }

  downloadReport(pdfId: string) {
    this.reportService.getReport(pdfId).subscribe((data) => {
      const reportUrl = window.URL.createObjectURL(data);
      window.open(reportUrl, '_blank');
    });
  }

  async downloadPerformanceReport() {
    this.isGeneratingPerformanceReport = true;

    this.performanceReportService
      .downloadPerformanceReport(this.inspectionId)
      .pipe(
        finalize(() => {
          this.isGeneratingPerformanceReport = false;
        })
      )
      .subscribe({
        next: (response) => {
          const filename = HttpHeadersUtils.getFilenameFromContentDisposition(response.headers);
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          saveAs(response.body!, filename);
        },
        error: (e) => console.error(e),
      });
  }
}
