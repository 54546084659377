import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogData } from '../confirm-dialog/confirm-dialog.component';
import { switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { generateChangeHygieneStatusMenuItems } from './inspection-actions-hygiene.utils';
import { HygieneInspectionStatus } from '../../../shared/models';
import { InspectionActionEvent, InspectionActionEventType } from '../../models';
import { InspectionService } from '../../services';
import { NotificationService } from '../../../services/notification.service';
import { KeycloakRole } from '../../../models';

interface ActionDataHygiene {
  id: string;
  inspectionNumber: string;
  hygieneStatus: HygieneInspectionStatus;
}

@Component({
  selector: 'backoffice-inspection-actions-hygiene',
  templateUrl: './inspection-actions-hygiene.component.html',
})
export class InspectionActionsHygieneComponent implements OnChanges {
  @Input() inspection!: ActionDataHygiene;
  @Output() changed = new EventEmitter<InspectionActionEvent>();

  menuEnabled = false;
  canDelete = false;
  statusChangeMenuItems: HygieneInspectionStatus[] = [];

  constructor(
    private readonly inspectionService: InspectionService,
    private readonly keycloakService: KeycloakService,
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const inspection = changes['inspection'].currentValue as ActionDataHygiene;
    if (inspection) {
      this.statusChangeMenuItems = generateChangeHygieneStatusMenuItems(this.keycloakService, inspection.hygieneStatus);
      const canChangeStatus = this.statusChangeMenuItems.length > 0;

      const isAdmin = this.keycloakService.isUserInRole(KeycloakRole.BACKOFFICE_ADMIN);

      // Admin can delete everything, planner only pending hygiene inspections
      this.canDelete = isAdmin;

      this.menuEnabled = this.canDelete || canChangeStatus;
    }
  }

  handleMenuClick(event: MouseEvent) {
    event.stopPropagation();
  }

  setHygieneInspectionStatus(status: HygieneInspectionStatus) {
    this.inspectionService.updateHygieneInspectionStatus(this.inspection.id, status).subscribe(
      (_value) => {
        this.changed.emit({ type: InspectionActionEventType.UPDATED });
      },
      (errorResponse) => {
        const httpStatus = errorResponse.error.status;
        let msg: string;
        if (httpStatus === 403) {
          // FORBIDDEN
          msg = 'Diese Statusänderung ist im aktuellen Status der Hygieneinspektion nicht möglich!';
        } else if (httpStatus === 400) {
          // BAD_REQUEST
          msg = 'Diese Statusänderung ist für Hygieneinspektionen nicht möglich!';
        } else {
          msg = 'Statusänderung für die Hygieneinspektion ist fehlgeschlagen.';
        }

        this.notificationService.errorMessage(msg);
      }
    );
  }

  deleteHygieneInspection() {
    this.dialog
      .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
        data: {
          cancel: 'Abbrechen',
          confirm: 'Hygieneinspektion löschen',
          content: `Soll die Hygieneinspektion dieser Inspektion wirklich gelöscht werden? Diese Aktion kann nicht wieder rückgängig gemacht werden.`,
          title: `Hygieneinspektion von Inspektion ${this.inspection.inspectionNumber} löschen`,
        },
      })
      .afterClosed()
      .pipe(
        switchMap((confirmed) => {
          if (confirmed === true) {
            return this.inspectionService.deleteHygieneInspection(this.inspection.id);
          }
          return EMPTY;
        })
      )
      .subscribe(
        () => {
          this.changed.emit({ type: InspectionActionEventType.DELETED });
          this.notificationService.successMessage('Hygieneinspektion erfolgreich gelöscht.');
        },
        () => {
          this.notificationService.errorMessage('Die Hygieneinspektion konnte nicht gelöscht werden, bitte Zustand überprüfen.');
        }
      );
  }
}
