import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'backoffice-create-merge-report-button',
  templateUrl: './create-merge-report-button.component.html',
  styleUrls: ['./create-merge-report-button.component.scss'],
})
export class CreateMergeReportButtonComponent {
  @Input() isGeneratingMergeReport!: boolean;
  @HostBinding('class.disabled')
  @Input()
  disabled!: boolean;
}
