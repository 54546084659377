<button type="button" mat-raised-button color="primary" [disabled]="disabled">
  <span>
    <mat-icon *ngIf="!isGeneratingMergeReport">save_alt</mat-icon>
    <mat-spinner
      diameter="24"
      mode="indeterminate"
      *ngIf="isGeneratingMergeReport"
    ></mat-spinner>
    Zusammenfassung erstellen
  </span>
</button>
