import { PlanningExcelVersion } from '../inspections/models';
import {
  HygieneInspectionType,
  AggregateShutdown,
  AggregateStatus,
  AggregateSubType,
  AggregateType,
  HygieneInspectionStatus,
  InspectionStatus,
  IssueKey,
  IssueSeverity,
  EnergeticInspectionStatus,
  EnergeticInspectionType,
} from './models';

export const ENUMS = {
  planningExcel: {
    version: {
      [PlanningExcelVersion.V1]: {
        text: 'Version 1',
      },
      [PlanningExcelVersion.V2]: {
        text: 'Version 2',
      },
    },
  },
  inspections: {
    kind: {
      text: 'RLT',
      icon: 'toys_fan',
    },
    status: {
      [InspectionStatus.CREATED]: {
        text: 'angelegt',
        icon: 'flare',
      },
      [InspectionStatus.PLANNED]: {
        text: 'geplant',
        icon: 'assignment',
      },
      [InspectionStatus.IN_PROGRESS]: {
        text: 'im Gange',
        icon: 'tablet_android',
      },
      [InspectionStatus.FINISHED_BY_INSPECTOR]: {
        text: 'von Prüfer abgeschlossen',
        icon: 'how_to_reg',
      },
      [InspectionStatus.READY_FOR_SHIPMENT]: {
        text: 'versandbereit',
        icon: 'drafts',
      },
      [InspectionStatus.SHIPPED_TO_CUSTOMER]: {
        text: 'verschickt',
        icon: 'mark_email_read',
      },
    },
    statusHygiene: {
      [HygieneInspectionStatus.PENDING]: {
        text: 'ausstehend',
        icon: 'pending',
      },
      [HygieneInspectionStatus.IN_PROGRESS]: {
        text: 'im Gange',
        icon: 'tablet_android',
      },
      [HygieneInspectionStatus.WAITING_FOR_LAB]: {
        text: 'im Labor',
        icon: 'science',
      },
      [HygieneInspectionStatus.IN_REVIEW]: {
        text: 'Berichtsprüfung',
        icon: 'manage_search',
      },
      [HygieneInspectionStatus.COMPLETED]: {
        text: 'abgeschlossen',
        icon: 'lock',
      },
      default: '---',
    },
    statusEnergetic: {
      [EnergeticInspectionStatus.PENDING]: {
        text: 'ausstehend',
        icon: 'pending',
      },
      [EnergeticInspectionStatus.IN_PROGRESS]: {
        text: 'im Gange',
        icon: 'tablet_android',
      },
      [EnergeticInspectionStatus.WAITING_FOR_DATA]: {
        text: 'warten auf Daten',
        icon: 'chronic',
      },
      [EnergeticInspectionStatus.IN_REVIEW]: {
        text: 'Berichtsprüfung',
        icon: 'manage_search',
      },
      [EnergeticInspectionStatus.COMPLETED]: {
        text: 'abgeschlossen',
        icon: 'lock',
      },
      default: '---',
    },
  },
  aggregates: {
    status: {
      [AggregateStatus.INSPECTED]: 'abgeschlossen',
      [AggregateStatus.PARTLY_INSPECTED]: 'teilabgeschlossen',
      [AggregateStatus.NOT_INSPECTED]: 'abgebrochen',
      [AggregateStatus.ON_GOING]: 'im Gange',
      default: 'ausstehend',
    },
    shutdown: {
      [AggregateShutdown.ACTIVE]: 'Ja',
      [AggregateShutdown.SHUTDOWN]: 'Nein',
    },
    types: {
      [AggregateType.CENTRAL_RLT]: {
        text: 'Zentrale RLT',
      },
      [AggregateType.DECENTRAL_APPLIANCE]: {
        text: 'Dezentrales Gerät',
      },
      [AggregateType.COOLING_TOWER]: {
        text: 'Verdunstungskühlanlage',
      },
    },
    subTypes: {
      /** CENTRAL_RLT */
      [AggregateSubType.INLET_AIR]: {
        text: 'Zuluft',
        superType: AggregateType.CENTRAL_RLT,
      },
      [AggregateSubType.EXHAUST_AIR]: {
        text: 'Abluft',
        superType: AggregateType.CENTRAL_RLT,
      },
      [AggregateSubType.COMPACT_UNIT]: {
        text: 'Kompaktanlage',
        superType: AggregateType.CENTRAL_RLT,
      },
      [AggregateSubType.SHARED_OUTSIDE_AIR_INTAKE]: {
        text: 'gemeinsame Außenluftansaugung',
        superType: AggregateType.CENTRAL_RLT,
      },
      /** DECENTRAL_APPLIANCE */
      [AggregateSubType.CEILING_CASSETTE]: {
        text: 'Deckenkassette',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      [AggregateSubType.CIRCULATING_AIR_APPLIANCE]: {
        text: 'Umluftkühlgerät',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      [AggregateSubType.CLIMATE_LOCKER_APPLIANCE]: {
        text: 'Klima-Schrankgerät',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      [AggregateSubType.DOOR_AIR_CURTAIN]: {
        text: 'Türluftschleier',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      [AggregateSubType.END_APPLIANCE]: {
        text: 'Endgerät',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      [AggregateSubType.HEATING_APPLIANCE]: {
        text: 'Heizgerät',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      [AggregateSubType.INDUCTION_APPLIANCE]: {
        text: 'Induktionsgerät',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      [AggregateSubType.MIX_APPLIANCE]: {
        text: 'Mischkasten',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      [AggregateSubType.PASSIVE_COOLING_CONVECTOR]: {
        text: 'passiver Kühlkonvektor',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      [AggregateSubType.POST_TREATMENT_APPLIANCE]: {
        text: 'Nachbehandlungsgerät',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      [AggregateSubType.RAILING_APPLIANCE]: {
        text: 'Brüstungsgerät',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      [AggregateSubType.ROOM_AIR_PURIFIER]: {
        text: 'Raumluftreiniger',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      [AggregateSubType.SPLIT_APPLIANCE]: {
        text: 'Splitgerät',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      [AggregateSubType.UNDERFLOOR_APPLIANCE]: {
        text: 'Unterflurgerät',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      [AggregateSubType.BLOWER_CONVECTOR]: {
        text: 'Unterflurgerät',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      [AggregateSubType.FACADE_APPLIANCE]: {
        text: 'Fassadengerät',
        superType: AggregateType.DECENTRAL_APPLIANCE,
      },
      /** COOLING_TOWER */
      [AggregateSubType.CLOSED_MIX_WATERPOOL]: {
        text: 'geschl., gem.Wasserbecken',
        superType: AggregateType.COOLING_TOWER,
      },
      [AggregateSubType.CLOSED_WITH_POOL]: {
        text: 'geschl., mit Becken',
        superType: AggregateType.COOLING_TOWER,
      },
      [AggregateSubType.GENERIC_COOLING_TOWER]: {
        text: 'VDKA',
        superType: AggregateType.COOLING_TOWER,
      },
      [AggregateSubType.HYBRID_NO_POOL]: {
        text: 'Hybrid, kein Becken',
        superType: AggregateType.COOLING_TOWER,
      },
      [AggregateSubType.HYBRID_WITH_POOL]: {
        text: 'Hybrid, mit Becken',
        superType: AggregateType.COOLING_TOWER,
      },
      [AggregateSubType.OPEN_MIXED_WATERPOOL]: {
        text: 'offen, gem.Wasserbecken',
        superType: AggregateType.COOLING_TOWER,
      },
      [AggregateSubType.OPEN_WITH_POOL]: {
        text: 'offen, mit Becken',
        superType: AggregateType.COOLING_TOWER,
      },
      [AggregateSubType.WET_SEPARATOR]: {
        text: 'Nassabscheider',
        superType: AggregateType.COOLING_TOWER,
      },
    },
    inspectionTypesHygiene: {
      [HygieneInspectionType.HYGIENE_INITIAL_INSPECTION]: {
        text: 'Hygiene-Erstinspektion',
      },
      [HygieneInspectionType.HYGIENE_INSPECTION]: {
        text: 'Hygieneinspektion',
      },
      [HygieneInspectionType.HYGIENE_REINSPECTION]: {
        text: 'Hygieneinspektion (Wiederholung)',
      },
      [HygieneInspectionType.HYGIENE_CHECK]: {
        text: 'Hygienekontrolle',
      },
      [HygieneInspectionType.FOLLOW_UP_INSPECTION]: {
        text: 'Nachinspektion',
      },
      [HygieneInspectionType.SPECIAL_INSPECTION]: {
        text: 'Sonderprüfung',
      },
      [HygieneInspectionType.QUARTERLY_INSPECTION]: {
        text: 'Quartalsprüfung',
      },
      [HygieneInspectionType.QUARTERLY_REINSPECTION]: {
        text: 'Quartalsprüfung (Wiederholung)',
      },
      [HygieneInspectionType.INDUSTRIAL_WATER_INSPECTION]: {
        text: 'Nutzwasserprüfung',
      },
    },
    inspectionTypesEnergetic: {
      [EnergeticInspectionType.RLT_STANDARD]: {
        text: 'RLT (standard)',
      },
      [EnergeticInspectionType.RLT_SIMPLE]: {
        text: 'RLT (einfach)',
      },
      [EnergeticInspectionType.CHILLER]: {
        text: 'Kälte',
      },
    },
    issues: {
      severity: {
        [IssueSeverity.OPTIONAL]: {
          text: 'optional',
          tooltip: 'optional zu bearbeitende Fehler',
        },
        [IssueSeverity.RECOMMENDED]: {
          text: 'empfohlen',
          tooltip: 'empfohlene zu bearbeitende Fehler',
        },
        [IssueSeverity.REQUIRED]: {
          text: 'notwendig',
          tooltip: 'notwendige zu bearbeitende Fehler',
        },
      },
      key: {
        [IssueKey.NO_BARCODE]: 'Barcode fehlt',
        [IssueKey.NO_LAB]: 'Labor fehlt',
        [IssueKey.NO_LAB_RESULT]: 'Laborergebnis fehlt',
        [IssueKey.NO_OPERATOR]: 'Betreiber fehlt',
        [IssueKey.NO_INSPECTION_DATE]: 'Inspektionsdatum fehlt',
        [IssueKey.NO_INSPECTOR]: 'Inspekteur fehlt',
        [IssueKey.NO_PHOTO_OF_AGGREGATE]: 'Aggregat-Photo fehlt',
        [IssueKey.NO_ZONES]: 'Zonen fehlen',
        [IssueKey.NO_ELEMENTS]: 'Elemente fehlen',
        [IssueKey.NO_PHOTO_OF_ELEMENT]: 'Element-Photo fehlt',
        [IssueKey.NO_SIGNATURE_IN_STORE]: 'Unterschrift-Bild nicht hinterlegt',
        [IssueKey.NO_PHOTO_IN_STORE]: 'Photo nicht hinterlegt',
      },
    },
  },
};
