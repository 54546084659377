import { KeycloakService } from 'keycloak-angular';
import { KeycloakRole } from '../../../models';
import { EnergeticInspectionStatus } from '../../../shared/models';

export const generateChangeEnergeticStatusMenuItems = (
  keycloakService: KeycloakService,
  status?: EnergeticInspectionStatus
): EnergeticInspectionStatus[] => {
  const items: EnergeticInspectionStatus[] = [];
  // if no energetic data exists, return an empty list
  if (status === undefined || status === null) {
    return items;
  }

  const isReviewer = keycloakService.isUserInRole(KeycloakRole.BACKOFFICE_REVIEWER_ENERGETIC);
  const isPlanner = keycloakService.isUserInRole(KeycloakRole.BACKOFFICE_PLANNER);

  // WAITING_FOR_DATA, IN_REVIEW, COMPLETED -> IN_PROGRESS  -- By BACKOFFICE_REVIEWER_ENERGETIC
  if (
    [EnergeticInspectionStatus.WAITING_FOR_DATA, EnergeticInspectionStatus.IN_REVIEW, EnergeticInspectionStatus.COMPLETED].includes(
      status
    ) &&
    (isReviewer || isPlanner)
  ) {
    items.push(EnergeticInspectionStatus.IN_PROGRESS);
  }

  // IN_REVIEW -> COMPLETED  -- By BACKOFFICE_REVIEWER_ENERGETIC
  if (status === EnergeticInspectionStatus.IN_REVIEW && isReviewer) {
    items.push(EnergeticInspectionStatus.COMPLETED);
  }

  // COMPLETED -> IN_REVIEW  -- By BACKOFFICE_REVIEWER_ENERGETIC
  if (status === EnergeticInspectionStatus.COMPLETED && isReviewer) {
    items.push(EnergeticInspectionStatus.IN_REVIEW);
  }

  return items;
};
