import { KeycloakService } from 'keycloak-angular';
import { KeycloakRole } from '../../../models';
import { HygieneInspectionStatus } from '../../../shared/models';

export const generateChangeHygieneStatusMenuItems = (
  keycloakService: KeycloakService,
  status?: HygieneInspectionStatus
): HygieneInspectionStatus[] => {
  const items: HygieneInspectionStatus[] = [];
  // if no hygiene data exists, return an empty list
  if (status === undefined || status === null) {
    return items;
  }

  const isReviewer = keycloakService.isUserInRole(KeycloakRole.BACKOFFICE_REVIEWER_HYGIENE);
  const isPlanner = keycloakService.isUserInRole(KeycloakRole.BACKOFFICE_PLANNER);

  // WAITING_FOR_LAB, IN_REVIEW, COMPLETED -> IN_PROGRESS  -- By BACKOFFICE_REVIEWER_HYGIENE
  if (
    [HygieneInspectionStatus.WAITING_FOR_LAB, HygieneInspectionStatus.IN_REVIEW, HygieneInspectionStatus.COMPLETED].includes(status) &&
    (isReviewer || isPlanner)
  ) {
    items.push(HygieneInspectionStatus.IN_PROGRESS);
  }

  // IN_REVIEW -> COMPLETED  -- By BACKOFFICE_REVIEWER_HYGIENE
  if (status === HygieneInspectionStatus.IN_REVIEW && isReviewer) {
    items.push(HygieneInspectionStatus.COMPLETED);
  }

  // COMPLETED -> IN_REVIEW  -- By BACKOFFICE_REVIEWER_HYGIENE
  if (status === HygieneInspectionStatus.COMPLETED && isReviewer) {
    items.push(HygieneInspectionStatus.IN_REVIEW);
  }

  return items;
};
