import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogData } from '../confirm-dialog/confirm-dialog.component';
import { switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { generateChangeStatusMenuItems } from './inspection-actions.utils';
import { InspectionStatus, SearchableInspectionResponse } from '../../../shared/models';
import { InspectionActionEvent, InspectionActionEventType } from '../../models';
import { InspectionService } from '../../services';
import { NotificationService } from '../../../services/notification.service';
import { KeycloakRole } from '../../../models';

@Component({
  selector: 'backoffice-inspection-actions',
  templateUrl: './inspection-actions.component.html',
})
export class InspectionActionsComponent implements OnChanges {
  @Input() inspection!: {
    id: string;
    inspectionNumber: string;
    status: InspectionStatus;
  };
  @Output() changed = new EventEmitter<InspectionActionEvent>();

  menuEnabled = false;
  canDelete = false;
  statusChangeMenuItems: InspectionStatus[] = [];

  constructor(
    private readonly inspectionService: InspectionService,
    private readonly keycloakService: KeycloakService,
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const inspection = changes['inspection'].currentValue as SearchableInspectionResponse;
    if (inspection) {
      const inspection = changes['inspection'].currentValue;
      this.statusChangeMenuItems = generateChangeStatusMenuItems(inspection, this.keycloakService);
      const canChangeStatus = this.statusChangeMenuItems.length > 0;

      const isAdmin = this.keycloakService.isUserInRole(KeycloakRole.BACKOFFICE_ADMIN);
      const isPlanner = this.keycloakService.isUserInRole(KeycloakRole.BACKOFFICE_PLANNER);

      // Admin can delete everything, planner only created and planned inspections
      this.canDelete =
        isAdmin || (isPlanner && (inspection.status === InspectionStatus.CREATED || inspection.status === InspectionStatus.PLANNED));

      this.menuEnabled = this.canDelete || canChangeStatus;
    }
  }

  handleMenuClick(event: MouseEvent) {
    event.stopPropagation();
  }

  setInspectionStatus(status: InspectionStatus) {
    this.inspectionService.updateInspectionStatus(this.inspection.id, status).subscribe(
      () => {
        this.changed.emit({ type: InspectionActionEventType.UPDATED });
      },
      (errorResponse) => {
        const httpStatus = errorResponse.error.status;
        let msg: string;
        if (httpStatus === 403) {
          // FORBIDDEN
          msg = 'Diese Statusänderung ist im aktuellen Status der Inspektion nicht möglich!';
        } else if (httpStatus === 400) {
          // BAD_REQUEST
          msg = 'Diese Statusänderung ist für Inspektionen nicht möglich!';
        } else {
          msg = 'Statusänderung für die Hygieneinspektion ist fehlgeschlagen.';
        }

        this.notificationService.errorMessage(msg);
      }
    );
  }

  deleteInspection() {
    this.dialog
      .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
        data: {
          cancel: 'Abbrechen',
          confirm: 'Inspektion löschen',
          content: `Soll diese Inspektion wirklich gelöscht werden? Diese Aktion kann nicht wieder rückgängig gemacht werden.`,
          title: `Inspektion ${this.inspection.inspectionNumber} löschen`,
        },
      })
      .afterClosed()
      .pipe(
        switchMap((confirmed) => {
          if (confirmed === true) {
            return this.inspectionService.deleteInspection(this.inspection.id);
          }
          return EMPTY;
        })
      )
      .subscribe({
        next: (_) => EMPTY,
        complete: () => {
          this.changed.emit({ type: InspectionActionEventType.DELETED });
          this.notificationService.successMessage('Inspektion erfolgreich gelöscht.');
        },
        error: (_) => {
          this.notificationService.errorMessage('Die Inspektion konnte nicht gelöscht werden, bitte Zustand überprüfen.');
        },
      });
  }
}
