import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AggregateType } from '../../models';

@Component({
  selector: 'backoffice-aggregate-type',
  templateUrl: './aggregate-type.component.html',
  styleUrls: ['./aggregate-type.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AggregateTypeComponent),
      multi: true,
    },
  ],
})
export class AggregateTypeComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() label!: string;

  aggregateTypes = [...Object.keys(AggregateType)];

  aggregateTypeControl!: FormControl;

  _aggregateType: AggregateType[] | null = null;
  get aggregateType() {
    return this._aggregateType;
  }
  set aggregateType(val) {
    this._aggregateType = val;
    this.propagateChange(this._aggregateType);
  }

  _destroy$ = new Subject<void>();

  ngOnInit() {
    this.aggregateTypeControl = new FormControl(null);

    this.aggregateTypeControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        if (Array.isArray(value) && value.length > 0) {
          this.aggregateType = value;
        } else {
          this.aggregateType = null;
        }
      });
  }

  propagateChange = (_: unknown) => {
    /* empty on purpose */
  };

  registerOnChange(fn: (_: unknown) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
    /* empty on purpose */
  }

  writeValue(value: AggregateType[]) {
    this.aggregateType = value;
    if (this.aggregateTypeControl) {
      this.aggregateTypeControl.setValue(this.aggregateType);
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
