import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  createUpdateInspectionStatus,
  createUpdateInspectionStartDate,
  createDeleteInspection,
  createUpdateInspectionInspectors,
  createDeleteHygieneInspection,
  createUpdateHygieneInspectionStatus,
  createDeleteEnergeticInspection,
  createUpdateEnergeticInspectionStatus,
} from '../events';
import {
  ApiPage,
  ApiPageParams,
  EnergeticInspectionStatus,
  HygieneInspectionStatus,
  InspectionResponseDetailed,
  InspectionResponseSummary,
  InspectionStatus,
  toStringParams,
} from '../../shared/models';
import { RecurrentSearchableInspectionResponse, SearchableInspectionResponse } from '../../shared/models/CognitiveSearchResponse';
import { environment } from '../../../environments/environment';
import { ClientEvent, RecurrentInspectionDetail } from '../models';

@Injectable({
  providedIn: 'root',
})
export class InspectionService {
  constructor(private http: HttpClient) {}

  getReport(aggregateId: string) {
    return this.http.get(`${environment.API_BASE}/reports/${aggregateId}`, {
      responseType: 'blob',
    });
  }

  getInspections(params: Partial<ApiPageParams> = { page: 0, size: 10 }) {
    return this.http.get(`${environment.API_BASE}/cognitive-search/inspections/`, {
      params: toStringParams(params),
    }) as Observable<ApiPage<SearchableInspectionResponse>>;
  }

  getInspection(id: string) {
    return this.http.get(`${environment.API_BASE}/inspections/${id}`) as Observable<InspectionResponseDetailed>;
  }

  generateDocumentation(id: string, version: string) {
    return this.http.put(`${environment.API_BASE}/inspections/${id}/documentation?version=${version}`, {});
  }

  getDocumentation(id: string, version: string) {
    return this.http.get(`${environment.API_BASE}/inspections/${id}/documentation?version=${version}`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  hasDocumentationBundle(id: string, version: string) {
    return this.http.head(`${environment.API_BASE}/inspections/${id}/documentation?version=${version}`);
  }

  getMergeReport(inspectionIds: string[], location: string) {
    return this.http.post(
      `${environment.API_BASE}/inspections/mergeReport/`,
      {
        location,
        inspectionIds,
      },
      {
        observe: 'response',
        responseType: 'blob',
      }
    );
  }

  getLabRequestBundle(id: string) {
    return this.http.get(`${environment.API_BASE}/labrequest/${id}`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  getRecurrentInspections(params: Partial<ApiPageParams> = { page: 0, size: 10 }) {
    return this.http.get(`${environment.API_BASE}/cognitive-search/inspections/recurrent`, {
      params: toStringParams(params),
    }) as Observable<ApiPage<RecurrentSearchableInspectionResponse>>;
  }

  getRecurrentInspectionDetail(id: string) {
    return this.http.get(`${environment.API_BASE}/recurrent-inspections/${id}`) as Observable<RecurrentInspectionDetail>;
  }

  getPlannedInspections(params: Partial<ApiPageParams> = { page: 0, size: 10 }) {
    return this.http.get(`${environment.API_BASE}/inspections/`, {
      params: toStringParams(params),
    }) as Observable<ApiPage<InspectionResponseSummary>>;
  }

  updateInspectors(id: string, inspectorIds: string[]) {
    return this.putEvent(id, createUpdateInspectionInspectors(inspectorIds));
  }

  updateStartDate(id: string, startDate: string) {
    return this.putEvent(id, createUpdateInspectionStartDate(startDate));
  }

  deleteInspection(id: string) {
    return this.putEvent(id, createDeleteInspection());
  }

  deleteHygieneInspection(id: string) {
    return this.putEvent(id, createDeleteHygieneInspection());
  }

  deleteEnergeticInspection(id: string) {
    return this.putEvent(id, createDeleteEnergeticInspection());
  }

  updateInspectionStatus(id: string, status: InspectionStatus) {
    // stringification and content-type needed, otherwise HttpClient would use text/plain
    return this.putEvent(id, createUpdateInspectionStatus(status));
  }

  updateHygieneInspectionStatus(id: string, status: HygieneInspectionStatus) {
    // stringification and content-type needed, otherwise HttpClient would use text/plain
    return this.putEvent(id, createUpdateHygieneInspectionStatus(status));
  }

  updateEnergeticInspectionStatus(id: string, status: EnergeticInspectionStatus) {
    // stringification and content-type needed, otherwise HttpClient would use text/plain
    return this.putEvent(id, createUpdateEnergeticInspectionStatus(status));
  }

  private putEvent(id: string, events: ClientEvent<unknown>) {
    return this.http.put(`${environment.API_BASE}/inspections/${id}/events/`, [events]);
  }
}
