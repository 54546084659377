import { AddressDetail } from './Address';
import { AggregateReportSummary } from './AggregateReport';

export interface AggregateResponseSummary {
  // data from backend directly
  inspectionId: string;
  id: string;
  name: string;
  atwId?: string;
  sortOrder: number;
  building?: string;
  floor?: string;
  installationSite?: string;
  equipmentNumber?: string;
  shutdown: number;

  hygieneAggregate?: HygieneAggregateResponseSummary;
  energeticAggregate?: EnergeticAggregateResponseSummary;
  typeDisplayName: string;
  operator?: AddressDetail;
  report?: AggregateReportSummary;
  requiredIssueCount: number;
  recommendedIssueCount: number;
  optionalIssueCount: number;
  internalNote: string;
}

export interface HygieneAggregateResponseSummary {
  status: AggregateStatus;
  nextInspectionDate?: string;
}

export interface EnergeticAggregateResponseSummary {
  status: AggregateStatus;
  nextInspectionDate?: string;
}

/**
 * The shutdown state is now a bit field, but for the cognitive search we have to convert it to multiple boolean values.
 * Hence, this enum is now only used for the search-bars
 */
export enum AggregateShutdown {
  ACTIVE = 'ACTIVE',
  SHUTDOWN = 'SHUTDOWN',
}

/**
 * Defines all different kinds of hygiene inspections status for aggregate.
 * !!!!!
 * IMPORTANT: These types are also defined in backend and app repositories. => keep them in sync!
 * !!!!!
 */
export enum AggregateStatus {
  INSPECTED = 'INSPECTED',
  PARTLY_INSPECTED = 'PARTLY_INSPECTED',
  NOT_INSPECTED = 'NOT_INSPECTED',
  ON_GOING = 'ON_GOING',
}

/**
 * Defines all different kinds of aggregate types.
 * !!!!!
 * IMPORTANT: These types are also defined in backend and app repositories. => keep them in sync!
 * !!!!!
 */
export enum AggregateType {
  CENTRAL_RLT = 'CENTRAL_RLT',
  DECENTRAL_APPLIANCE = 'DECENTRAL_APPLIANCE',
  COOLING_TOWER = 'COOLING_TOWER',
}

/**
 * Defines all different kinds of aggregate subtypes.
 * !!!!!
 * IMPORTANT: These types are also defined in backend and audit-app repositories. => keep them in sync!
 * !!!!!
 */
export enum AggregateSubType {
  /** CENTRAL_RLT */
  INLET_AIR = 'INLET_AIR',
  EXHAUST_AIR = 'EXHAUST_AIR',
  COMPACT_UNIT = 'COMPACT_UNIT',
  SHARED_OUTSIDE_AIR_INTAKE = 'SHARED_OUTSIDE_AIR_INTAKE',

  /** DECENTRAL_APPLIANCE */
  END_APPLIANCE = 'END_APPLIANCE',
  INDUCTION_APPLIANCE = 'INDUCTION_APPLIANCE',
  MIX_APPLIANCE = 'MIX_APPLIANCE',
  CIRCULATING_AIR_APPLIANCE = 'CIRCULATING_AIR_APPLIANCE',
  PASSIVE_COOLING_CONVECTOR = 'PASSIVE_COOLING_CONVECTOR',
  POST_TREATMENT_APPLIANCE = 'POST_TREATMENT_APPLIANCE',
  SPLIT_APPLIANCE = 'SPLIT_APPLIANCE',
  CLIMATE_LOCKER_APPLIANCE = 'CLIMATE_LOCKER_APPLIANCE',
  BLOWER_CONVECTOR = 'BLOWER_CONVECTOR',
  FACADE_APPLIANCE = 'FACADE_APPLIANCE',
  UNDERFLOOR_APPLIANCE = 'UNDERFLOOR_APPLIANCE',
  RAILING_APPLIANCE = 'RAILING_APPLIANCE',
  DOOR_AIR_CURTAIN = 'DOOR_AIR_CURTAIN',
  HEATING_APPLIANCE = 'HEATING_APPLIANCE',
  CEILING_CASSETTE = 'CEILING_CASSETTE',
  ROOM_AIR_PURIFIER = 'ROOM_AIR_PURIFIER',

  /** COOLING_TOWER */
  GENERIC_COOLING_TOWER = 'GENERIC_COOLING_TOWER',
  HYBRID_NO_POOL = 'HYBRID_NO_POOL',
  HYBRID_WITH_POOL = 'HYBRID_WITH_POOL',
  OPEN_WITH_POOL = 'OPEN_WITH_POOL',
  OPEN_MIXED_WATERPOOL = 'OPEN_MIXED_WATERPOOL',
  CLOSED_WITH_POOL = 'CLOSED_WITH_POOL',
  CLOSED_MIX_WATERPOOL = 'CLOSED_MIX_WATERPOOL',
  WET_SEPARATOR = 'WET_SEPARATOR',
}

/**
 * Defines all different kinds of aggregate inspections.
 * !!!!!
 * IMPORTANT: These types are also defined in backend and audit-app repositories. => keep them in sync!
 * !!!!!
 */
export enum HygieneInspectionType {
  HYGIENE_INITIAL_INSPECTION = 'HYGIENE_INITIAL_INSPECTION',
  HYGIENE_INSPECTION = 'HYGIENE_INSPECTION',
  HYGIENE_REINSPECTION = 'HYGIENE_REINSPECTION',
  HYGIENE_CHECK = 'HYGIENE_CHECK',
  FOLLOW_UP_INSPECTION = 'FOLLOW_UP_INSPECTION',
  SPECIAL_INSPECTION = 'SPECIAL_INSPECTION',
  QUARTERLY_INSPECTION = 'QUARTERLY_INSPECTION',
  QUARTERLY_REINSPECTION = 'QUARTERLY_REINSPECTION',
  INDUSTRIAL_WATER_INSPECTION = 'INDUSTRIAL_WATER_INSPECTION',
}

/**
 * Defines all different kinds of energetic inspections for aggregates.
 *
 * !!!!!
 * IMPORTANT: These types are also defined in audit-app and backend repositories. => keep them in sync!
 * !!!!!
 */
export enum EnergeticInspectionType {
  RLT_STANDARD = 'RLT_STANDARD',
  RLT_SIMPLE = 'RLT_SIMPLE',
  CHILLER = 'CHILLER',
}
