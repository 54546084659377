import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import isEqual from 'lodash.isequal';
import {
  emptySearchBarFormAggregateEnergetic,
  emptySearchBarFormAggregateGeneral,
  emptySearchBarFormAggregateHygiene,
  emptySearchBarFormAggregateInspection,
  SearchBarFormAggregate,
} from '../models/SearchBarFormAggregate';

const initialValue: SearchBarFormAggregate = {
  aggregateData: emptySearchBarFormAggregateGeneral(),
  inspectionData: emptySearchBarFormAggregateInspection(),
  hygieneAggregateData: emptySearchBarFormAggregateHygiene(),
  energeticAggregateData: emptySearchBarFormAggregateEnergetic(),
} as SearchBarFormAggregate;

@Injectable({
  providedIn: 'root',
})
export class AggregateSearchBarService {
  private _subject = new ReplaySubject<SearchBarFormAggregate>(1);
  private _force = false;
  changes: Observable<SearchBarFormAggregate>;
  constructor() {
    this.changes = this._subject.asObservable().pipe(distinctUntilChanged((x, y) => !this._force && isEqual(x, y)));
    this.next(initialValue);
  }

  next(value: SearchBarFormAggregate) {
    this._subject.next(value);
  }

  forceNext(value: SearchBarFormAggregate) {
    this._force = true;
    this._subject.next(value);
    this._force = false;
  }
}
