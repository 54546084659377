<h1 class="mat-h1">Barcodes generieren</h1>
<form *ngIf="isAllowedToGenerateBarcodes" [formGroup]="form" disabled>
    <mat-card>
        <mat-form-field>
            <mat-label>Barcode-Typ</mat-label>
            <mat-select formControlName="barcode">
                <mat-option *ngFor="let barcode of barcodes" [value]="barcode"
                            [ngStyle]="{'background-color': barcode.color}">
                    {{barcode.prefix}} - {{barcode.title}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls['barcode'].hasError('required')">
                Eingabe notwendig
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Anzahl Seiten</mat-label>
            <input formControlName="sheetCount" matInput type="number" min="1" max="50" step="1">
            <mat-error *ngIf="form.controls['sheetCount'].hasError('required')">
                Eingabe notwendig
            </mat-error>
            <mat-error *ngIf="form.controls['sheetCount'].hasError('min')">
                Mindestens 1 Seite
            </mat-error>
            <mat-error *ngIf="form.controls['sheetCount'].hasError('max')">
                Maximal 50 Seiten
            </mat-error>
            <mat-error *ngIf="form.controls['sheetCount'].hasError('noDecimal')">
                Nur ganzzahlige Seiten
            </mat-error>
        </mat-form-field>

        <button [disabled]="!form.valid || isLoading" mat-stroked-button (click)="onSubmit()"
                [ngStyle]="{'background-color': form.controls['barcode'].value?.color}">
            <mat-icon *ngIf="!isLoading">print</mat-icon>
            <mat-spinner diameter="24" mode="indeterminate" *ngIf="isLoading"></mat-spinner>
            Barcodes drucken
        </button>

        <button [disabled]="!form.valid || isLoading" mat-stroked-button (click)="onSubmit(false)"
                [ngStyle]="{'background-color': form.controls['barcode'].value?.color}">
            <mat-icon *ngIf="!isLoading">save_alt</mat-icon>
            <mat-spinner diameter="24" mode="indeterminate" *ngIf="isLoading"></mat-spinner>
            Barcodes download
        </button>
    </mat-card>
</form>