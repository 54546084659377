<h1 *ngIf="data.title" mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p>{{ data.content }}</p>
  <div *ngIf="data.error">
    <p class="mat-h2">Fehlerbeschreibung:</p>
    <p class="NotificationDialog__Error">{{ data.error }}</p>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button color="primary" [mat-dialog-close] cdkFocusInitial>Schließen</button>
</div>