import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PlanningExcelVersion } from '../../inspections/models';

@Injectable({
  providedIn: 'root',
})
export class PlanningExcelService {
  private uploadSubject = new BehaviorSubject(false);
  readonly uploaded = this.uploadSubject.asObservable().pipe(share());

  constructor(private http: HttpClient) {}

  uploadPlanningExcel(excelFile: File, version: PlanningExcelVersion) {
    const formData = new FormData();
    formData.append('file', excelFile, excelFile.name);

    return this.http.post(`${environment.API_BASE}/planningexcel/${version}`, formData).pipe(
      tap(() => {
        this.uploadSubject.next(true);
      })
    );
  }

  downloadPlanningExcel(aggregateIds: string[]) {
    return this.http.post(
      `${environment.API_BASE}/planningexcel/`,
      {
        aggregateIds,
      },
      {
        observe: 'response',
        responseType: 'blob',
      }
    );
  }

  downloadPlanningExcelTemplate() {
    return this.http.get(`${environment.API_BASE}/planningexcel/template`, {
      observe: 'response',
      responseType: 'blob',
    });
  }
}
