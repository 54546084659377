import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PerformanceReportService {
  constructor(private http: HttpClient) {}

  /**
   * Downloads Performance Report (Leistungsnachweis) for given Inspection
   * @param id inspection UUID
   */
  downloadPerformanceReport(id: string) {
    return this.http.get(`${environment.API_BASE}/performancereport/${id}`, {
      params: new HttpParams(),
      observe: 'response',
      responseType: 'blob',
    });
  }
}
