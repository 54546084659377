<h2 class="mat-h2">{{ aggregates.length }} Anlagen</h2>

<div class="mat-elevation-z2">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
    <ng-container matColumnDef="internalNote">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear></th>
      <td mat-cell *matCellDef="let element">
        <mat-icon
          *ngIf="element.internalNote"
          [matTooltip]="element.internalNote"
          >info</mat-icon
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="atwId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        ATW-ID
      </th>
      <td mat-cell *matCellDef="let element">{{ element.atwId }}</td>
    </ng-container>

    <ng-container matColumnDef="typeDisplayName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Sub-Typ
      </th>
      <td mat-cell *matCellDef="let element">{{ element.typeDisplayName }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Anlagen-Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="equipmentNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        EQ-Nr.
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.equipmentNumber ? element.equipmentNumber : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="building">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Gebäude
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.building ? element.building : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="floor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Stockwerk
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.floor ? element.floor : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="installationSite">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Aufstellungsort
      </th>
      <td mat-cell *matCellDef="let element">{{ element.installationSite }}</td>
    </ng-container>

    <ng-container matColumnDef="hygieneAggregate.nextInspectionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Datum Wied.-Inspektion
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.hygieneAggregate?.nextInspectionDate | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="issues">
      <th mat-header-cell *matHeaderCellDef>Validierungen</th>
      <td mat-cell *matCellDef="let element">
        <backoffice-aggregate-validations [aggregate]="element" />
      </td>
    </ng-container>

    <ng-container matColumnDef="hygieneAggregate.status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Status
      </th>
      <td mat-cell *matCellDef="let element">
        <span [ngClass]="'Aggregate_Status--' + element.status">
          {{
            ('aggregates.status.' + element.hygieneAggregate?.status | enum) ||
              '-'
          }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="displayedColumns.length"
      >
        <div
          class="example-element-detail"
          [@detailExpand]="
            element === expandedElement ? 'expanded' : 'collapsed'
          "
        >
          <backoffice-aggregate-issues
            *ngIf="element === expandedElement"
            [aggregateId]="element.id"
          >
          </backoffice-aggregate-issues>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-spinner
          *ngIf="loadingReports"
          color="primary"
          mode="indeterminate"
          diameter="24"
        ></mat-spinner>
        <button
          *ngIf="
            !loadingReports &&
            element.report &&
            element.report.lastGenerationTime
          "
          mat-icon-button
          color="primary"
          (click)="handleDownloadReport(element.report.pdfId, $event)"
        >
          <mat-icon>description</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="expandedElement = expandedElement === row ? null : row"
      class="element-row"
      [class.expanded-row]="expandedElement === row"
      [class.shutdown]="row.shutdown"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="example-detail-row"
    ></tr>
  </table>
</div>
