import { Component, OnInit, Output, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { takeUntil, take, debounceTime } from 'rxjs/operators';
import { SearchBarService } from '../../../inspections/services/search-bar.service';
import { CognitiveSearchIndexes } from '../../../shared/services/suggestion.service';
import {
  emptySearchBarFormInspectionAggregateEnergetic,
  emptySearchBarFormInspectionAggregateGeneral,
  emptySearchBarFormInspectionAggregateHygiene,
  emptySearchBarFormInspectionGeneral,
  SearchBarFormInspection,
} from '../../../inspections/models/SearchBarFormInspection';
import Utils from '../../../utils/utils';

@Component({
  selector: 'backoffice-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Input() showStatus = false;
  @Input() showEnInFilters = false;
  @Input() showDueDates = false;
  @Input() showAggregateNumberFields = false;
  @Output() changed: Observable<SearchBarFormInspection>;
  searchForm!: FormGroup;

  expandInspectionFilters = false;
  expandAggregateFilters = false;
  expandHygieneAggregateFilters = false;
  expandEnergeticAggregateFilters = false;

  isAnyInspectionFilterSet = false;
  isAnyAggregateGeneralFilterSet = false;
  isAnyAggregateHygieneFilterSet = false;
  isAnyAggregateEnergeticFilterSet = false;

  inspectionIndex = CognitiveSearchIndexes.Inspections;
  aggregateIndex = CognitiveSearchIndexes.Aggregates;

  _destroy$ = new Subject<void>();

  constructor(private readonly fb: FormBuilder, private readonly service: SearchBarService) {
    // assign here so it is available in parents' ngOnInit
    this.changed = this.service.changes;
  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      inspectionData: this.fb.group(emptySearchBarFormInspectionGeneral()),
      aggregateData: this.fb.group(emptySearchBarFormInspectionAggregateGeneral()),
      hygieneAggregateData: this.fb.group(emptySearchBarFormInspectionAggregateHygiene()),
      energeticAggregateData: this.fb.group(emptySearchBarFormInspectionAggregateEnergetic()),
    });

    this.changed.pipe(take(1)).subscribe((initialValue) => {
      this.searchForm.patchValue(initialValue);
    });

    this.searchForm.valueChanges.pipe(takeUntil(this._destroy$), debounceTime(300)).subscribe((formValue) => {
      this.service.next(formValue);
    });

    // update the filter states without delay
    this.searchForm.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((formValue) => {
      this.updateFilterStatus(formValue);
    });
  }

  updateFilterStatus(formValue: SearchBarFormInspection) {
    this.isAnyInspectionFilterSet = Utils.toNullIfValuesNull(formValue.inspectionData) !== null;
    this.isAnyAggregateGeneralFilterSet = Utils.toNullIfValuesNull(formValue.aggregateData) !== null;
    this.isAnyAggregateHygieneFilterSet = Utils.toNullIfValuesNull(formValue.hygieneAggregateData) !== null;
    this.isAnyAggregateEnergeticFilterSet = Utils.toNullIfValuesNull(formValue.energeticAggregateData) !== null;
  }

  toggleInspectionFilters() {
    this.expandInspectionFilters = !this.expandInspectionFilters;
  }

  toggleAggregateFilters() {
    this.expandAggregateFilters = !this.expandAggregateFilters;
  }

  toggleHygieneAggregateFilters() {
    this.expandHygieneAggregateFilters = !this.expandHygieneAggregateFilters;
  }

  toggleEnergeticAggregateFilters() {
    this.expandEnergeticAggregateFilters = !this.expandEnergeticAggregateFilters;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  reset() {
    this.searchForm.reset({});
  }

  refresh() {
    this.service.forceNext(this.searchForm.value);
  }
}
