import { Component, OnInit, ViewChildren, QueryList, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';

import { map, shareReplay, startWith, take, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { RecurrentAggregateTableComponent } from './recurrent-aggregate-table/recurrent-aggregate-table.component';
import fileSaver from 'file-saver';
import { SelectionChange } from '@angular/cdk/collections';
import { InspectionService } from '../../services';
import { PlanningExcelService } from '../../../shared/services';
import { RecurrentInspectionAggregate, RecurrentInspectionDetail } from '../../models';
import { NotificationService } from '../../../services/notification.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import HttpHeadersUtils from '../../../utils/HttpHeaderExtensions';

interface RouteParams {
  id: string;
  [k: string]: string;
}

@Component({
  selector: 'backoffice-recurrent-inspection-detail',
  templateUrl: './recurrent-inspection-detail.component.html',
  styleUrls: ['./recurrent-inspection-detail.component.scss'],
})
export class RecurrentInspectionDetailComponent implements AfterViewInit, OnInit {
  @ViewChildren(RecurrentAggregateTableComponent)
  tables!: QueryList<RecurrentAggregateTableComponent>;

  inspection$!: Observable<RecurrentInspectionDetail>;
  planningExcelDownloadEnabled$!: Observable<boolean>;
  isLoadingExcel = false;

  inspectionId!: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private inspectionService: InspectionService,
    private planningExcelService: PlanningExcelService,
    private route: ActivatedRoute,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.inspectionId = (this.route.snapshot.params as RouteParams).id;
    this.inspection$ = this.inspectionService.getRecurrentInspectionDetail(this.inspectionId).pipe(shareReplay());
  }

  ngAfterViewInit() {
    const excelEnabledRlt$ = this.tables.changes.pipe(
      take(1),
      switchMap((list: QueryList<RecurrentAggregateTableComponent>) => {
        return combineLatest([
          list.first.selection.changed.pipe(startWith(false)),
          list.last.selection.changed.pipe(startWith(false)),
        ]) as unknown as Observable<Array<false | SelectionChange<RecurrentInspectionAggregate>>>;
      }),
      map(([selection, otherSelection]) => {
        return (selection && selection.source.hasValue()) || (otherSelection && otherSelection.source.hasValue());
      }),
      startWith(false),
      distinctUntilChanged()
    );

    this.planningExcelDownloadEnabled$ = this.inspection$.pipe(
      switchMap(() => {
        return excelEnabledRlt$;
      })
    );

    this.cdr.detectChanges();
  }

  async generatePlanningExcel() {
    this.isLoadingExcel = true;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const aggregateIds = this.tables.first.selection.selected.map((aggregate) => aggregate.id)!;
    const secondaryAggregateIds = this.tables.last.selection.selected.map((aggregate) => aggregate.id);

    const combinedIds = aggregateIds.concat(secondaryAggregateIds);

    this.planningExcelService.downloadPlanningExcel(combinedIds).subscribe({
      next: (excelResponse) => {
        const body = excelResponse.body;
        if (body != null) {
          const filename = HttpHeadersUtils.getFilenameFromContentDisposition(excelResponse.headers);
          fileSaver.saveAs(body, filename);
        } else {
          console.error('Excel response body missing');
        }
      },

      error: (e) => {
        this.isLoadingExcel = false;
        const httpError = e as HttpErrorResponse;
        const defaultMsg = `Fehler beim Erzeugen des Planungs-Excels! Fehlercode: ${httpError.status}`;

        if (httpError.status === HttpStatusCode.BadRequest) {
          httpError.error.text().then((responseText: string) => {
            let msg = defaultMsg;
            try {
              const jsonPayload = JSON.parse(responseText);
              const keyMessage = 'message';
              if (keyMessage in jsonPayload && jsonPayload[keyMessage]) {
                msg = jsonPayload[keyMessage];
              }
            } catch (e) {
              // fallback to default message
            }
            this.notificationService.errorMessage(msg);
          });
        }
        this.notificationService.errorMessage(defaultMsg);
      },

      complete: () => {
        this.isLoadingExcel = false;
      },
    });
  }
}
