import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit, Input } from '@angular/core';
import { EnergeticInspectionStatus } from '../../../shared/models';

interface InspectionProgressStep {
  status: EnergeticInspectionStatus;
  completed: boolean;
  optional: boolean;
}

@Component({
  selector: 'backoffice-inspection-progress-energetic',
  templateUrl: './inspection-progress-energetic.component.html',
  styleUrls: ['./inspection-progress-energetic.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class InspectionProgressEnergeticComponent implements OnInit {
  @Input() status!: EnergeticInspectionStatus;
  steps: InspectionProgressStep[] = [];
  selectedIndex = 0;

  ngOnInit() {
    let matched = false;
    Object.keys(EnergeticInspectionStatus).forEach((value, index) => {
      if (value === this.status) {
        matched = true;
        this.selectedIndex = index;
      }
      this.steps.push({
        completed: this.status && (!matched || (this.status === EnergeticInspectionStatus.COMPLETED && matched)),
        status: value as EnergeticInspectionStatus,
        optional: false,
      });
    });
  }
}
