<mat-form-field subscriptSizing="dynamic">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [formControl]="statusControl"
    multiple
    panelClass="searchbar-list StatusComponent__Panel"
  >
    <mat-option
      *ngFor="let statusItem of inspectionStatuses"
      [value]="statusItem"
    >
      {{ 'inspections.status.' + statusItem + '.text' | enum }}
    </mat-option>
  </mat-select>
</mat-form-field>
