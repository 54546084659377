import { Component } from '@angular/core';

import filesaver from 'file-saver';
import { PlanningExcelService } from '../../../shared/services';
import HttpHeadersUtils from '../../../utils/HttpHeaderExtensions';

@Component({
  selector: 'backoffice-planning-excel-download-button',
  templateUrl: './planning-excel-download-button.component.html',
  styleUrls: ['./planning-excel-download-button.component.scss'],
})
export class PlanningExcelDownloadButtonComponent {
  constructor(private readonly planningExcelService: PlanningExcelService) {}

  downloadTemplate() {
    this.planningExcelService.downloadPlanningExcelTemplate().subscribe((response) => {
      const filename = HttpHeadersUtils.getFilenameFromContentDisposition(response.headers);

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      filesaver.saveAs(response.body!, filename);
    });
  }
}
