import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AggregatesRoutingModule } from './aggregates-routing.module';
import { AggregatesPageComponent } from './aggregates-page/aggregates-page.component';
import { AggregatesSubnavigationComponent } from './components/aggregates-subnavigation/aggregates-subnavigation.component';
import { AggregateSearchBarComponent } from './components/aggregate-search-bar/aggregate-search-bar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material/material.module';

@NgModule({
  declarations: [
    AggregatesPageComponent,
    AggregatesSubnavigationComponent,
    AggregateSearchBarComponent,
  ],
  imports: [
    CommonModule,
    AggregatesRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    SharedModule,
  ],
})
export class AggregatesModule {}
