import { Component, OnInit, ViewChild } from '@angular/core';
import { BARCODE_RANGES } from '../models';
import { FormBuilder, FormGroup, Validators, FormGroupDirective, AbstractControl } from '@angular/forms';
import { BarcodeService } from '../services/barcode.service';
import { finalize,map } from 'rxjs/operators';
import { iif } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { NotificationService } from '../../services/notification.service';
import { KeycloakRole } from '../../models';

const noDecimalValidator = (control: AbstractControl): { [key: string]: unknown; } | null => {
  if (!control.value) {
    return null;
  }
  return parseInt(control.value, 10) === parseFloat(control.value) ? null : { noDecimal: true };
};

@Component({
  selector: 'backoffice-barcodes-page',
  templateUrl: './barcodes-page.component.html',
  styleUrls: ['./barcodes-page.component.scss']
})
export class BarcodesPageComponent implements OnInit {
  // use FormGroupDirective to correctly reset form status with validations
  @ViewChild(FormGroupDirective, { static: true }) ngForm!: FormGroupDirective;

  barcodes = BARCODE_RANGES;
  form!: FormGroup;
  isLoading = false;
  isAllowedToGenerateBarcodes = false;

  constructor(
    private readonly barcodeService: BarcodeService,
    private readonly fb: FormBuilder,
    private readonly keycloak: KeycloakService,
    private readonly notificationServices: NotificationService
  ) { }

  ngOnInit() {
    this.isAllowedToGenerateBarcodes = this.keycloak.isUserInRole(KeycloakRole.BACKOFFICE_ADMIN) || this.keycloak.isUserInRole(KeycloakRole.BACKOFFICE_PLANNER);

    this.form = this.fb.group({
      barcode: [null, Validators.required],
      sheetCount: [null, [Validators.required, Validators.min(1), Validators.max(50), noDecimalValidator]]
    });
  }

  onSubmit(print = true) {
    const params = {
      prefix: this.form.value.barcode.prefix,
      sheetCount: parseInt(this.form.value.sheetCount, 10)
    };

    if (this.form.valid) {
      this.isLoading = true;

      iif(
        () => print,
        this.barcodeService.printSheets(params)
          // printing will show success message
          .pipe(
            map(() => { this.notificationServices.successMessage('Druckauftrag übermittelt'); })
          ),
        this.barcodeService.downloadSheets(params)
      ).pipe(
        finalize(() => {
          this.isLoading = false;
        })
      ).subscribe((response) => {
        if (response instanceof Blob) {
          const reportUrl = window.URL.createObjectURL(response);
          window.open(reportUrl, '_blank');
        }
        this.ngForm.resetForm();
      }, () => {
        this.notificationServices.errorMessage('Anfrage fehlgeschlagen');
      });
    }
  }
}
