import { HttpParams } from '@angular/common/http';

export interface ApiPage<T> {
  content: T[];
  pageable: ApiPagePageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  numberOfElements: number;
  first: boolean;
  number: number;
  sort: ApiPageSortable;
  empty: boolean;
}

export interface ApiPageParams {
  page: number;
  size: number;
  query: string;
  sort: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any;
}

const isObjectLike = (val: unknown): val is object => (val !== null) && (typeof val === 'object') && (!Array.isArray(val));

export const toStringParams = (object: Partial<ApiPageParams>): HttpParams => {

  return Object.entries(object)
    .reduce(
      (params, [key, value]) => params.set(
        // HttpParams allows only strings so we manually transform the objects into strings while skipping null values
        key, isObjectLike(value) ? JSON.stringify(value, (k, v) => v === null ? undefined : v) : value
      ),
      new HttpParams()
    );
};


interface ApiPagePageable {
  sort: ApiPageSortable;
  offset: number;
  pageSize: number;
  pageNumber: number;
  paged: boolean;
  unpaged: boolean;
}

interface ApiPageSortable {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}
