import { KeycloakService } from 'keycloak-angular';
import { KeycloakRole } from '../../../models';
import { InspectionStatus, SearchableInspectionResponse } from '../../../shared/models';

export const generateChangeStatusMenuItems = (inspection: SearchableInspectionResponse, keycloakService: KeycloakService)
    : InspectionStatus[] => {
    const items: InspectionStatus[] = [];
    const isPlanner = keycloakService.isUserInRole(KeycloakRole.BACKOFFICE_PLANNER);
    const isShipper = keycloakService.isUserInRole(KeycloakRole.BACKOFFICE_SHIPPER);


    // This is normally done automatically
    // CREATED -> PLANNED  -- By BACKOFFICE_PLANNER
    if (inspection.status === InspectionStatus.CREATED && isPlanner) {
        items.push(InspectionStatus.PLANNED);
    }

    // PLANNED -> CREATED  -- By BACKOFFICE_PLANNER
    if (inspection.status === InspectionStatus.PLANNED && isPlanner) {
        items.push(InspectionStatus.CREATED);
    }

    // READY_FOR_SHIPMENT -> SHIPPED_TO_CUSTOMER  -- By BACKOFFICE_SHIPPER
    if (inspection.status === InspectionStatus.READY_FOR_SHIPMENT && isShipper) {
        items.push(InspectionStatus.SHIPPED_TO_CUSTOMER);
    }

    // SHIPPED_TO_CUSTOMER -> READY_FOR_SHIPMENT  -- By BACKOFFICE_SHIPPER
    if (inspection.status === InspectionStatus.SHIPPED_TO_CUSTOMER && isShipper) {
        items.push(InspectionStatus.READY_FOR_SHIPMENT);
    }

    return items;
};
