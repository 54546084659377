import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  CognitiveSearchIndexes,
  SuggestionsService,
} from '../../services/suggestion.service';
import { of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-textfield-filter',
  templateUrl: './textfield-filter.component.html',
  styleUrls: ['./textfield-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextfieldFilterComponent),
      multi: true,
    },
  ],
})
export class TextfieldFilterComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() label!: string;
  @Input() cognitiveSearchIndex!: CognitiveSearchIndexes;
  @Input() suggestionField!: string;

  _filterText: string | null = null;
  _destroy$ = new Subject<void>();

  textfieldFilterControl!: FormControl;
  filteredOptions: string[] = [];
  // long variable name prevent istanbul from breaking snapshots https://github.com/thymikee/jest-preset-angular/issues/85
  displayFunction = (selectedOption: string) => {
    return !selectedOption ? '' : selectedOption;
  };

  get filterText() {
    return this._filterText;
  }
  set filterText(val) {
    this._filterText = val;
    this.propagateChange(this._filterText);
  }

  constructor(private readonly suggestionService: SuggestionsService) {}

  ngOnInit() {
    this.textfieldFilterControl = new FormControl('');

    this.textfieldFilterControl.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(300),
        switchMap((searchString?: string) => {
          if (searchString && searchString.length > 0) {
            this.filterText = searchString;
            if (
              this.suggestionField &&
              (this.cognitiveSearchIndex ===
                CognitiveSearchIndexes.Aggregates ||
                this.cognitiveSearchIndex ===
                  CognitiveSearchIndexes.Inspections)
            ) {
              return this.suggestionService.searchSuggestions(
                this.cognitiveSearchIndex,
                this.suggestionField,
                searchString
              );
            }
          } else {
            this.filterText = null;
          }
          return of([]); // use no suggester
        })
      )
      .subscribe((options) => {
        this.filteredOptions = options;
      });
  }

  writeValue(value: string) {
    this.filterText = value;
    if (this.textfieldFilterControl) {
      this.textfieldFilterControl.setValue(this.filterText);
    }
  }
  registerOnChange(fn: (_: unknown) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched() { /* empty on purpose */ }

  propagateChange = (_: unknown) => { /* empty on purpose */ };

  clear() {
    this.filterText = '';
    this.textfieldFilterControl.setValue('');
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
