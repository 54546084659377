import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformReportTimestamp',
})
export class ReportTimestampPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: string | null | undefined): string | undefined | null {
    let output: string | null | undefined;

    if (value === undefined) {
      output = undefined;
    } else if (value === null) {
      output = 'nicht vorhanden';
    } else if (value === 'generating') {
      output = 'wird generiert';
    } else {
      try {
        output = this.datePipe.transform(value, 'medium');
      } catch (error) {
        // console.log('Failed to transform value ' + value.toString() + ' to date')
        output = value; // just forward the input value of the pipe
      }
    }

    return output;
  }
}
