import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecurrentInspectionsPageComponent } from './recurrent-inspections-page/recurrent-inspections-page.component';
import { InspectionsPageComponent } from './inspections-page/inspections-page.component';
import { SubnavigationComponent } from './components/subnavigation/subnavigation.component';
import { RecurrentInspectionDetailComponent } from './recurrent-inspections-page/recurrent-inspection-detail/recurrent-inspection-detail.component';
import { PlannedInspectionsPageComponent } from './planned-inspections-page/planned-inspections-page.component';
import { InspectionDetailComponent } from './inspections-page/inspection-detail/inspection-detail.component';
import { AppGuard } from '../guards/app.guard';

const routes: Routes = [
  {
    path: 'inspections',
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        outlet: 'subnavigation',
        component: SubnavigationComponent,
      },
      {
        path: 'recurrent/:id',
        component: RecurrentInspectionDetailComponent,
      },
      {
        path: 'recurrent',
        component: RecurrentInspectionsPageComponent,
      },
      {
        path: 'all/:id',
        component: InspectionDetailComponent,
      },
      {
        path: 'all',
        component: InspectionsPageComponent,
      },
      {
        path: 'planned',
        component: PlannedInspectionsPageComponent,
      },
      {
        path: '',
        redirectTo: 'recurrent',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InspectionsRoutingModule {}
