import { Component, Input } from '@angular/core';
import {
  AggregateResponseSummary,
  IssueSeverity,
} from '../../../../shared/models';
@Component({
  selector: 'backoffice-aggregate-validations',
  templateUrl: './aggregate-validations.component.html',
  styleUrls: ['./aggregate-validations.component.scss'],
})
export class AggregateValidationsComponent {
  @Input() aggregate!: AggregateResponseSummary;

  IssueSeverity = IssueSeverity;
}
