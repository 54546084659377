import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'backoffice-snackbar-message',
  templateUrl: './snackbar-message.component.html',
  styleUrls: ['./snackbar-message.component.scss']
})
export class SnackbarMessageComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { icon: string; message: string; error?: boolean }
  ) { }

}
