import { SelectionModel } from '@angular/cdk/collections';
import { Component, ViewChild, OnInit } from '@angular/core';
import { Observable, combineLatest, BehaviorSubject, merge, EMPTY } from 'rxjs';

import { finalize, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { InspectionService } from '../services';
import { SearchBarComponent } from '../components/search-bar/search-bar.component';
import { MatDialog } from '@angular/material/dialog';
import { MergeReportDialogComponent, MergeReportDialogData } from '../components/merge-report-dialog/merge-report-dialog.component';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakRole } from '../../models';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  SearchBarFormInspectionAggregateEnergetic,
  SearchBarFormInspectionAggregateGeneral,
  SearchBarFormInspectionAggregateHygiene,
  SearchBarFormInspectionGeneral,
} from '../models/SearchBarFormInspection';
import Utils from '../../utils/utils';
import '../../utils/documentExtensions';
import { initialPage, pageSizeOptions } from '../../config';
import { addressToString, SearchableInspectionResponse } from '../../shared/models';
import { PlanningExcelService } from '../../shared/services';
import { NotificationService } from '../../services/notification.service';
import {
  SearchParameterInspectionAggregateEnergetic,
  SearchParameterInspectionAggregateGeneral,
  SearchParameterInspectionAggregateHygiene,
  SearchParameterInspectionGeneral,
} from '../models/CognitiveSearchParameter';
import filesaver from 'file-saver';
import HttpHeadersUtils from '../../utils/HttpHeaderExtensions';

@Component({
  selector: 'backoffice-inspections-page',
  templateUrl: './inspections-page.component.html',
  styleUrls: ['./inspections-page.component.scss'],
})
export class InspectionsPageComponent implements OnInit {
  readonly initialPage = initialPage;
  readonly pageSizeOptions = pageSizeOptions;

  readonly initialSort = {
    active: 'startDate',
    direction: 'desc',
  } as Sort;

  readonly locationToString = addressToString;
  readonly displayedColumns: string[] = [
    'select',
    'inspectionNumber',
    'customer.name',
    'location.name',
    'location.zip',
    'location.city',
    'location.street',
    'startDate',
    'reportTimestamp',
    'status',
    'hygieneData.status',
    'energeticData.status',
    'actions-newTab',
  ];

  inspections$!: Observable<SearchableInspectionResponse[]>;
  private readonly reloadSubject = new BehaviorSubject(true);
  totalItems$!: Observable<number>;

  isGeneratingMergeReport = false;
  isGeneratingMultipleReports = false;
  userAllowedToGenerateMultipleReports = false;

  public selection = new SelectionModel<SearchableInspectionResponse>(true);

  inspections!: SearchableInspectionResponse[];

  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(SearchBarComponent, { static: true })
  searchBar!: SearchBarComponent;

  constructor(
    private readonly inspectionService: InspectionService,
    private readonly planningExcelService: PlanningExcelService,
    private readonly notificationService: NotificationService,
    private readonly dialog: MatDialog,
    private readonly keycloak: KeycloakService
  ) {}

  convertToSearchParameterInspection(v: SearchBarFormInspectionGeneral): SearchParameterInspectionGeneral {
    return {
      inspectionNumber: Utils.toNullIfFalsy(v.inspectionNumber),
      customer: Utils.toNullIfFalsy(v.customer),
      location: Utils.toNullIfFalsy(v.location),
      operator: Utils.toNullIfFalsy(v.operator),
      status: Utils.toNullIfFalsy(v.status),
      statusHygiene: Utils.toNullIfFalsy(v.statusHygiene),
      statusEnergetic: Utils.toNullIfFalsy(v.statusEnergetic),
      inspectors: Utils.toNullIfFalsy(v.inspectors),
      startAfterDate: Utils.formatOrNull(v.startAfterDate),
      startBeforeDate: Utils.formatOrNull(v.startBeforeDate),
      endAfterDate: Utils.formatOrNull(v.endAfterDate),
      endBeforeDate: Utils.formatOrNull(v.endBeforeDate),
    };
  }

  convertToSearchParameterAggregate(v: SearchBarFormInspectionAggregateGeneral): SearchParameterInspectionAggregateGeneral {
    return {
      name: Utils.toNullIfFalsy(v.name),
      atwId: Utils.toNullIfFalsy(v.atwId),
      reportPdfId: Utils.toNullIfFalsy(v.reportPdfId),
      type: Utils.toNullIfFalsy(v.type),
      subType: Utils.toNullIfFalsy(v.subType),
      shutdown: v.shutdown, // do not use toNullIfFalsy as it would convert 'false' to 'null'. But 'false' is a valid value
      equipmentNumber: Utils.toNullIfFalsy(v.equipmentNumber),
      building: Utils.toNullIfFalsy(v.building),
      floor: Utils.toNullIfFalsy(v.floor),
      installationSite: Utils.toNullIfFalsy(v.installationSite),
    };
  }

  convertToSearchParameterHygieneAggregate(v: SearchBarFormInspectionAggregateHygiene): SearchParameterInspectionAggregateHygiene {
    return {
      inspectionTypes: Utils.toNullIfFalsy(v.inspectionType),
      inspector: Utils.toNullIfFalsy(v.inspector),
      shutdown: v.shutdown, // do not use toNullIfFalsy as it would convert 'false' to 'null'. But 'false' is a valid value
      isMostRecent: Utils.toNullIfFalsy(v.isMostRecent), // 'false' must be converted to 'null', since we want to list all aggregates!
      inspectionDateFrom: Utils.formatOrNull(v.inspectionDateFrom),
      inspectionDateTo: Utils.formatOrNull(v.inspectionDateTo),
      nextInspectionDateFrom: Utils.formatOrNull(v.nextInspectionDateFrom),
      nextInspectionDateTo: Utils.formatOrNull(v.nextInspectionDateTo),
    };
  }

  convertToSearchParameterEnergeticAggregate(v: SearchBarFormInspectionAggregateEnergetic): SearchParameterInspectionAggregateEnergetic {
    return {
      inspectionTypes: Utils.toNullIfFalsy(v.inspectionType),
      inspector: Utils.toNullIfFalsy(v.inspector),
      shutdown: v.shutdown, // do not use toNullIfFalsy as it would convert 'false' to 'null'. But 'false' is a valid value
      isMostRecent: Utils.toNullIfFalsy(v.isMostRecent), // 'false' must be converted to 'null', since we want to list all aggregates!
      inspectionDateFrom: Utils.formatOrNull(v.inspectionDateFrom),
      inspectionDateTo: Utils.formatOrNull(v.inspectionDateTo),
      nextInspectionDateFrom: Utils.formatOrNull(v.nextInspectionDateFrom),
      nextInspectionDateTo: Utils.formatOrNull(v.nextInspectionDateTo),
    };
  }

  ngOnInit() {
    this.userAllowedToGenerateMultipleReports = this.keycloak.isUserInRole(KeycloakRole.BACKOFFICE_ADMIN);

    document.addPreventDefaultForMouseMiddleEventListener('.inspection-table');

    const reload$ = merge(this.reloadSubject.asObservable(), this.planningExcelService.uploaded);

    const sortChange$ = this.sort.sortChange.pipe(startWith(this.initialSort));

    const pageChange$ = this.paginator.page.pipe(startWith(this.initialPage));

    const searchChange$ = this.searchBar.changed.pipe(
      tap(() => {
        this.paginator.firstPage();
      }), // always set pagination to page 1 after filter has been changed
      map((values) => ({
        // the name must match to the expected parameter in the backend
        inspectionParameter: Utils.toNullIfValuesNull(this.convertToSearchParameterInspection(values.inspectionData)),
        aggregateParameter: Utils.toNullIfValuesNull(this.convertToSearchParameterAggregate(values.aggregateData)),
        hygieneAggregateParameter: Utils.toNullIfValuesNull(this.convertToSearchParameterHygieneAggregate(values.hygieneAggregateData)),
        energeticAggregateParameter: Utils.toNullIfValuesNull(
          this.convertToSearchParameterEnergeticAggregate(values.energeticAggregateData)
        ),
      }))
    );

    const apiResult$ = combineLatest([sortChange$, pageChange$, reload$, searchChange$]).pipe(
      switchMap(([sort, page, _, search]) => {
        return this.inspectionService.getInspections({
          page: page.pageIndex,
          size: page.pageSize,
          sort: `${sort.active},${sort.direction}`,
          ...search,
        });
      }),
      shareReplay()
    );

    this.totalItems$ = apiResult$.pipe(map((apiPage) => apiPage.totalElements));

    this.inspections$ = apiResult$.pipe(
      tap((apiPage) => {
        this.inspections = apiPage.content;
        // don't select the rows by default.
        this.selection.clear();
      }),
      map((apiPage) => apiPage.content)
    );
  }

  reloadList() {
    setTimeout(() => {
      this.reloadSubject.next(true);
    }, 500); // index needs time to be updated after modifying inspection data
  }

  openInspectionNewTab(event: MouseEvent, inspectionId: string) {
    event.stopPropagation();
    if (inspectionId && inspectionId !== '') {
      window.open(window.location.href + '/' + inspectionId);
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.areAllSelected()
      ? this.selection.clear()
      : this.inspections.forEach((row) => {
          // only select enabled ones
          if (this.isRowEnabled(row)) {
            this.selection.select(row);
          }
        });
  }

  isRowEnabled(_row: SearchableInspectionResponse) {
    return true;
  }

  areAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.inspections.filter((row) => this.isRowEnabled(row)).length;

    return numSelected === numRows;
  }
  async generateMultipleReports() {
    this.isGeneratingMultipleReports = true;
    const version = '2';
    const inspectionIds = this.selection.selected.map((inspection) => inspection.id);

    inspectionIds.forEach((inspectionId) => {
      this.inspectionService
        .generateDocumentation(inspectionId, version)
        .pipe(
          finalize(() => {
            this.isGeneratingMultipleReports = false;
          })
        )
        .subscribe(
          () => {
            console.log('Successfully requested report generation for ' + inspectionId.toString());
          },
          (error) => {
            console.log('Generate report request failed for ' + inspectionId.toString());
            console.log(error);
            this.isGeneratingMergeReport = false;
          }
        );
    });
  }

  async generateMergeReport() {
    this.isGeneratingMergeReport = true;
    const inspectionIds = this.selection.selected.map((inspection) => inspection.id);

    let observable: Observable<HttpResponse<Blob>>;

    if (inspectionIds.length === 1) {
      // if only 1 inspection is selected we just get the default report
      observable = this.inspectionService.getMergeReport(inspectionIds, 'unused');
    } else {
      // The user must provide a description of the location for the merged inspections
      const minLength = MergeReportDialogComponent.descriptionLengthMin;
      const maxLength = MergeReportDialogComponent.descriptionLengthMax;
      observable = this.dialog
        .open<MergeReportDialogComponent, MergeReportDialogData>(MergeReportDialogComponent, {
          data: {
            title: 'Dokumentationszusammenfassung erstellen',
            text: `Umfasst ${inspectionIds.length} Inspektionen`,
            labelName: `Beschreibung (${minLength} - ${maxLength} Zeichen)`,
            save: 'Erstellen',
            cancel: 'Abbrechen',
          },
        })
        .afterClosed()
        .pipe(
          switchMap((form) => {
            if (form === undefined) {
              return EMPTY; // User canceled the dialog
            }
            let locationDescription: string = form.input;
            locationDescription = locationDescription.trim();
            return this.inspectionService.getMergeReport(inspectionIds, locationDescription);
          })
        );
    }

    observable
      .pipe(
        finalize(() => {
          this.isGeneratingMergeReport = false;
        })
      )
      .subscribe({
        next: (response) => {
          const filename = HttpHeadersUtils.getFilenameFromContentDisposition(response.headers);
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filesaver.saveAs(response.body!, filename);
          this.isGeneratingMergeReport = false;
        },
        error: (error) => {
          this.isGeneratingMergeReport = false;
          // extract the error message from the blob
          error.error.text().then((responseText: string) => {
            try {
              const jsonPayload = JSON.parse(responseText);
              const keyMessage = 'message';
              this.notificationService.errorMessage(jsonPayload[keyMessage]);
            } catch (e) {
              const httpError = error as HttpErrorResponse;
              this.notificationService.errorMessage('Fehler beim Erstellen der Zusammenfassung: ' + httpError.message);
            }
          });
        },
      });
  }
}
