import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateComponent } from './search-bar-components/date/date.component';
import { AggregateTypeComponent } from './search-bar-components/aggregate-type/aggregate-type.component';
import { AggregateSubTypeComponent } from './search-bar-components/aggregate-sub-type/aggregate-sub-type.component';
import { AggregateStatusComponent } from './search-bar-components/aggregate-status/aggregate-status.component';
import { AggregateHygieneInspectionTypeComponent } from './search-bar-components/aggregate-hygiene-inspection-type/aggregate-hygiene-inspection-type.component';
import { TextfieldFilterComponent } from './search-bar-components/textfield-filter/textfield-filter.component';
import { NumberfieldFilterComponent } from './search-bar-components/numberfield-filter/numberfield-filter.component';

import { ReactiveFormsModule } from '@angular/forms';
import { CreateExcelButtonComponent } from './components/create-excel-button/create-excel-button.component';
import { CreateMergeReportButtonComponent } from './components/create-merge-report-button/create-merge-report-button.component';
import { GenerateMultipleReportsButtonComponent } from './components/generate-multiple-reports-button/generate-multiple-reports-button.component';
import { CheckboxFilterComponent } from './search-bar-components/checkbox-filter/checkbox-filter.component';
import { AggregateShutdownComponent } from './search-bar-components/aggregate-shutdown/aggregate-shutdown.component';
import { MaterialModule } from '../material/material.module';
import { EnumPipe } from './enum.pipe';
import { UnsetPipe } from './unset.pipe';
import { ReportTimestampPipe } from './report-timestamp.pipe';
import { AggregateEnergeticInspectionTypeComponent } from './search-bar-components/aggregate-energetic-inspection-type/aggregate-energetic-inspection-type.component';

@NgModule({
  declarations: [
    DateComponent,
    AggregateTypeComponent,
    AggregateSubTypeComponent,
    AggregateStatusComponent,
    AggregateShutdownComponent,
    AggregateHygieneInspectionTypeComponent,
    AggregateEnergeticInspectionTypeComponent,
    TextfieldFilterComponent,
    NumberfieldFilterComponent,
    CreateExcelButtonComponent,
    CreateMergeReportButtonComponent,
    GenerateMultipleReportsButtonComponent,
    CheckboxFilterComponent,
    EnumPipe,
    UnsetPipe,
    ReportTimestampPipe,
  ],
  exports: [
    DateComponent,
    AggregateTypeComponent,
    AggregateSubTypeComponent,
    AggregateStatusComponent,
    AggregateShutdownComponent,
    AggregateHygieneInspectionTypeComponent,
    AggregateEnergeticInspectionTypeComponent,
    TextfieldFilterComponent,
    NumberfieldFilterComponent,
    CreateExcelButtonComponent,
    CreateMergeReportButtonComponent,
    GenerateMultipleReportsButtonComponent,
    CheckboxFilterComponent,
    EnumPipe,
    UnsetPipe,
    ReportTimestampPipe,
  ],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule],
})
export class SharedModule {}
