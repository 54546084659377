<button type="button" mat-raised-button color="primary" [disabled]="disabled">
  <span>
    <mat-icon *ngIf="!isLoadingExcel">save_alt</mat-icon>
    <mat-spinner
      diameter="24"
      mode="indeterminate"
      *ngIf="isLoadingExcel"
    ></mat-spinner>
    Planungs-Excel erzeugen
  </span>
</button>
