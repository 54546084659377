import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'backoffice-generate-multiple-reports-button',
  templateUrl: './generate-multiple-reports-button.component.html',
  styleUrls: ['./generate-multiple-reports-button.component.scss'],
})
export class GenerateMultipleReportsButtonComponent {
  @Input() isGeneratingMultipleReports!: boolean;
  @HostBinding('class.disabled')
  @Input()
  disabled!: boolean;
}
