import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationDialogData } from '../../services/notification.service';

@Component({
  selector: 'backoffice-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
})
export class NotificationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: NotificationDialogData
  ) {}
}
