import { Component, OnInit, Input, forwardRef, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { InspectionStatus } from '../../../../shared/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StatusComponent),
      multi: true,
    },
  ],
})
export class StatusComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() label!: string;

  inspectionStatuses = [...Object.keys(InspectionStatus)];

  statusControl!: FormControl;

  _status: InspectionStatus[] | null = null;
  get status() {
    return this._status;
  }
  set status(val) {
    this._status = val;
    this.propagateChange(this._status);
  }

  _destroy$ = new Subject<void>();

  ngOnInit() {
    this.statusControl = new FormControl(null);

    this.statusControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        if (Array.isArray(value) && value.length > 0) {
          this.status = value;
        } else {
          this.status = null;
        }
      });
  }

  propagateChange = (_: unknown) => {
    /* empty on purpose */
  };

  registerOnChange(fn: (_: unknown) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
    /* empty on purpose */
  }

  writeValue(value: InspectionStatus[]) {
    this.status = value;
    if (this.statusControl) {
      this.statusControl.setValue(this.status);
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
