export enum  IssueSeverity {
    REQUIRED = 'REQUIRED',
    RECOMMENDED = 'RECOMMENDED',
    OPTIONAL = 'OPTIONAL'
}

export enum IssueKey {
    /** value: ID of result */
    NO_BARCODE = 'NO_BARCODE',
    /** value: ID of result */
    NO_LAB = 'NO_LAB',
    /** value: ID of result */
    NO_LAB_RESULT = 'NO_LAB_RESULT',
    /** value: aggregate.name */
    NO_OPERATOR = 'NO_OPERATOR',
    /** value: aggregate.name */
    NO_INSPECTION_DATE = 'NO_INSPECTION_DATE',
    /** value: aggregate.name */
    NO_INSPECTOR = 'NO_INSPECTOR',
    /** value: aggregate.name */
    NO_PHOTO_OF_AGGREGATE = 'NO_PHOTO_OF_AGGREGATE',
    /** value: aggregate.name */
    NO_ZONES = 'NO_ZONES',
    /** value: aggregate.name: zone.name */
    NO_ELEMENTS = 'NO_ELEMENTS',
    /** value: aggregate.name: zone.name: element.name */
    NO_PHOTO_OF_ELEMENT = 'NO_PHOTO_OF_ELEMENT',
    /** value: aggregate.name: person.systemName */
    NO_SIGNATURE_IN_STORE = 'NO_SIGNATURE_IN_STORE',
    /** value: aggregate.name: filename */
    NO_PHOTO_IN_STORE = 'NO_PHOTO_IN_STORE'
}

export interface AggregateIssue {
    aggregateName: string;
    atwId?: string;
    severity: IssueSeverity;
    key: IssueKey;
    value?: string;
}
