export interface AddressSummary {
    id: string;
    name: string;
    street?: string;
    zip: string;
    city: string;
    country: string;
}

export interface AddressDetail extends AddressSummary {
    name1?: string;
    name2?: string;
    postbox?: string;
}

export const addressToString = (address: AddressSummary) => {
    return `${address.name}, ${address.zip} ${address.city}`;
};

export const addressToCity = (address: AddressSummary) => {
    return `${address.zip} ${address.city}`;
};
