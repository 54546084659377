import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: ['./checkbox-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxFilterComponent),
      multi: true,
    },
  ],
})
export class CheckboxFilterComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() label!: string;
  checkboxFilterControl!: FormControl;

  _checked: boolean | null = null;
  get checked() {
    return this._checked;
  }
  set checked(val) {
    this._checked = val ? true : null; // If not checked is null and not false
    this.propagateChange(this._checked);
  }

  _destroy$ = new Subject<void>();

  ngOnInit() {
    this.checkboxFilterControl = new FormControl();

    this.checkboxFilterControl.valueChanges
      .pipe(takeUntil(this._destroy$), debounceTime(300))
      .subscribe((checked) => {
        this.checked = checked;
      });
  }

  writeValue(value: boolean) {
    this.checked = value;
    if (this.checkboxFilterControl) {
      this.checkboxFilterControl.setValue(this.checked);
    }
  }
  registerOnChange(fn: (_: unknown) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
    /* empty on purpose */
  }

  propagateChange = (_: unknown) => {
    /* empty on purpose */
  };

  clear() {
    this.checked = false;
    this.checkboxFilterControl.setValue(false);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
