import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AggregateSubType } from '../../models';

@Component({
  selector: 'backoffice-aggregate-sub-type',
  templateUrl: './aggregate-sub-type.component.html',
  styleUrls: ['./aggregate-sub-type.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AggregateSubTypeComponent),
      multi: true,
    },
  ],
})
export class AggregateSubTypeComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() label!: string;

  aggregateSubTypes = [...Object.keys(AggregateSubType)];

  aggregateSubTypeControl!: FormControl;

  _aggregateSubType: AggregateSubType[] | null = null;
  get aggregateSubType() {
    return this._aggregateSubType;
  }
  set aggregateSubType(val) {
    this._aggregateSubType = val;
    this.propagateChange(this._aggregateSubType);
  }

  _destroy$ = new Subject<void>();

  ngOnInit() {
    this.aggregateSubTypeControl = new FormControl(null);

    this.aggregateSubTypeControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        if (Array.isArray(value) && value.length > 0) {
          this.aggregateSubType = value;
        } else {
          this.aggregateSubType = null;
        }
      });
  }

  propagateChange = (_: unknown) => {
    /* empty on purpose */
  };

  registerOnChange(fn: (_: unknown) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
    /* empty on purpose */
  }

  writeValue(value: AggregateSubType[]) {
    this.aggregateSubType = value;
    if (this.aggregateSubTypeControl) {
      this.aggregateSubTypeControl.setValue(this.aggregateSubType);
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
