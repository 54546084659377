<button type="button" mat-raised-button color="primary" [disabled]="disabled">
  <span>
    <mat-icon *ngIf="!isGeneratingMultipleReports">save_alt</mat-icon>
    <mat-spinner
      diameter="24"
      mode="indeterminate"
      *ngIf="isGeneratingMultipleReports"
    ></mat-spinner>
    Berichte generieren
  </span>
</button>
