import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppGuard } from '../guards/app.guard';
import { BarcodesPageComponent } from './barcodes-page/barcodes-page.component';

const routes: Routes = [
  {
    path: 'barcodes',
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        component: BarcodesPageComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BarcodesRoutingModule { }
