import { Component, OnInit, Input } from '@angular/core';
import { AggregateResponseSummary, InspectionResponseDetailed } from '../../../shared/models';
import { RecurrentInspectionAggregate, RecurrentInspectionDetail } from '../../models';

interface InternalNoteAggregate {
  label: string;
  note: string;
}

const mapAggregates = (aggregates: Array<RecurrentInspectionAggregate | AggregateResponseSummary>): InternalNoteAggregate[] =>
  aggregates
    .filter((agg) => !!agg.internalNote)
    .map((aggregate) => {
      let label = aggregate.name;
      if (aggregate.atwId != null) {
        label += ` - ${aggregate.atwId}`;
      }
      if (aggregate.equipmentNumber != null) {
        label += ` - ${aggregate.equipmentNumber}`;
      }

      return {
        label: label,
        note: aggregate.internalNote,
      };
    });

@Component({
  selector: 'backoffice-internal-notes',
  templateUrl: './internal-notes.component.html',
  styleUrls: ['./internal-notes.component.scss'],
})
export class InternalNotesComponent implements OnInit {
  @Input() inspection!: RecurrentInspectionDetail | InspectionResponseDetailed;

  inspectionExpanded = false;
  aggregates: InternalNoteAggregate[] = [];

  ngOnInit() {
    this.inspectionExpanded = !!this.inspection.internalNote;
    this.aggregates = [...mapAggregates(this.inspection.aggregates)];
    if ('otherAggregates' in this.inspection) {
      this.aggregates = [...this.aggregates, ...mapAggregates(this.inspection.otherAggregates)];
    }
  }
}
