<mat-form-field subscriptSizing="dynamic">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [formControl]="aggregateShutdownControl"
    multiple
    panelClass="searchbar-list AggregateShutdownComponent__Panel"
  >
    <mat-option
      *ngFor="let shutdownItem of aggregateShutdownList"
      [value]="shutdownItem"
    >
      {{ 'aggregates.shutdown.' + shutdownItem | enum }}
    </mat-option>
  </mat-select>
</mat-form-field>
