import { KeycloakRole } from '../app/models';
import { BackofficeFrontendEnvironment } from './type';

export const environment: BackofficeFrontendEnvironment = {
  production: true,
  environment: 'dev',
  API_BASE: '/api/v3',
  version: 'vDEV',
  auth: {
    config: {
      url: 'https://auth.dev.priomon.de/auth',
      realm: 'atw',
      clientId: 'backoffice-frontend',
    },
    roles_required: [KeycloakRole.BACKOFFICE],
  },
};
