import { Component, OnInit, ViewChild } from '@angular/core';
import { InspectionService } from '../services';
import { Observable, BehaviorSubject, merge } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { Sort, MatSort } from '@angular/material/sort';
import { map, startWith, switchMap, shareReplay } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { initialPage, pageSizeOptions } from '../../config';
import { InspectionResponseSummary, InspectionStatus } from '../../shared/models';
import { PlanningExcelService } from '../../shared/services';

@Component({
  selector: 'backoffice-recurrent-inspections-page',
  templateUrl: './planned-inspections-page.component.html',
  styleUrls: ['./planned-inspections-page.component.scss'],
})
export class PlannedInspectionsPageComponent implements OnInit {
  readonly initialPage = initialPage;
  readonly pageSizeOptions = pageSizeOptions;

  readonly initialSort = {
    active: 'startDate',
    direction: 'desc',
  } as Sort;

  readonly displayedColumns: string[] = [
    'inspectionNumber',
    'customer.name',
    'location.name',
    'location.zip',
    'location.city',
    'location.street',
    'startDate',
    'inspectors',
    'status',
  ];

  inspections$!: Observable<InspectionResponseSummary[]>;
  private readonly reloadSubject = new BehaviorSubject(true);

  totalItems$!: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private readonly inspectionService: InspectionService, private readonly planningExcelService: PlanningExcelService) {}

  ngOnInit() {
    const reload$ = merge(this.reloadSubject.asObservable(), this.planningExcelService.uploaded);

    const sortChange$ = this.sort.sortChange.pipe(startWith(this.initialSort));

    const pageChange$ = this.paginator.page.pipe(startWith(this.initialPage));

    const apiResult$ = combineLatest([sortChange$, pageChange$, reload$]).pipe(
      switchMap(([sort, page, _]) => {
        return this.inspectionService.getPlannedInspections({
          status: [InspectionStatus.CREATED, InspectionStatus.PLANNED],
          page: page.pageIndex,
          size: page.pageSize,
          sort: `${sort.active},${sort.direction}`,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any);
      }),
      shareReplay()
    );

    this.totalItems$ = apiResult$.pipe(map((apiPage) => apiPage.totalElements));

    this.inspections$ = apiResult$.pipe(map((apiPage) => apiPage.content));
  }

  reloadList() {
    this.reloadSubject.next(true);
  }
}
