import { Component, OnInit, Input } from '@angular/core';
import { AggregateIssue } from '../../../../shared/models';

import { Observable } from 'rxjs';
import { AggregateService } from '../../../../shared/services/aggregate.service';

@Component({
  selector: 'backoffice-aggregate-issues',
  templateUrl: './aggregate-issues.component.html',
  styleUrls: ['./aggregate-issues.component.scss'],
})
export class AggregateIssuesComponent implements OnInit {
  @Input() aggregateId!: string;
  issues$!: Observable<AggregateIssue[]>;

  readonly displayedColumns: string[] = ['key', 'value', 'severity'];

  constructor(readonly aggregateService: AggregateService) {}

  ngOnInit() {
    this.issues$ = this.aggregateService.getIssues(this.aggregateId);
  }
}
