<mat-accordion>
    <mat-expansion-panel *ngIf="inspection.internalNote" [expanded]="inspectionExpanded">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Inspektion - Interne Bemerkungen:
        </mat-panel-title>
      </mat-expansion-panel-header>
  
      {{ inspection.internalNote }}
      
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="aggregates.length > 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Anlagen - Interne Bemerkungen ({{ aggregates.length }}):
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p *ngFor="let aggregate of aggregates" class="notes">
        <span>{{ aggregate.label }}</span>
        <span >{{ aggregate.note }}</span>
      </p>
    </mat-expansion-panel>
  </mat-accordion>