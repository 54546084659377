<mat-form-field subscriptSizing="dynamic">
  <mat-label>{{ label }}</mat-label>
  <input
    type="text"
    (keypress)="checkIfCharacterIsNumber($event)"
    matInput
    [matAutocomplete]="auto"
    [formControl]="numberfieldFilterControl"
    (keydown.enter)="$any($event.target).blur()"
  />
  <button
    matTooltip="Feld zurücksetzen"
    mat-icon-button
    matSuffix
    *ngIf="numberfieldFilterControl.value"
    (click)="clear()"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-form-field>

<mat-autocomplete
  panelWidth="auto"
  #auto="matAutocomplete"
  [displayWith]="displayFunction"
  autoActiveFirstOption
>
  <mat-option *ngFor="let option of filteredOptions" [value]="option">
    <span>{{ option }}</span>
  </mat-option>
</mat-autocomplete>
