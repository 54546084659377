import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BarcodesRoutingModule } from './barcodes-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { BarcodesPageComponent } from './barcodes-page/barcodes-page.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material/material.module';


@NgModule({
  declarations: [
    BarcodesPageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    BarcodesRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
  ]
})
export class BarcodesModule { }
