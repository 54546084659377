<table mat-table [dataSource]="issues$">
    <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef>Fehlerart</th>
        <td mat-cell *matCellDef="let element"> {{ 'aggregates.issues.key.' + element.key | enum }} </td>
    </ng-container>

    <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>Fehler</th>
        <td mat-cell *matCellDef="let element"> {{element.value }} </td>
    </ng-container>

    <ng-container matColumnDef="severity">
        <th mat-header-cell *matHeaderCellDef>Schweregrad</th>
        <td mat-cell *matCellDef="let element" [ngClass]="'AggregateIssues__Severity--' + element.severity">
            {{ 'aggregates.issues.severity.' + element.severity + '.text' | enum }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"></tr>
</table>
