import { Routes } from '@angular/router';
import { ErrorAccessDeniedComponent } from './components/error-access-denied/error-access-denied.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'inspections',
    pathMatch: 'full',
  },
  {
    path: 'access_denied',
    component: ErrorAccessDeniedComponent,
  },
];
