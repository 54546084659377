import moment from 'moment';

export default class Utils {
  /**
   * Converts the value to null if it evaluates to false
   * Attention if using this with boolean, as it will convert false to null which is probably not intended
   *
   * @param s the object to nullify if falsy
   * @returns the object or null if the object is falsy
   */
  static toNullIfFalsy<T>(s: T): T | null {
    return s ? s : null;
  }

  /**
   * Returns null if all first level values of the given object are null, otherwise return the object itself
   *
   * @param v the object to convert to null if required
   * @returns the object or null
   */
  static toNullIfValuesNull<T extends object>(v: T): T | null {
    const isNotNullNorProperty = (a: T) => a !== null && a !== Object.prototype;

    const allNull = Object.values(v).filter(isNotNullNorProperty).length === 0;
    return allNull ? null : v;
  }

  static formatOrNull(m: moment.Moment | undefined | null): string | null {
    return m ? m.format('YYYY-MM-DD') : null;
  }
}
