import { Component, Input } from '@angular/core';
import { EnergeticInspectionStatus } from '../../../shared/models';
import { SearchableInspectionResponse } from '../../../shared/models/CognitiveSearchResponse';

@Component({
  selector: 'backoffice-inspection-status-energetic',
  templateUrl: './inspection-status-energetic.component.html',
  styleUrls: ['./inspection-status-energetic.component.scss'],
})
export class InspectionStatusEnergeticComponent {
  @Input() inspection!: SearchableInspectionResponse;

  readonly statusList = EnergeticInspectionStatus;
}
