<mat-form-field subscriptSizing="dynamic">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [formControl]="aggregateInspectionTypeControl"
    multiple
    panelClass="searchbar-list AggregateEnergeticInspectionTypeComponent__Panel"
  >
    <mat-option *ngFor="let inspectionTypeItem of aggregateInspectionTypes" [value]="inspectionTypeItem">
      {{ 'aggregates.inspectionTypesEnergetic.' + inspectionTypeItem + '.text' | enum }}
    </mat-option>
  </mat-select>
</mat-form-field>
