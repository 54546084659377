/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';
import { ENUMS } from './enums';

@Pipe({
  name: 'enum',
})
export class EnumPipe implements PipeTransform {
  // maybe return missing translation but aggregate table uses the result
  // in boolean comparison
  transform(enumKey: string): string {
    if (enumKey.indexOf('.')) {
      const keys = enumKey.split('.');
      let search = ENUMS as any;
      for (const k of keys) {
        if (k in search) {
          search = search[k];
        } else {
          return '';
        }
      }
      return search;
    } else if (enumKey in ENUMS) {
      return (ENUMS as any)[enumKey];
    }
    return '';
  }
}
