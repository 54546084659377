import { Component, OnInit, ViewChild } from '@angular/core';
import { InspectionService } from '../services';
import { Observable, EMPTY } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { Sort, MatSort } from '@angular/material/sort';
import { map, startWith, switchMap, catchError, distinctUntilChanged, tap, shareReplay } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { SearchBarComponent } from '../components/search-bar/search-bar.component';
import { RecurrentSearchableInspectionResponse } from '../../shared/models/CognitiveSearchResponse';
import {
  SearchBarFormInspectionAggregateGeneral,
  SearchBarFormInspectionAggregateHygiene,
  SearchBarFormInspectionGeneral,
} from '../models/SearchBarFormInspection';
import Utils from '../../utils/utils';
import '../../utils/documentExtensions';
import { initialPage, pageSizeOptions } from '../../config';
import { NotificationService } from '../../services/notification.service';
import {
  SearchParameterInspectionAggregateGeneral,
  SearchParameterInspectionAggregateHygiene,
  SearchParameterInspectionGeneral,
} from '../models/CognitiveSearchParameter';

@Component({
  selector: 'backoffice-recurrent-inspections-page',
  templateUrl: './recurrent-inspections-page.component.html',
  styleUrls: ['./recurrent-inspections-page.component.scss'],
})
export class RecurrentInspectionsPageComponent implements OnInit {
  readonly initialPage = initialPage;
  readonly pageSizeOptions = pageSizeOptions;

  readonly initialSort = {
    active: 'endDate',
    direction: 'desc',
  } as Sort;

  readonly displayedColumns: string[] = [
    'inspectionNumber',
    'customer.name',
    'location.name',
    'location.zip',
    'location.city',
    'location.street',
    'aggregatesTotal',
    'endDate',
    'nextInspectionDueDate',
    'remainingDays',
    'actions',
  ];

  inspections$!: Observable<RecurrentSearchableInspectionResponse[]>;
  totalItems$!: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(SearchBarComponent, { static: true })
  searchBar!: SearchBarComponent;

  constructor(private readonly inspectionService: InspectionService, private readonly notificationService: NotificationService) {}

  convertToSearchParameterInspection(v: SearchBarFormInspectionGeneral): SearchParameterInspectionGeneral {
    return {
      inspectionNumber: Utils.toNullIfFalsy(v.inspectionNumber),
      customer: Utils.toNullIfFalsy(v.customer),
      location: Utils.toNullIfFalsy(v.location),
      operator: Utils.toNullIfFalsy(v.operator),
      inspectors: Utils.toNullIfFalsy(v.inspectors),
      startAfterDate: Utils.formatOrNull(v.startAfterDate),
      startBeforeDate: Utils.formatOrNull(v.startBeforeDate),
      endAfterDate: Utils.formatOrNull(v.endAfterDate),
      endBeforeDate: Utils.formatOrNull(v.endBeforeDate),
      dueFromDate: Utils.formatOrNull(v.dueFromDate),
      dueUntilDate: Utils.formatOrNull(v.dueUntilDate),
      numberOfAggregates: Utils.toNullIfFalsy(v.numberOfAggregates),
    };
  }

  convertToSearchParameterAggregate(v: SearchBarFormInspectionAggregateGeneral): SearchParameterInspectionAggregateGeneral {
    return {
      name: Utils.toNullIfFalsy(v.name),
      atwId: Utils.toNullIfFalsy(v.atwId),
      reportPdfId: Utils.toNullIfFalsy(v.reportPdfId),
      type: Utils.toNullIfFalsy(v.type),
      subType: Utils.toNullIfFalsy(v.subType),
      shutdown: v.shutdown, // do not use toNullIfFalsy as it would convert 'false' to 'null'. But 'false' is a valid value
      equipmentNumber: Utils.toNullIfFalsy(v.equipmentNumber),
      building: Utils.toNullIfFalsy(v.building),
      floor: Utils.toNullIfFalsy(v.floor),
      installationSite: Utils.toNullIfFalsy(v.installationSite),
    };
  }

  convertToSearchParameterHygieneAggregate(v: SearchBarFormInspectionAggregateHygiene): SearchParameterInspectionAggregateHygiene {
    return {
      inspectionTypes: Utils.toNullIfFalsy(v.inspectionType),
      inspector: Utils.toNullIfFalsy(v.inspector),
      shutdown: v.shutdown, // do not use toNullIfFalsy as it would convert 'false' to 'null'. But 'false' is a valid value
      isMostRecent: Utils.toNullIfFalsy(v.isMostRecent), // 'false' must be converted to 'null', since we want to list all aggregates!
      inspectionDateFrom: Utils.formatOrNull(v.inspectionDateFrom),
      inspectionDateTo: Utils.formatOrNull(v.inspectionDateTo),
      nextInspectionDateFrom: Utils.formatOrNull(v.nextInspectionDateFrom),
      nextInspectionDateTo: Utils.formatOrNull(v.nextInspectionDateTo),
    };
  }

  ngOnInit() {
    document.addPreventDefaultForMouseMiddleEventListener('.inspection-table');

    const sortChange$ = this.sort.sortChange.pipe(startWith(this.initialSort));

    const pageChange$ = this.paginator.page.pipe(startWith(this.initialPage));

    const searchChange$ = this.searchBar.changed.pipe(
      tap(() => this.paginator.firstPage()), // always set pagination to page 1 after filter has been changed
      map((values) => ({
        // the name must match to the expected parameter in the backend
        inspectionParameter: Utils.toNullIfValuesNull(this.convertToSearchParameterInspection(values.inspectionData)),
        aggregateParameter: Utils.toNullIfValuesNull(this.convertToSearchParameterAggregate(values.aggregateData)),
        hygieneAggregateParameter: Utils.toNullIfValuesNull(this.convertToSearchParameterHygieneAggregate(values.hygieneAggregateData)),
      }))
    );

    const apiResult$ = combineLatest([sortChange$, pageChange$, searchChange$]).pipe(
      distinctUntilChanged(),
      switchMap(([sort, page, search]) => {
        return this.inspectionService
          .getRecurrentInspections({
            page: page.pageIndex,
            size: page.pageSize,
            // map remainingdays to nextinspectionduedate since it is the same
            sort: `${sort.active === 'remainingDays' ? 'nextInspectionDueDate' : sort.active},${sort.direction}`,
            ...search,
          })
          .pipe(
            catchError(() => {
              this.notificationService.errorMessage('Ungültige Anfrage');
              return EMPTY;
            })
          );
      }),
      shareReplay(1)
    );

    this.totalItems$ = apiResult$.pipe(map((apiPage) => apiPage.totalElements));

    this.inspections$ = apiResult$.pipe(map((apiPage) => apiPage.content));
  }

  openInspectionNewTab(event: MouseEvent, inspectionId: string) {
    event.stopPropagation();
    if (inspectionId && inspectionId !== '') {
      window.open(window.location.href + '/' + inspectionId);
    }
  }
}
