import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {
  AggregateIssue,
  ApiPage,
  ApiPageParams,
  SearchableAggregateResponse,
  toStringParams,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class AggregateService {
  constructor(private http: HttpClient) {}

  getAggregates(params: Partial<ApiPageParams> = { page: 0, size: 10 }) {
    return this.http.get(
      `${environment.API_BASE}/cognitive-search/aggregates`,
      {
        params: toStringParams(params),
      }
    ) as Observable<ApiPage<SearchableAggregateResponse>>;
  }

  getIssues(id: string) {
    return this.http.get(
      `${environment.API_BASE}/aggregates/${id}/issues`
    ) as Observable<AggregateIssue[]>;
  }
}
