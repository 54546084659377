import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AtwEmployeeResponse } from '../../shared/models';
@Injectable({
  providedIn: 'root',
})
export class InspectorService {
  constructor(private http: HttpClient) {}

  getInspectors() {
    return this.http.get(`${environment.API_BASE}/inspectors/`) as Observable<
      AtwEmployeeResponse[]
    >;
  }
}
