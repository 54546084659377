import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

interface BarcodeGenerationRequest {
  prefix: string;
  sheetCount: number;
}

@Injectable({
  providedIn: 'root',
})
export class BarcodeService {
  constructor(private http: HttpClient) {}

  printSheets(request: BarcodeGenerationRequest) {
    return this.http.post(
      `${environment.API_BASE}/barcode-generator/sheet-print-job`,
      request
    );
  }

  downloadSheets(request: BarcodeGenerationRequest) {
    return this.http.post(
      `${environment.API_BASE}/barcode-generator/sheet-download`,
      request,
      {
        responseType: 'blob',
      }
    );
  }
}
