import { cmyk2hex } from '../utils';

export enum BarcodeType {
    DRINKING_WATER = 'DRINKING_WATER',
    INLET_WATER = 'INLET_WATER',
    AIR_MALT = 'AIR_MALT',
    WIPE_MALT = 'WIPE_MALT',
    AIR_DG18 = 'AIR_DG18',
    POOL_WATER = 'POOL_WATER',
    AIR_CASO = 'AIR_CASO',
    WIPE_DG18 = 'WIPE_DG18',
    WIPE_CASO = 'WIPE_CASO',
    SMEAR = 'SMEAR'
}

interface Barcode {
    type: BarcodeType;
    prefix: string;
    title: string;
    color: string;
}

export const BARCODE_RANGES: Barcode[] = [
    {
        type: BarcodeType.DRINKING_WATER,
        prefix: '01',
        title: 'Trinkwasser',
        color: cmyk2hex(35, 17, 0, 1)
    },
    {
        type: BarcodeType.INLET_WATER,
        prefix: '02',
        title: 'Zulaufwasser',
        color: cmyk2hex(0, 53, 53, 0)
    },
    {
        type: BarcodeType.AIR_MALT,
        prefix: '03',
        title: 'Luftkeim Malz',
        color: cmyk2hex(0, 0, 0, 24)
    },
    {
        type: BarcodeType.WIPE_MALT,
        prefix: '04',
        title: 'Abklatsch Malz',
        color: cmyk2hex(0, 22, 0, 0)
    },
    {
        type: BarcodeType.AIR_DG18,
        prefix: '05',
        title: 'Luftkeim DG18',
        color: cmyk2hex(20, 39, 0, 0)
    },
    {
        type: BarcodeType.POOL_WATER,
        prefix: '06',
        title: 'Beckenwasser',
        color: cmyk2hex(56, 0, 56, 0)
    },
    {
        type: BarcodeType.AIR_CASO,
        prefix: '07',
        title: 'Luftkeim CASO',
        color: cmyk2hex(0, 0, 25, 0)
    },
    {
        type: BarcodeType.WIPE_DG18,
        prefix: '08',
        title: 'Abklatsch DG18',
        color: cmyk2hex(0, 35, 51, 0)
    },
    {
        type: BarcodeType.WIPE_CASO,
        prefix: '09',
        title: 'Abklatsch CASO',
        color: cmyk2hex(17, 0, 16, 0)
    },
    {
        type: BarcodeType.SMEAR,
        prefix: '10',
        title: 'Abstrich',
        color: cmyk2hex(0, 0, 0, 0)
        },
];
