<a mat-raised-button color="primary" [matMenuTriggerFor]="menu" [hidden]="!userAllowedToUploadPlanningExcel">
  <mat-icon *ngIf="!loading">cloud_upload</mat-icon>
  <mat-progress-spinner diameter="24" *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
  Planungs-Excel hochladen
</a>

<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let version of versions" [ngClass]="'PlanningExcelVersion__' + version" (click)="promptExcel(version)">
    <span>{{ 'planningExcel.version.' + version + '.text' | enum }}</span>
  </button>
</mat-menu>

<input type="file" #file style="display: none" accept=".xlsx,.xlsm" (change)="handleFileSelected()" />
