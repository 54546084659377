import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InspectorAssignmentDialogComponent } from '../inspector-assignment-dialog/inspector-assignment-dialog.component';
import { switchMap, map, catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { HttpErrorResponse } from '@angular/common/http';
import { AtwEmployeeResponse, InspectionStatus } from '../../../shared/models';
import { InspectionService } from '../../services';
import { InspectorService } from '../../services/inspector.service';
import { NotificationService } from '../../../services/notification.service';
import { KeycloakRole } from '../../../models';

@Component({
  selector: 'backoffice-inspector-assignment',
  templateUrl: './inspector-assignment.component.html',
  styleUrls: ['./inspector-assignment.component.scss'],
})
export class InspectorAssignmentComponent implements OnInit {
  @Input() inspection!: {
    id: string;
    inspectors: AtwEmployeeResponse[];
    status: InspectionStatus;
  };
  @Output() changed = new EventEmitter<AtwEmployeeResponse[]>();

  assignedInspectors: AtwEmployeeResponse[] = [];
  get content() {
    return (
      this.assignedInspectors
        .map((inspector) => inspector.name)
        .sort()
        .join(', ') || (this.editable ? 'zuweisen' : 'keine zugewiesen')
    );
  }

  editable = false;

  constructor(
    private dialog: MatDialog,
    private inspectionService: InspectionService,
    private inspectorService: InspectorService,
    private readonly notificationService: NotificationService,
    private readonly keycloakService: KeycloakService
  ) {}

  ngOnInit() {
    this.assignedInspectors = [...this.inspection.inspectors];
    this.editable =
      this.inspection.status !== InspectionStatus.SHIPPED_TO_CUSTOMER && this.keycloakService.isUserInRole(KeycloakRole.BACKOFFICE_PLANNER);
  }

  openDialog() {
    this.inspectorService
      .getInspectors()
      .pipe(
        // open dialog that returns list of selected inspectors
        switchMap((inspectors) => {
          return this.dialog
            .open(InspectorAssignmentDialogComponent, {
              data: {
                selectedInspectors: this.assignedInspectors,
                inspectors,
              },
            })
            .afterClosed();
        }),
        // when list given, save inspectors
        switchMap((selectedInspectors) => {
          if (Array.isArray(selectedInspectors)) {
            return this.inspectionService
              .updateInspectors(
                this.inspection.id,
                selectedInspectors.map((inspector) => inspector.id)
              )
              .pipe(
                map(() => selectedInspectors),
                catchError((e) => {
                  const httpError = e as HttpErrorResponse;
                  const forbidden = 403; // HttpStatusCode.Forbidden
                  if (httpError.status === forbidden) {
                    // User is not allowed to change the inspectors
                    this.notificationService.errorMessage('Ihnen fehlt die Berechtigung die Inpekteure zu ändern!');
                  } else {
                    this.notificationService.errorMessage('Fehler beim Ändern der Inspekteure! Fehlercode ' + httpError.status);
                  }
                  return EMPTY;
                })
              );
          }
          // dialog has been canceled
          return EMPTY;
        })
      )
      .subscribe((selectedInspectors) => {
        // update in list
        this.assignedInspectors = [...selectedInspectors];
        this.changed.emit([...this.assignedInspectors]);
      });
  }
}
