import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private readonly http: HttpClient) {}

  getReport(pdfId: string) {
    return this.http.get(`${environment.API_BASE}/reports/${pdfId}`, {
      responseType: 'blob',
    });
  }
}
