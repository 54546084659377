<mat-horizontal-stepper #stepper linear="false" class="InspectionHygieneProgress" [disableRipple]="true" [selectedIndex]="selectedIndex">

    <ng-template *ngFor="let step of steps" matStepperIcon="{{step.status}}">
        <mat-icon class="material-symbols-outlined mat-step-inner-icon" >{{ 'inspections.statusHygiene.' + step.status + '.icon' | enum }}</mat-icon>
    </ng-template>

    <ng-template matStepperIcon="done">
        <mat-icon class="material-symbols-outlined mat-step-inner-icon">done</mat-icon>
    </ng-template>

    <mat-step *ngFor="let step of steps" [completed]="step.completed" editable="false" [optional]="step.optional" state="{{step.status}}">
        <ng-template matStepLabel>
            {{ 'inspections.statusHygiene.' + step.status + '.text' | enum }}
        </ng-template>
    </mat-step>
</mat-horizontal-stepper>