import { HygieneInspectionType, AggregateStatus, AggregateSubType, AggregateType, EnergeticInspectionType } from '../../shared/models';
import moment from 'moment';

/**
 * Interface to bundle data for the aggregate search index
 * The data is filled by the user when he applies filters in the corresponding fields
 */
export interface SearchBarFormAggregate {
  aggregateData: SearchBarFormAggregateGeneral;
  inspectionData: SearchBarFormAggregateInspection;
  hygieneAggregateData: SearchBarFormAggregateHygiene;
  energeticAggregateData: SearchBarFormAggregateEnergetic;
}

/**
 * Interface to bundle general aggregate data in a FormGroup
 */
export interface SearchBarFormAggregateGeneral {
  name?: string | null;
  atwId?: string | null;
  reportPdfId?: string | null;
  type?: AggregateType[] | null;
  subType?: AggregateSubType[] | null;
  shutdown?: boolean | null;
  equipmentNumber?: string | null;
  building?: string | null;
  floor?: string | null;
  installationSite?: string | null;
}

/**
 *
 * @returns an empty (all null) object of [SearchBarFormAggregateGeneral]
 */
export function emptySearchBarFormAggregateGeneral(): SearchBarFormAggregateGeneral {
  return {
    name: null,
    atwId: null,
    reportPdfId: null,
    type: null,
    subType: null,
    shutdown: null,
    equipmentNumber: null,
    building: null,
    floor: null,
    installationSite: null,
  };
}

/**
 * Interface to bundle inspection data of aggregate in a FormGroup
 */
export interface SearchBarFormAggregateInspection {
  inspectionNumber?: string | null;
  customer?: string | null;
  location?: string | null;
  operator?: string | null;
}

/**
 *
 * @returns an empty (all null) object of [SearchBarFormAggregateInspection]
 */
export function emptySearchBarFormAggregateInspection(): SearchBarFormAggregateInspection {
  return {
    inspectionNumber: null,
    customer: null,
    location: null,
    operator: null,
  };
}

/**
 * Interface to bundle hygiene specific aggregate data in a FormGroup
 */
export interface SearchBarFormAggregateHygiene {
  inspectionTypes: HygieneInspectionType[] | null;
  inspectionStatuses: AggregateStatus[] | null;
  inspector?: string | null;
  shutdown?: boolean | null;
  isMostRecent?: boolean | null;
  inspectionDateFrom?: moment.Moment | null;
  inspectionDateTo?: moment.Moment | null;
  nextInspectionDateFrom?: moment.Moment | null;
  nextInspectionDateTo?: moment.Moment | null;
}

/**
 *
 * @returns an empty (all null) object of [SearchBarFormAggregateHygiene]
 */
export function emptySearchBarFormAggregateHygiene(): SearchBarFormAggregateHygiene {
  return {
    inspectionTypes: null,
    inspectionStatuses: null,
    inspector: null,
    shutdown: null,
    isMostRecent: null,
    inspectionDateFrom: null,
    inspectionDateTo: null,
    nextInspectionDateFrom: null,
    nextInspectionDateTo: null,
  };
}

/**
 * Interface to bundle hygiene specific aggregate data in a FormGroup
 */
export interface SearchBarFormAggregateEnergetic {
  inspectionTypes: EnergeticInspectionType[] | null;
  inspectionStatuses: AggregateStatus[] | null;
  inspector?: string | null;
  shutdown?: boolean | null;
  isMostRecent?: boolean | null;
  inspectionDateFrom?: moment.Moment | null;
  inspectionDateTo?: moment.Moment | null;
  nextInspectionDateFrom?: moment.Moment | null;
  nextInspectionDateTo?: moment.Moment | null;
}

/**
 *
 * @returns an empty (all null) object of [SearchBarFormAggregateEnergetic]
 */
export function emptySearchBarFormAggregateEnergetic(): SearchBarFormAggregateEnergetic {
  return {
    inspectionTypes: null,
    inspectionStatuses: null,
    inspector: null,
    shutdown: null,
    isMostRecent: null,
    inspectionDateFrom: null,
    inspectionDateTo: null,
    nextInspectionDateFrom: null,
    nextInspectionDateTo: null,
  };
}
