import { HttpHeaders } from '@angular/common/http';

export default class HttpHeadersUtils {
  /**
   * Tries to get the filename from the content-disposition header.
   * Return undefined, if the header does not exist or the filename could not be parsed from the content
   */
  static getFilenameFromContentDisposition(headers: HttpHeaders): string | undefined {
    const contentDisposition = headers.get('Content-Disposition');
    let filename: string | undefined;
    if (contentDisposition != null) {
      filename = HttpHeadersUtils.getFilenameOrUndefined(contentDisposition);
    }
    return filename;
  }

  private static getFilenameOrUndefined(disposition: string): string | undefined {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.$#]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    let filename: string | undefined;
    if (utf8FilenameRegex.test(disposition)) {
      const result = utf8FilenameRegex.exec(disposition);
      if (result != null) {
        filename = decodeURIComponent(result[1]);
      }
    } else {
      const filenameStart = disposition.toLowerCase().indexOf('filename=');
      if (filenameStart >= 0) {
        const partialDisposition = disposition.slice(filenameStart);
        const matches = asciiFilenameRegex.exec(partialDisposition);
        if (matches != null && matches[2]) {
          filename = matches[2];
        }
      }
    }
    return filename;
  }
}
