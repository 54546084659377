import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unset',
})
export class UnsetPipe implements PipeTransform {
  transform<T>(value: T): string | T {
    return value === null ? 'nicht gesetzt' : value;
  }
}
