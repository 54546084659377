import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { PlanningExcelService } from '../../../shared/services';
import { NotificationService } from '../../../services/notification.service';
import { PlanningExcelVersion } from '../../models';
import { KeycloakRole } from '../../../models';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'backoffice-planning-excel-upload-button',
  templateUrl: './planning-excel-upload-button.component.html',
  styleUrls: ['./planning-excel-upload-button.component.scss'],
})
export class PlanningExcelUploadButtonComponent implements OnInit {
  @ViewChild('file', { static: true }) file!: ElementRef<HTMLInputElement>;
  loading = false;
  versions = Object.values(PlanningExcelVersion);
  currentVersion: PlanningExcelVersion | null = null;

  userAllowedToUploadPlanningExcel = false;

  constructor(
    private readonly planningExcelService: PlanningExcelService,
    private readonly notificationService: NotificationService,
    private readonly keycloak: KeycloakService
  ) {}

  ngOnInit() {
    // only planner are allowed to upload a planning excel
    this.userAllowedToUploadPlanningExcel = this.keycloak.isUserInRole(KeycloakRole.BACKOFFICE_PLANNER);
  }

  promptExcel(version: PlanningExcelVersion) {
    this.file.nativeElement.click();
    this.currentVersion = version;
  }

  handleFileSelected() {
    if (this.file.nativeElement?.files?.length === 1) {
      this.loading = true;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const excel = this.file.nativeElement.files.item(0)!;
      this.planningExcelService
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .uploadPlanningExcel(excel, this.currentVersion!)
        .pipe(
          finalize(() => {
            this.file.nativeElement.value = '';
            this.loading = false;
            this.currentVersion = null;
          })
        )
        .subscribe({
          next: (_) => EMPTY,
          complete: () => {
            this.notificationService.successMessage('Planungs-Excel erfolgreich hochgeladen.');
          },
          error: (error) => {
            if (error.error.status === 400) {
              // Display specific excel error message.
              this.notificationService.openNotificationDialog({
                title: 'Fehler',
                content: 'Das Planungs-Excel konnte leider nicht korrekt verarbeitet werden.',
                error: error.error.message,
              });
            } else {
              // Not sure what is wrong, show generic error message.
              this.notificationService.errorMessage('Es ist ein Fehler aufgetreten, bitte ein gültiges Excel hochladen.');
            }
          },
        });
    }
  }
}
