<div class="mat-elevation-z2">
  <table
    mat-table
    [dataSource]="inspections$"
    matSort
    [matSortActive]="initialSort.active"
    matSortDisableClear
    [matSortDirection]="initialSort.direction"
  >
    <ng-container matColumnDef="inspectionNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Insp.-Nr.</th>
      <td mat-cell *matCellDef="let element">{{ element.inspectionNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="customer.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Auftraggeber</th>
      <td mat-cell *matCellDef="let element">{{ element.customer.name }}</td>
    </ng-container>

    <!-- Anlagennutzer -->

    <ng-container matColumnDef="location.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Standort</th>
      <td mat-cell *matCellDef="let element">{{ element.location.name }}</td>
    </ng-container>

    <ng-container matColumnDef="location.zip">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>PLZ</th>
      <td mat-cell *matCellDef="let element">{{ element.location.zip }}</td>
    </ng-container>

    <ng-container matColumnDef="location.city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Ort</th>
      <td mat-cell *matCellDef="let element">{{ element.location.city }}</td>
    </ng-container>

    <ng-container matColumnDef="location.street">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Straße</th>
      <td mat-cell *matCellDef="let element">{{ element.location.street }}</td>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Start-<br />Datum</th>
      <td mat-cell *matCellDef="let element">
        <backoffice-start-date-assignment (changed)="reloadList()" [inspection]="element" />
      </td>
    </ng-container>

    <ng-container matColumnDef="inspectors">
      <th mat-header-cell *matHeaderCellDef>Inspekteure</th>
      <td mat-cell *matCellDef="let element">
        <backoffice-inspector-assignment (changed)="reloadList()" [inspection]="element" />
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="statusHeader" disableClear matTooltip="Gesamtstatus der Inspektion">
        Status
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="statusWithActionButtonWrapper">
          <backoffice-inspection-status [inspection]="element" />
          <backoffice-inspection-actions (changed)="reloadList()" [inspection]="element" />
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="empty">
      <td mat-footer-cell *matFooterCellDef [colSpan]="displayedColumns.length">keine Ergebnisse</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="element-row"></tr>
    <tr mat-footer-row *matFooterRowDef="['empty']" [class.hidden]="(totalItems$ | async) ?? 0 > 0"></tr>
  </table>

  <mat-paginator [length]="totalItems$ | async" [pageSize]="initialPage.pageSize" [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
</div>
