<button
  (click)="handleMenuClick($event)"
  *ngIf="menuEnabled"
  matTooltip="weitere Aktionen"
  mat-icon-button
  [matMenuTriggerFor]="menu"
  aria-label="Aktionsmenü"
>
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    *ngFor="let item of statusChangeMenuItems"
    (click)="setInspectionStatus(item)"
  >
    <mat-icon>{{ 'inspections.status.' + item + '.icon' | enum }}</mat-icon>
    <span>Status "{{ 'inspections.status.' + item + '.text' | enum }}" setzen</span>
  </button>
  <button mat-menu-item *ngIf="canDelete" (click)="deleteInspection()">
    <mat-icon color="warn">delete</mat-icon>
    <span>Inspektion löschen</span>
  </button>
</mat-menu>
