import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import localeDe from '@angular/common/locales/de';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserButtonComponent } from './components/user-button/user-button.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SharedModule } from './shared/shared.module';
import { BarcodesModule } from './barcodes/barcodes.module';
import { SnackbarMessageComponent } from './components/snackbar-message/snackbar-message.component';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';
import { HttpClientModule } from '@angular/common/http';
import { AggregatesModule } from './aggregates/aggregates.module';
import { InspectionsModule } from './inspections/inspections.module';
import { ErrorAccessDeniedComponent } from './components/error-access-denied/error-access-denied.component';
import { DatePipe, registerLocaleData } from '@angular/common';

registerLocaleData(localeDe, 'de');

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: environment.auth.config,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
      enableBearerInterceptor: true,
    });
}

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    ErrorAccessDeniedComponent,
    UserButtonComponent,

    NotificationDialogComponent,
    SnackbarMessageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    BarcodesModule,
    AggregatesModule,
    InspectionsModule,
    SharedModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: LOCALE_ID, useValue: 'de',
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
