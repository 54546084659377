<backoffice-search-bar [showStatus]="true" [showEnInFilters]="true" />

<p class="buttons">
  <backoffice-generate-multiple-reports-button
    [isGeneratingMultipleReports]="isGeneratingMultipleReports"
    [hidden]="!userAllowedToGenerateMultipleReports"
    [disabled]="!selection.hasValue() || isGeneratingMultipleReports"
    (click)="generateMultipleReports()"
  />
  <backoffice-create-merge-report-button
    [isGeneratingMergeReport]="isGeneratingMergeReport"
    [disabled]="!selection.hasValue() || isGeneratingMergeReport"
    (click)="generateMergeReport()"
  />
</p>

<div class="mat-elevation-z2">
  <table
    mat-table
    class="inspection-table"
    [dataSource]="inspections$"
    matSort
    [matSortActive]="initialSort.active"
    matSortDisableClear
    [matSortDirection]="initialSort.direction"
  >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && areAllSelected()"
          [indeterminate]="selection.hasValue() && !areAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [disabled]="!isRowEnabled(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="inspectionNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Insp.-Nr.</th>
      <td mat-cell *matCellDef="let element">{{ element.inspectionNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="customer.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Auftraggeber</th>
      <td mat-cell *matCellDef="let element">{{ element.customer.name }}</td>
    </ng-container>

    <!-- Anlagennutzer -->

    <ng-container matColumnDef="location.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Standort</th>
      <td mat-cell *matCellDef="let element">{{ element.location.name }}</td>
    </ng-container>

    <ng-container matColumnDef="location.zip">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>PLZ</th>
      <td mat-cell *matCellDef="let element">{{ element.location.zip }}</td>
    </ng-container>

    <ng-container matColumnDef="location.city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Ort</th>
      <td mat-cell *matCellDef="let element">{{ element.location.city }}</td>
    </ng-container>

    <ng-container matColumnDef="location.street">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Straße</th>
      <td mat-cell *matCellDef="let element">{{ element.location.street }}</td>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Datum</th>
      <td mat-cell *matCellDef="let element">{{ element.startDate | date | unset }}</td>
    </ng-container>

    <ng-container matColumnDef="reportTimestamp">
      <th mat-header-cell *matHeaderCellDef>Berichtsgenerierung</th>
      <td mat-cell *matCellDef="let element">{{ element.reportTimestamp | transformReportTimestamp }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="statusHeader" disableClear matTooltip="Gesamtstatus der Inspektion">
        Status
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="statusWithActionButtonWrapper">
          <backoffice-inspection-status [inspection]="element" />
          <backoffice-inspection-actions (changed)="reloadList()" [inspection]="element" />
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="hygieneData.status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="statusHeader" disableClear matTooltip="Status Hygieneinspektion">
        Hyg-Status
      </th>
      <td mat-cell *matCellDef="let inspection">
        <div class="statusWithActionButtonWrapper">
          <backoffice-inspection-status-hygiene [inspection]="inspection" />
          <backoffice-inspection-actions-hygiene
            (changed)="reloadList()"
            [inspection]="{
              id: inspection.id,
              inspectionNumber: inspection.inspectionNumber,
              hygieneStatus: inspection.hygieneData.status
            }"
          />
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="energeticData.status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="statusHeader" disableClear matTooltip="Status Energetische Inspektion">
        EnIn-Status
      </th>
      <td mat-cell *matCellDef="let inspection">
        <div class="statusWithActionButtonWrapper">
          <backoffice-inspection-status-energetic [inspection]="inspection" />
          <backoffice-inspection-actions-energetic
            (changed)="reloadList()"
            class="mySpan"
            [inspection]="{
              id: inspection.id,
              inspectionNumber: inspection.inspectionNumber,
              energeticStatus: inspection.energeticData?.status
            }"
          />
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions-newTab">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button matTooltip="In neuen Tab öffnen" (click)="openInspectionNewTab($event, element.id)">
          <mat-icon>open_in_new</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="empty">
      <td mat-footer-cell *matFooterCellDef [colSpan]="displayedColumns.length">keine Ergebnisse</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="element-row"
      [routerLink]="[row.id]"
      (auxclick)="$event.button === 1 ? openInspectionNewTab($event, row.id) : null"
    ></tr>
    <tr mat-footer-row *matFooterRowDef="['empty']" [class.hidden]="(totalItems$ | async) ?? 0 > 0"></tr>
  </table>

  <mat-paginator [length]="totalItems$ | async" [pageSize]="initialPage.pageSize" [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
</div>
