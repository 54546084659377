import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AggregateResponseSummary } from '../../../../shared/models';

@Component({
  selector: 'backoffice-aggregate-table',
  templateUrl: './aggregate-table.component.html',
  styleUrls: ['./aggregate-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AggregateTableComponent implements OnInit, OnChanges {
  @Input() aggregates!: AggregateResponseSummary[];
  @Input() loadingReports = false;
  @Output() downloadReport = new EventEmitter<string>();

  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  readonly initialSort = {
    active: 'endDate',
    direction: 'desc'
  } as Sort;

  readonly displayedColumns: string[] = [
    'internalNote', 'name', 'atwId', 'typeDisplayName', 'equipmentNumber', 'building',
    'floor', 'installationSite', 'hygieneAggregate.nextInspectionDate', 'issues', 'hygieneAggregate.status', 'actions'
  ];

  dataSource: MatTableDataSource<AggregateResponseSummary> = new MatTableDataSource();
  expandedElement!: AggregateResponseSummary | null;

  ngOnInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges({ aggregates }: { aggregates: SimpleChange; }) {
    if (aggregates) {
      this.dataSource.data = aggregates.currentValue;
    }
  }

  handleDownloadReport(pdfId: string, event: Event) {
    event.stopPropagation();
    this.downloadReport.emit(pdfId);
  }
}
