import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  CognitiveSearchIndexes,
  SuggestionsService,
} from '../../services/suggestion.service';
import { of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-numberfield-filter',
  templateUrl: './numberfield-filter.component.html',
  styleUrls: ['./numberfield-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberfieldFilterComponent),
      multi: true,
    },
  ],
})
export class NumberfieldFilterComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() label!: string;
  @Input() cognitiveSearchIndex!: CognitiveSearchIndexes;
  @Input() suggestionField!: string;

  _filterNumber: string | null = null;
  _destroy$ = new Subject<void>();

  numberfieldFilterControl!: FormControl;

  filteredOptions: string[] = [];
  // long variable name prevent istanbul from breaking snapshots https://github.com/thymikee/jest-preset-angular/issues/85
  displayFunction = (selectedOption: string) => {
    return !selectedOption ? '' : selectedOption;
  };

  get filterNumber() {
    return this._filterNumber;
  }
  set filterNumber(val) {
    this._filterNumber = val;
    this.propagateChange(this._filterNumber);
  }

  constructor(private readonly suggestionService: SuggestionsService) {}

  ngOnInit() {
    this.numberfieldFilterControl = new FormControl('');

    this.numberfieldFilterControl.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(300),
        switchMap((searchNumber?: string) => {
          if (searchNumber && searchNumber.length > 0) {
            this.filterNumber = searchNumber;
            if (
              this.suggestionField &&
              (this.cognitiveSearchIndex ===
                CognitiveSearchIndexes.Aggregates ||
                this.cognitiveSearchIndex ===
                  CognitiveSearchIndexes.Inspections)
            ) {
              return this.suggestionService.searchSuggestions(
                this.cognitiveSearchIndex,
                this.suggestionField,
                searchNumber
              );
            }
          } else {
            this.filterNumber = null;
          }
          return of([]); // use no suggester
        })
      )
      .subscribe((options) => {
        this.filteredOptions = options;
      });
  }

  writeValue(value: string) {
    this.filterNumber = value;
    if (this.numberfieldFilterControl) {
      this.numberfieldFilterControl.setValue(this.filterNumber);
    }
  }
  registerOnChange(fn: (_: unknown) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
    /* empty on purpose */
  }

  propagateChange = (_: unknown) => {
    /* empty on purpose */
  };

  clear() {
    this.filterNumber = null;
    this.numberfieldFilterControl.setValue('');
  }

  checkIfCharacterIsNumber(event: { charCode: number }) {
    return event.charCode > 47 && event.charCode < 58;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
