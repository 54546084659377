import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit, Input } from '@angular/core';
import { InspectionStatus } from '../../../shared/models';

interface InspectionProgressStep {
  status: InspectionStatus;
  completed: boolean;
  optional: boolean;
}

@Component({
  selector: 'backoffice-inspection-progress',
  templateUrl: './inspection-progress.component.html',
  styleUrls: ['./inspection-progress.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class InspectionProgressComponent implements OnInit {
  @Input() status!: InspectionStatus;
  steps: InspectionProgressStep[] = [];
  selectedIndex = 0;

  ngOnInit() {
    let matched = false;
    Object.keys(InspectionStatus).forEach((value, index) => {
      if (value === this.status) {
        matched = true;
        this.selectedIndex = index;
      }
      this.steps.push({
        completed:
          !matched ||
          (this.status === InspectionStatus.SHIPPED_TO_CUSTOMER && matched),
        status: value as InspectionStatus,
        optional: false, // no optional status available atm.
      });
    });
  }
}
