import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AtwEmployeeResponse } from '../../../shared/models';

interface InspectorAssignmentDialogData {
  selectedInspectors: AtwEmployeeResponse[];
  inspectors: AtwEmployeeResponse[];
}

@Component({
  selector: 'backoffice-inspector-assignment-dialog',
  templateUrl: './inspector-assignment-dialog.component.html',
  styleUrls: ['./inspector-assignment-dialog.component.scss'],
})
export class InspectorAssignmentDialogComponent implements OnInit {
  @ViewChild('inspectorInput', { static: false })
  inspectorInput!: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete!: MatAutocomplete;

  inspectorControl = new FormControl();

  filteredInspectors!: Observable<AtwEmployeeResponse[]>;
  selectedInspectors!: AtwEmployeeResponse[];

  constructor(
    public readonly dialogRef: MatDialogRef<InspectorAssignmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InspectorAssignmentDialogData
  ) {}

  ngOnInit() {
    this.selectedInspectors = [...this.data.selectedInspectors];
    this.filteredInspectors = this.inspectorControl.valueChanges.pipe(
      // tslint:disable-next-line:deprecation
      startWith(null),
      map((value: string | AtwEmployeeResponse | null) => {
        return value === null
          ? [...this.data.inspectors]
          : typeof value === 'string'
          ? this._filter(value)
          : this._filter(value.name);
      })
    );
  }

  remove(inspector: AtwEmployeeResponse): void {
    const matched = this.selectedInspectors.find((i) => i.id === inspector.id);

    if (matched) {
      this.selectedInspectors.splice(
        this.selectedInspectors.indexOf(matched),
        1
      );
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const inspector = event.option.value as AtwEmployeeResponse;
    if (!this.selectedInspectors.find((i) => i.id === inspector.id)) {
      this.selectedInspectors.push(event.option.value);
    }
    this.inspectorInput.nativeElement.value = '';
    this.inspectorControl.setValue(null);
  }

  onSubmit() {
    if (!this.matAutocomplete.isOpen) {
      this.dialogRef.close(this.selectedInspectors);
    }
  }

  private _filter(value: string): AtwEmployeeResponse[] {
    const filterValue = value.toLowerCase();

    return this.data.inspectors.filter(
      (inspector) => inspector.name.toLowerCase().indexOf(filterValue) !== -1
    );
  }
}
