<span
  class="AggregateValidation__Badge AggregateValidation__Badge--Required"
  [matTooltip]="'aggregates.issues.severity.' + IssueSeverity.REQUIRED + '.tooltip' | enum"
  [class.AggregateValidation__Badge--Hidden]="aggregate.requiredIssueCount === 0"
  >{{ aggregate.requiredIssueCount }}</span
>
<span
  class="AggregateValidation__Badge AggregateValidation__Badge--Recommended"
  [matTooltip]="'aggregates.issues.severity.' + IssueSeverity.RECOMMENDED + '.tooltip'"
  [class.AggregateValidation__Badge--Hidden]="
    aggregate.recommendedIssueCount === 0
  "
  >{{ aggregate.recommendedIssueCount }}</span
>
<span
  class="AggregateValidation__Badge AggregateValidation__Badge--Optional"
  [matTooltip]="'aggregates.issues.severity.' + IssueSeverity.OPTIONAL + '.tooltip'"
  [class.AggregateValidation__Badge--Hidden]="aggregate.optionalIssueCount === 0"
  >{{ aggregate.optionalIssueCount }}</span
>
