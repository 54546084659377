import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'backoffice-user-button',
  templateUrl: './user-button.component.html',
  styleUrls: ['./user-button.component.scss'],
})
export class UserButtonComponent implements OnInit {
  username!: string;
  // TODO: is version still needed? currently it's hard coded to package.json
  // and is out of sync with git tag.
  version = environment.version;

  get manageAccountUrl() {
    return `${environment.auth.config.url}/realms/${environment.auth.config.realm}/account`;
  }

  constructor(protected keycloakAngular: KeycloakService) {}

  async ngOnInit() {
    await this.keycloakAngular.loadUserProfile();
    this.username = this.keycloakAngular.getUsername();
  }

  logout() {
    this.keycloakAngular.logout(
      `${window.location.protocol}//${window.location.host}`
    );
  }
}
