/**
 * Red = 255 × ( 1 - Cyan ÷ 100 ) × ( 1 - Black ÷ 100 )
 * Green = 255 × ( 1 - Magenta ÷ 100 ) × ( 1 - Black ÷ 100 )
 * Blue = 255 × ( 1 - Yellow ÷ 100 ) × ( 1 - Black ÷ 100 )
 */
export const cmyk2hex = (cyan: number, magenta: number, yellow: number, key: number): string => {
    const red = Math.round(255 * ( 1 - cyan / 100 ) * ( 1 - key / 100 ));
    const green = Math.round(255 * ( 1 - magenta / 100 ) * ( 1 - key / 100 ));
    const blue = Math.round(255 * ( 1 - yellow / 100 ) * ( 1 - key / 100 ));
    return `#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`;
};
