<button mat-button [matMenuTriggerFor]="menu" matTooltip="Benutzer Menü">
  <mat-icon>account_circle</mat-icon>{{ username }}
</button>
<mat-menu #menu="matMenu">
  <a mat-menu-item [attr.href]="manageAccountUrl" target="_blank"
    ><mat-icon>person</mat-icon>Account-Verwaltung</a
  >
  <button mat-menu-item (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>Logout
  </button>
  <!-- <span mat-menu-item disableRipple disabled class="UserButton__Version">{{ version }}</span> -->
</mat-menu>
