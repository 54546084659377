import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ) {
    if (!this.authenticated) {
      this.keycloak.login();
    }

    const requiredRoles = [
      ...environment.auth.roles_required,
      ...(route.data['roles'] ? route.data['roles'] : []),
    ];

    if (!requiredRoles || requiredRoles.length === 0) {
      return true;
    } else {
      if (!this.roles || this.roles.length === 0) {
        return false;
      }
      let granted = false;
      for (const requiredRole of requiredRoles) {
        if (this.roles.indexOf(requiredRole) > -1) {
          granted = true;
          break;
        }
      }
      if (granted === false) {
        this.router.navigate(['/access_denied']);
      }
      return granted;
    }
  }
}
