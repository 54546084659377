import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface MergeReportDialogData {
  title: string;
  text: string;
  labelName: string;
  save: string;
  cancel: string;
}

@Component({
  selector: 'backoffice-merge-report-dialog',
  templateUrl: './merge-report-dialog.component.html',
  styleUrls: ['./merge-report-dialog.component.scss'],
})
export class MergeReportDialogComponent implements OnInit {
  public static descriptionLengthMin = 10;
  public static descriptionLengthMax = 50;

  inputPattern = '^[^<>:"/\\|*?\n\t]+$';
  form!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<MergeReportDialogData>,
    @Inject(MAT_DIALOG_DATA) public readonly data: MergeReportDialogData
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      input: [
        '',
        [
          Validators.required,
          Validators.minLength(MergeReportDialogComponent.descriptionLengthMin),
          Validators.maxLength(MergeReportDialogComponent.descriptionLengthMax),
          Validators.pattern(this.inputPattern),
        ],
      ],
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.form.value);
  }
}
