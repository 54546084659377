import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import isEqual from 'lodash.isequal';
import {
  emptySearchBarFormInspectionAggregateEnergetic,
  emptySearchBarFormInspectionAggregateGeneral,
  emptySearchBarFormInspectionAggregateHygiene,
  emptySearchBarFormInspectionGeneral,
  SearchBarFormInspection,
} from '../models/SearchBarFormInspection';

const initialValue: SearchBarFormInspection = {
  aggregateData: emptySearchBarFormInspectionAggregateGeneral(),
  inspectionData: emptySearchBarFormInspectionGeneral(),
  hygieneAggregateData: emptySearchBarFormInspectionAggregateHygiene(),
  energeticAggregateData: emptySearchBarFormInspectionAggregateEnergetic(),
} as SearchBarFormInspection;

@Injectable({
  providedIn: 'root',
})
export class SearchBarService {
  private _subject = new ReplaySubject<SearchBarFormInspection>(1);
  changes: Observable<SearchBarFormInspection>;
  private _force = false;

  constructor() {
    this.changes = this._subject.asObservable().pipe(distinctUntilChanged((x, y) => !this._force && isEqual(x, y)));
    this.next(initialValue);
  }

  next(value: SearchBarFormInspection) {
    this._subject.next(value);
  }

  forceNext(value: SearchBarFormInspection) {
    this._force = true;
    this._subject.next(value);
    this._force = false;
  }
}
