import { AddressDetail } from './Address';
import { AggregateResponseSummary } from './Aggregate';
import { AtwEmployeeResponse } from './AtwEmployeeResponse';
import { ContactResponseSummary } from './Contact';

/**
 * Defines all different kinds of inspections status.
 * !!!!!
 * IMPORTANT: These types are also defined in backend and app repositories. => keep them in sync!
 * !!!!!
 */
export enum InspectionStatus {
  CREATED = 'CREATED',
  PLANNED = 'PLANNED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED_BY_INSPECTOR = 'FINISHED_BY_INSPECTOR',
  READY_FOR_SHIPMENT = 'READY_FOR_SHIPMENT',
  SHIPPED_TO_CUSTOMER = 'SHIPPED_TO_CUSTOMER',
}

/**
 * Defines all different kinds of hygiene inspections status
 * !!!!!
 * IMPORTANT: These types are also defined in backend and app repositories. => keep them in sync!
 * !!!!!
 */
export enum HygieneInspectionStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_FOR_LAB = 'WAITING_FOR_LAB',
  IN_REVIEW = 'IN_REVIEW',
  COMPLETED = 'COMPLETED',
}

/**
 * Defines all different kinds of energetic inspections for aggregates.
 *
 * !!!!!
 * IMPORTANT: These types are also defined in audit-app and backend repositories. => keep them in sync!
 * !!!!!
 */
export enum EnergeticInspectionStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_FOR_DATA = 'WAITING_FOR_DATA',
  IN_REVIEW = 'IN_REVIEW',
  COMPLETED = 'COMPLETED',
}

export interface HygieneInspectionResponseSummary {
  status: HygieneInspectionStatus;
}

export interface EnergeticInspectionResponseSummary {
  status: EnergeticInspectionStatus;
}

interface InspectionResponseBase {
  id: string;
  inspectionNumber: string;
  startDate: string;
  endDate: string;
  status: InspectionStatus;
  customer: AddressDetail;
  location: AddressDetail;
}

// use type alias instead of new interface as currently no additional data is required
export type InspectionResponseSummary = InspectionResponseBase;

export interface InspectionResponseDetailed extends InspectionResponseBase {
  hasLabRequestBundle: boolean;
  internalNote: string;
  hygieneInspection?: HygieneInspectionResponseSummary;
  energeticInspection?: EnergeticInspectionResponseSummary;
  contacts: ContactResponseSummary[];
  aggregates: AggregateResponseSummary[];
  inspectors: AtwEmployeeResponse[];
}
