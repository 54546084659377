<mat-form-field subscriptSizing="dynamic">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [formControl]="aggregateTypeControl"
    multiple
    panelClass="searchbar-list AggregateTypeComponent__Panel"
  >
    <mat-option *ngFor="let typeItem of aggregateTypes" [value]="typeItem">
      {{ 'aggregates.types.' + typeItem + '.text' | enum }}
    </mat-option>
  </mat-select>
</mat-form-field>
