export { }; // mark this file as an external module, to be able to use declare global

declare global {
    interface Document {
        addPreventDefaultForMouseMiddleEventListener(selector: string): void;
    }
}

function nonePassiveHandler(event: MouseEvent) {
    if (event.button === 1) {
        // prevent the default scrolling for mouse middle events
        event.preventDefault();
    }
}

Document.prototype.addPreventDefaultForMouseMiddleEventListener = (selector: string) => {
    const element = document.querySelector<HTMLElement>(selector);
    const nonePassive = {
        passive: false
    };
    element?.addEventListener('mousedown', nonePassiveHandler, nonePassive);
};
