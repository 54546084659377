<mat-form-field subscriptSizing="dynamic">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [formControl]="aggregateStatusControl"
    multiple
    panelClass="searchbar-list AggregateStatusComponent__Panel"
  >
    <mat-option
      *ngFor="let statusItem of aggregateStatusList"
      [value]="statusItem"
    >
      {{ 'aggregates.status.' + statusItem | enum }}
    </mat-option>
  </mat-select>
</mat-form-field>
