<backoffice-search-bar [showDueDates]="true" [showAggregateNumberFields]="true"/>

<div class="mat-elevation-z2">

  <table mat-table class="inspection-table" [dataSource]="inspections$" multiTemplateDataRows matSort [matSortActive]="initialSort.active"
    matSortDisableClear [matSortDirection]="initialSort.direction">

    <ng-container matColumnDef="inspectionNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Insp.-Nr.</th>
      <td mat-cell *matCellDef="let element"> {{element.inspectionNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="customer.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Auftraggeber</th>
      <td mat-cell *matCellDef="let element"> {{element.customer.name}} </td>
    </ng-container>

    <!-- Anlagennutzer -->

    <ng-container matColumnDef="location.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Standort</th>
      <td mat-cell *matCellDef="let element"> {{element.location.name}} </td>
    </ng-container>

    <ng-container matColumnDef="location.zip">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>PLZ</th>
      <td mat-cell *matCellDef="let element"> {{element.location.zip}} </td>
    </ng-container>

    <ng-container matColumnDef="location.city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Ort</th>
      <td mat-cell *matCellDef="let element"> {{element.location.city}} </td>
    </ng-container>

    <ng-container matColumnDef="location.street">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Straße</th>
      <td mat-cell *matCellDef="let element"> {{element.location.street}} </td>
    </ng-container>

    <ng-container matColumnDef="aggregatesTotal">
      <th mat-header-cell *matHeaderCellDef># Anlagen</th>
      <td mat-cell *matCellDef="let element"> {{ element.aggregatesTotal }} </td>
    </ng-container>

    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Inspektions-<br />Datum</th>
      <td mat-cell *matCellDef="let element"> {{ element.endDate | date }} </td>
    </ng-container>

    <ng-container matColumnDef="nextInspectionDueDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Früheste Wdh.</th>
      <td mat-cell *matCellDef="let element"> {{ element.nextInspectionDueDate | date }} </td>
    </ng-container>

    <ng-container matColumnDef="remainingDays">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>verbl. Tage</th>
      <td mat-cell *matCellDef="let element" [backofficeRemainingDays]="element.nextInspectionDueDate">
        {{ !element.nextInspectionDueDate ? '---' : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button matTooltip="In neuen Tab öffnen" (click)="openInspectionNewTab($event, element.id)">
          <mat-icon>open_in_new</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="empty">
      <td mat-footer-cell *matFooterCellDef [colSpan]="displayedColumns.length">
        keine Ergebnisse
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" [routerLink]="[row.id]"
    (auxclick)="$event.button === 1 ? openInspectionNewTab($event, row.id) : null"></tr>
    <tr mat-footer-row *matFooterRowDef="['empty']" [class.hidden]="(totalItems$ | async) ?? 0 > 0"></tr>
  </table>

  <mat-paginator [length]="totalItems$ | async" [pageSize]="initialPage.pageSize" [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>
</div>
