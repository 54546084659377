import { Component, Input } from '@angular/core';
import { InspectionStatus, SearchableInspectionResponse } from '../../../shared/models';

@Component({
  selector: 'backoffice-inspection-status',
  templateUrl: './inspection-status.component.html',
  styleUrls: ['./inspection-status.component.scss']
})
export class InspectionStatusComponent {
  @Input() inspection!: SearchableInspectionResponse;

  readonly statusList = InspectionStatus;

}
