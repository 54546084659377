import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarMessageComponent } from '../components/snackbar-message/snackbar-message.component';
import { NotificationDialogComponent } from '../components/notification-dialog/notification-dialog.component';

export interface NotificationDialogData {
  title?: string;
  content: string;
  error?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog
  ) {}

  successMessage(message: string) {
    this.snackBar.openFromComponent(SnackbarMessageComponent, {
      duration: 3000,
      data: {
        icon: 'check',
        message,
      },
    });
  }

  errorMessage(message: string) {
    this.snackBar.openFromComponent(SnackbarMessageComponent, {
      duration: 6000,
      data: {
        icon: 'error_outline',
        message,
        error: true,
      },
    });
  }

  openNotificationDialog(data: NotificationDialogData) {
    this.dialog.open(NotificationDialogComponent, {
      position: {
        bottom: '3em',
        right: '3em',
      },
      data,
      hasBackdrop: false,
    });
  }
}
