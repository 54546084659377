import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  emptySearchBarFormAggregateEnergetic,
  emptySearchBarFormAggregateGeneral,
  emptySearchBarFormAggregateHygiene,
  emptySearchBarFormAggregateInspection,
  SearchBarFormAggregate,
} from '../../models/SearchBarFormAggregate';
import { AggregateSearchBarService } from '../../services/aggregate-search-bar.service';
import { CognitiveSearchIndexes } from '../../../shared/services/suggestion.service';
import Utils from '../../../utils/utils';
import { Observable, Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-aggregate-search-bar',
  templateUrl: './aggregate-search-bar.component.html',
  styleUrls: ['./aggregate-search-bar.component.scss'],
})
export class AggregateSearchBarComponent implements OnInit, OnDestroy {
  @Output() changed: Observable<SearchBarFormAggregate>;

  searchForm!: FormGroup;

  expandAggregateFilters = false;
  expandHygieneAggregateFilters = false;
  expandEnergeticAggregateFilters = false;

  isAnyInspectionFilterSet = false;
  isAnyAggregateGeneralFilterSet = false;
  isAnyAggregateHygieneFilterSet = false;
  isAnyAggregateEnergeticFilterSet = false;

  inspectionIndex = CognitiveSearchIndexes.Inspections;
  aggregateIndex = CognitiveSearchIndexes.Aggregates;

  _destroy$ = new Subject<void>();

  constructor(private readonly fb: FormBuilder, private readonly service: AggregateSearchBarService) {
    // assign here so it is available in parents' ngOnInit
    this.changed = this.service.changes;
  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      aggregateData: this.fb.group(emptySearchBarFormAggregateGeneral()),
      inspectionData: this.fb.group(emptySearchBarFormAggregateInspection()),
      hygieneAggregateData: this.fb.group(emptySearchBarFormAggregateHygiene()),
      energeticAggregateData: this.fb.group(emptySearchBarFormAggregateEnergetic()),
    });

    this.changed.pipe(take(1)).subscribe((initialValue) => {
      this.searchForm.patchValue(initialValue);
    });

    this.searchForm.valueChanges.pipe(takeUntil(this._destroy$), debounceTime(300)).subscribe((formValue) => {
      this.service.next(formValue);
    });

    // update the filter states without delay
    this.searchForm.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((formValue) => {
      this.updateFilterStatus(formValue);
    });
  }

  updateFilterStatus(formValue: SearchBarFormAggregate) {
    this.isAnyInspectionFilterSet = Utils.toNullIfValuesNull(formValue.inspectionData) !== null;
    this.isAnyAggregateGeneralFilterSet = Utils.toNullIfValuesNull(formValue.aggregateData) !== null;
    this.isAnyAggregateHygieneFilterSet = Utils.toNullIfValuesNull(formValue.hygieneAggregateData) !== null;
    this.isAnyAggregateEnergeticFilterSet = Utils.toNullIfValuesNull(formValue.energeticAggregateData) !== null;
  }

  toggleAggregateFilters() {
    this.expandAggregateFilters = !this.expandAggregateFilters;
  }

  toggleHygieneAggregateFilters() {
    this.expandHygieneAggregateFilters = !this.expandHygieneAggregateFilters;
  }

  toggleEnergeticAggregateFilters() {
    this.expandEnergeticAggregateFilters = !this.expandEnergeticAggregateFilters;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  reset() {
    this.searchForm.reset({});
  }

  refresh() {
    this.service.forceNext(this.searchForm.value);
  }
}
