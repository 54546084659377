<mat-toolbar color="primary" class="mat-elevation-z4">
  <mat-toolbar-row>
    <div class="Navigation__MainContainer" [ngClass]="environment.environment">
      <a mat-button routerLink="/inspections" routerLinkActive="active">Inspektionen</a>
      <a mat-button routerLink="/aggregates" routerLinkActive="active">Anlagen</a>
      <a mat-button routerLink="/barcodes" routerLinkActive="active" [hidden] = "!userAllowedToPrintBarcodes" >Barcodes</a>
    </div>
    <div>
      <backoffice-user-button/>
    </div>
  </mat-toolbar-row>
  <router-outlet name="subnavigation"></router-outlet>
</mat-toolbar>
