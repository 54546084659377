<button (click)="handleMenuClick($event)" *ngIf="menuEnabled" matTooltip="weitere Aktionen" mat-icon-button
    [matMenuTriggerFor]="menu" aria-label="Aktionsmenü">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let item of statusChangeMenuItems" (click)="setHygieneInspectionStatus(item)">
        <mat-icon class="material-symbols-outlined">{{ 'inspections.statusHygiene.' + item + '.icon' | enum }}</mat-icon>
        <span>Status "{{ 'inspections.statusHygiene.' + item + '.text' | enum }}" setzen</span>
    </button>
    <button mat-menu-item *ngIf="canDelete" (click)="deleteHygieneInspection()">
        <mat-icon color="warn">delete</mat-icon>
        <span>Hygieneinspektion löschen</span>
    </button>
</mat-menu>

