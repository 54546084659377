<h1 class="mat-h1">Inspektion {{ (inspection$ | async)?.inspectionNumber }}</h1>

<div *ngIf="inspection$ | async as inspection; else loadingTemplate">
  <div class="InspectionDetail__Wrapper">
    <mat-card class="InspectionDetail__Overview">
      <div class="blockSpan2">
        <h3>Inspektions-Art</h3>
        <p class="InspectionDetail__InspectionKind" [ngClass]="'InspectionDetail__InspectionKind--VENTILATION_SYSTEMS'">
          <mat-icon class="material-symbols-outlined">{{ 'inspections.kind.icon' | enum }}</mat-icon>
          {{ 'inspections.kind.text' | enum }}
        </p>
      </div>
      <div class="blockSpan2">
        <h3>Inspektions-Datum</h3>
        <p>
          <backoffice-start-date-assignment (changed)="reload()" [inspection]="inspection" />
          - {{ inspection.endDate | date }}
        </p>
      </div>
      <div class="blockSpan2">
        <h3>Inspekteure</h3>
        <backoffice-inspector-assignment [inspection]="inspection" (changed)="reload()" />
      </div>
      <div class="blockSpan2">
        <h3>Auftraggeber</h3>
        <p>
          {{ inspection.customer.name }}<br />
          {{ inspection.customer.street }}<br />
          {{ inspection.customer.zip }}<br />
          {{ inspection.customer.city }}
        </p>
      </div>
      <div class="blockSpan2">
        <h3>Anlagennutzer</h3>
        <p *ngIf="inspection.aggregates.length > 0">
          {{ inspection.aggregates[0].operator?.name }}<br />
          {{ inspection.aggregates[0].operator?.street }}<br />
          {{ inspection.aggregates[0].operator?.zip }}<br />
          {{ inspection.aggregates[0].operator?.city }}
        </p>
      </div>
      <div class="blockSpan2">
        <h3>Standort</h3>
        <p>
          {{ inspection.location.name }}<br />
          {{ inspection.location.street }}<br />
          {{ inspection.location.zip }}<br />
          {{ inspection.location.city }}
        </p>
      </div>
      <div class="center-vertical">
        <h3>Inspektionsstatus:</h3>
      </div>
      <div class="progressAndActions">
        <backoffice-inspection-progress [status]="inspection.status" />
        <backoffice-inspection-actions class="center-vertical" (changed)="reloadPage()" [inspection]="inspection" />
      </div>
      <ng-container *ngIf="inspection.hygieneInspection">
        <div class="center-vertical">
          <h3>Hygiene-Inspektionsstatus:</h3>
        </div>
        <div class="progressAndActions">
          <backoffice-inspection-progress-hygiene [status]="inspection.hygieneInspection.status" />
          <backoffice-inspection-actions-hygiene
            class="center-vertical"
            (changed)="reloadPage()"
            [inspection]="{
              id: inspection.id,
              inspectionNumber: inspection.inspectionNumber,
              hygieneStatus: inspection.hygieneInspection.status
            }"
          />
        </div>
      </ng-container>
      <ng-container *ngIf="inspection.energeticInspection">
        <div class="center-vertical">
          <h3>Energetisch-Inspektionsstatus:</h3>
        </div>
        <div class="progressAndActions">
          <backoffice-inspection-progress-energetic [status]="inspection.energeticInspection.status" />
          <backoffice-inspection-actions-energetic
            class="center-vertical"
            (changed)="reloadPage()"
            [inspection]="{
              id: inspection.id,
              inspectionNumber: inspection.inspectionNumber,
              energeticStatus: inspection.energeticInspection.status
            }"
          />
        </div>
      </ng-container>
    </mat-card>
    <div class="buttons">
      <button *ngIf="inspection.hasLabRequestBundle" mat-raised-button (click)="downloadLabRequestBundle()">
        Laboranfrage und Beauftragung herunterladen
      </button>
      <button mat-raised-button [disabled]="!isActionPlanAvailable || isLoadingActionPlan" (click)="downloadActionPlan()">
        <span>
          <mat-spinner
            class="InspectionDetail__GenerateActionPlanButton"
            diameter="24"
            mode="indeterminate"
            *ngIf="isLoadingActionPlan"
          ></mat-spinner>
          <mat-icon *ngIf="!isLoadingActionPlan">assignment</mat-icon>
          Einsatzplan herunterladen
        </span>
      </button>

      <button [disabled]="isGeneratingV2" mat-raised-button (click)="generateDocumentation('2')" color="primary">
        <span>
          <mat-spinner
            class="InspectionDetail__GenerateDocumentationButton"
            diameter="24"
            mode="indeterminate"
            *ngIf="isGeneratingV2"
          ></mat-spinner>
          <mat-icon *ngIf="!isGeneratingV2">autorenew</mat-icon>
          Dokumentation generieren
        </span>
      </button>
      <button
        [disabled]="!isDocumentationAvailable || isGeneratingV2 || isLoadingDocumentation"
        mat-raised-button
        color="warn"
        (click)="downloadDocumentation('2')"
      >
        <mat-spinner
          class="InspectionDetail__GenerateDocumentationButton"
          diameter="24"
          mode="indeterminate"
          *ngIf="isLoadingDocumentation"
        ></mat-spinner>
        <mat-icon *ngIf="!isLoadingDocumentation">cloud_download</mat-icon>
        Dokumentation herunterladen
      </button>

      <button
        mat-raised-button
        color="primary"
        [disabled]="!canGeneratePerformanceReport || isGeneratingPerformanceReport"
        (click)="downloadPerformanceReport()"
      >
        <span>
          <mat-spinner
            class="InspectionDetail__GenerateDocumentationButton"
            diameter="24"
            mode="indeterminate"
            *ngIf="isGeneratingPerformanceReport"
          ></mat-spinner>
          <mat-icon *ngIf="!isGeneratingPerformanceReport">assignment_returned</mat-icon>
          Leistungsnachweis herunterladen
        </span>
      </button>
    </div>
  </div>

  <backoffice-internal-notes [inspection]="inspection" />

  <div>
    <backoffice-aggregate-table [aggregates]="inspection.aggregates" (downloadReport)="downloadReport($event)" />
  </div>
</div>

<ng-template #loadingTemplate> Lädt... </ng-template>
