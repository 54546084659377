import { Component, Input } from '@angular/core';
import { HygieneInspectionStatus } from '../../../shared/models';
import { SearchableInspectionResponse } from '../../../shared/models/CognitiveSearchResponse';


@Component({
  selector: 'backoffice-inspection-status-hygiene',
  templateUrl: './inspection-status-hygiene.component.html',
  styleUrls: ['./inspection-status-hygiene.component.scss']
})
export class InspectionStatusHygieneComponent {
  @Input() inspection!: SearchableInspectionResponse;

  readonly statusList = HygieneInspectionStatus;
}
