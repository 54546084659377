import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
} from '@angular/core';

import { MatDatepickerInput } from '@angular/material/datepicker';
import moment from 'moment';
import { takeUntil, switchMap, map, catchError } from 'rxjs/operators';
import { Subject, EMPTY } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

import { HttpErrorResponse } from '@angular/common/http';
import {
  InspectionStatus,
} from '../../../shared/models';
import { InspectionService } from '../../services';
import { NotificationService } from '../../../services/notification.service';
import { KeycloakRole } from '../../../models';

@Component({
  selector: 'backoffice-start-date-assignment',
  templateUrl: './start-date-assignment.component.html',
  styleUrls: ['./start-date-assignment.component.scss'],
})
export class StartDateAssignmentComponent implements OnInit, OnDestroy {
  @Input() inspection!: {
    id: string;
    status: InspectionStatus;
    startDate: string;
  };
  @Output() changed = new EventEmitter<string>();
  @ViewChild(MatDatepickerInput, { static: true })
  input!: MatDatepickerInput<moment.Moment>;

  _destroy$ = new Subject<void>();

  constructor(
    private readonly inspectionService: InspectionService,
    private readonly notificationService: NotificationService,
    private readonly keycloakService: KeycloakService
  ) {}

  startDate!: string;
  editable = false;

  ngOnInit() {
    this.startDate = this.inspection.startDate;
    this.editable =
      this.inspection.status !== InspectionStatus.SHIPPED_TO_CUSTOMER &&
      this.keycloakService.isUserInRole(KeycloakRole.BACKOFFICE_PLANNER);

    if (this.editable) {
      this.input.dateChange
        .pipe(
          takeUntil(this._destroy$),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          map((target) => target!.value!.format('YYYY-MM-DD')),
          switchMap((date) => {
            return this.inspectionService
              .updateStartDate(this.inspection.id, date)
              .pipe(
                // return the new date afterwards
                map(() => date),
                catchError((e) => {
                  const httpError = e as HttpErrorResponse;
                  const forbidden = 403; // HttpStatusCode.Forbidden
                  if (httpError.status === forbidden) {
                    // User is not allowed to change the inspection date
                    this.notificationService.errorMessage(
                      'Ihnen fehlt die Berechtigung das Start-Datum zu ändern!'
                    );
                  } else {
                    this.notificationService.errorMessage(
                      'Fehler beim Ändern des Start-Datums! Fehlercode ' +
                        httpError.status
                    );
                  }
                  return EMPTY;
                })
              );
          })
        )
        .subscribe((date) => {
          this.startDate = date;
          this.changed.emit(date);
        });
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
