import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogData } from '../confirm-dialog/confirm-dialog.component';
import { switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { generateChangeEnergeticStatusMenuItems } from './inspection-actions-energetic.utils';
import { EnergeticInspectionStatus } from '../../../shared/models';
import { InspectionActionEvent, InspectionActionEventType } from '../../models';
import { InspectionService } from '../../services';
import { NotificationService } from '../../../services/notification.service';
import { KeycloakRole } from '../../../models';

interface ActionDataEnergetic {
  id: string;
  inspectionNumber: string;
  energeticStatus?: EnergeticInspectionStatus;
}

@Component({
  selector: 'backoffice-inspection-actions-energetic',
  templateUrl: './inspection-actions-energetic.component.html',
})
export class InspectionActionsEnergeticComponent implements OnChanges {
  @Input() inspection!: ActionDataEnergetic;
  @Output() changed = new EventEmitter<InspectionActionEvent>();

  menuEnabled = false;
  canDelete = false;
  statusChangeMenuItems: EnergeticInspectionStatus[] = [];

  constructor(
    private readonly inspectionService: InspectionService,
    private readonly keycloakService: KeycloakService,
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const inspection = changes['inspection'].currentValue as ActionDataEnergetic;
    if (inspection && inspection.energeticStatus) {
      this.statusChangeMenuItems = generateChangeEnergeticStatusMenuItems(this.keycloakService, inspection.energeticStatus);
      const canChangeStatus = this.statusChangeMenuItems.length > 0;

      const isAdmin = this.keycloakService.isUserInRole(KeycloakRole.BACKOFFICE_ADMIN);

      // Admin can delete everything, planner only pending energetic inspections
      this.canDelete = isAdmin;

      this.menuEnabled = this.canDelete || canChangeStatus;
    }
  }

  handleMenuClick(event: MouseEvent) {
    event.stopPropagation();
  }

  setEnergeticInspectionStatus(status: EnergeticInspectionStatus) {
    this.inspectionService.updateEnergeticInspectionStatus(this.inspection.id, status).subscribe(
      (_value) => {
        this.changed.emit({ type: InspectionActionEventType.UPDATED });
      },
      (errorResponse) => {
        const httpStatus = errorResponse.error.status;
        let msg: string;
        if (httpStatus === 403) {
          // FORBIDDEN
          msg = 'Diese Statusänderung ist im aktuellen Status der Energetischen Inspektion nicht möglich!';
        } else if (httpStatus === 400) {
          // BAD_REQUEST
          msg = 'Diese Statusänderung ist für Energetischen Inspektion nicht möglich!';
        } else {
          msg = 'Statusänderung für die Energetischen Inspektion ist fehlgeschlagen.';
        }

        this.notificationService.errorMessage(msg);
      }
    );
  }

  deleteEnergeticInspection() {
    this.dialog
      .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
        data: {
          cancel: 'Abbrechen',
          confirm: 'Energetischen Inspektion löschen',
          content: `Soll die Energetischen Inspektion dieser Inspektion wirklich gelöscht werden? Diese Aktion kann nicht wieder rückgängig gemacht werden.`,
          title: `Energetischen Inspektion von Inspektion ${this.inspection.inspectionNumber} löschen`,
        },
      })
      .afterClosed()
      .pipe(
        switchMap((confirmed) => {
          if (confirmed === true) {
            return this.inspectionService.deleteEnergeticInspection(this.inspection.id);
          }
          return EMPTY;
        })
      )
      .subscribe(
        () => {
          this.changed.emit({ type: InspectionActionEventType.DELETED });
          this.notificationService.successMessage('Energeticinspektion erfolgreich gelöscht.');
        },
        () => {
          this.notificationService.errorMessage('Die Energeticinspektion konnte nicht gelöscht werden, bitte Zustand überprüfen.');
        }
      );
  }
}
