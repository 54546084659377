import {
  Component,
  OnInit,
  Input,
  forwardRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import * as moment from 'moment';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const MIN_DATE = moment.utc('2000-01-01T00:00:00.000Z');

const minDateValidator = (
  control: AbstractControl
): {
  [key: string]: unknown;
} | null => {
  if (!control.value) {
    return null;
  }
  return control.value >= MIN_DATE ? null : { invalid: true };
};

@Component({
  selector: 'backoffice-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateComponent),
      multi: true,
    },
  ],
})
export class DateComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild(MatDatepickerInput, { static: true })
  input!: MatDatepickerInput<moment.Moment>;
  @Input() label!: string;

  _date!: moment.Moment | null;
  get date() {
    return this._date;
  }
  set date(val) {
    this._date = val;
    this.propagateChange(this._date);
  }

  dateControl!: FormControl;

  _destroy$ = new Subject<void>();

  ngOnInit() {
    this.dateControl = new FormControl('', [minDateValidator]);
    this.input.dateChange.pipe(takeUntil(this._destroy$)).subscribe((d) => {
      if (this.dateControl.valid) {
        this.date = d.value;
      }
    });
  }

  // long variable name prevent istanbul from breaking snapshots https://github.com/thymikee/jest-preset-angular/issues/85
  dateFilter = (momentDateToCompare: moment.Moment): boolean => {
    return momentDateToCompare?.isSameOrAfter(MIN_DATE);
  };

  propagateChange = (_: unknown) => {
    /* empty on purpose */
  };

  registerOnChange(fn: (_: unknown) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
    /* empty on purpose */
  }

  writeValue(value: moment.Moment) {
    this.date = value;
    if (this.dateControl) {
      this.dateControl.setValue(this.date);
    }
  }

  clear() {
    this.dateControl.setValue('');
    this.date = null;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
