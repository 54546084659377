<div class="mat-elevation-z2">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && areAllSelected()"
          [indeterminate]="selection.hasValue() && !areAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [disabled]="row.alreadyInLaterInspection || row.shutdown !== 0"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="internalNote">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear></th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.internalNote" [matTooltip]="element.internalNote">info</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="atwId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>ATW-ID</th>
      <td mat-cell *matCellDef="let element">{{ element.atwId }}</td>
    </ng-container>

    <ng-container matColumnDef="typeDisplayName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Sub-Typ</th>
      <td mat-cell *matCellDef="let element">{{ element.typeDisplayName }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Anlagen-Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="equipmentNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>EQ-Nr.</th>
      <td mat-cell *matCellDef="let element">{{ element.equipmentNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="building">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Gebäude</th>
      <td mat-cell *matCellDef="let element">{{ element.building }}</td>
    </ng-container>

    <ng-container matColumnDef="nextInspectionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Datum Wied.-Inspektion</th>
      <td mat-cell *matCellDef="let element">
        {{ element.nextInspectionDate | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="remainingDays">
      <th mat-header-cell *matHeaderCellDef>verbl. Tage</th>
      <td
        mat-cell
        *matCellDef="let element"
        [backofficeRemainingDays]="element.alreadyInLaterInspection ? null : element.nextInspectionDate"
      >
        {{ element.shutdown !== 0 ? 'stillgelegt' : element.alreadyInLaterInspection ? 'verplant' : '' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="element-row"
      [class.disabled]="row.alreadyInLaterInspection"
      [class.shutdown]="row.shutdown !== 0"
    ></tr>
  </table>
</div>
