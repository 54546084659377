<mat-form-field subscriptSizing="dynamic">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [formControl]="aggregateSubTypeControl"
    multiple
    panelClass="searchbar-list AggregateSubTypeComponent__Panel"
  >
    <mat-option
      *ngFor="let subTypeItem of aggregateSubTypes"
      [value]="subTypeItem"
    >
      {{ 'aggregates.subTypes.' + subTypeItem + '.text' | enum }}
    </mat-option>
  </mat-select>
</mat-form-field>
