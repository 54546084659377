import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InspectionsRoutingModule } from './inspections-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { InspectionsPageComponent } from './inspections-page/inspections-page.component';
import { RecurrentInspectionsPageComponent } from './recurrent-inspections-page/recurrent-inspections-page.component';
import { SubnavigationComponent } from '../inspections/components/subnavigation/subnavigation.component';
import { RemainingDaysDirective } from '../inspections/directives/remaining-days.directive';
import { PlanningExcelUploadButtonComponent } from './components/planning-excel-upload-button/planning-excel-upload-button.component';
import { RecurrentInspectionDetailComponent } from './recurrent-inspections-page/recurrent-inspection-detail/recurrent-inspection-detail.component';
import { RecurrentAggregateTableComponent } from './recurrent-inspections-page/recurrent-inspection-detail/recurrent-aggregate-table/recurrent-aggregate-table.component';
import { PlannedInspectionsPageComponent } from './planned-inspections-page/planned-inspections-page.component';
import { InspectionActionsComponent } from './components/inspection-actions/inspection-actions.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';

import { InspectorAssignmentComponent } from './components/inspector-assignment/inspector-assignment.component';
import { InspectorAssignmentDialogComponent } from './components/inspector-assignment-dialog/inspector-assignment-dialog.component';
import { InspectionStatusComponent } from './components/inspection-status/inspection-status.component';
import { InspectionDetailComponent } from './inspections-page/inspection-detail/inspection-detail.component';
import { AggregateTableComponent } from './inspections-page/inspection-detail/aggregate-table/aggregate-table.component';
import { InspectionProgressComponent } from './components/inspection-progress/inspection-progress.component';
import { AggregateIssuesComponent } from './inspections-page/inspection-detail/aggregate-issues/aggregate-issues.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AggregateValidationsComponent } from './inspections-page/inspection-detail/aggregate-validations/aggregate-validations.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { PlanningExcelDownloadButtonComponent } from './components/planning-excel-download-button/planning-excel-download-button.component';
import { StatusComponent } from './components/search-bar/status/status.component';
import { StartDateAssignmentComponent } from './components/start-date-assignment/start-date-assignment.component';
import { InternalNotesComponent } from './components/internal-notes/internal-notes.component';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { MergeReportDialogComponent } from './components/merge-report-dialog/merge-report-dialog.component';
import { InspectionStatusHygieneComponent } from './components/inspection-status-hygiene/inspection-status-hygiene.component';
import { StatusHygieneComponent } from './components/search-bar/status-hygiene/status-hygiene.component';
import { InspectionProgressHygieneComponent } from './components/inspection-progress-hygiene/inspection-progress-hygiene.component';
import { InspectionActionsHygieneComponent } from './components/inspection-actions-hygiene/inspection-actions-hygiene.component';
import { InspectionActionsEnergeticComponent } from './components/inspection-actions-energetic/inspection-actions-energetic.component';
import { InspectionProgressEnergeticComponent } from './components/inspection-progress-energetic/inspection-progress-energetic.component';
import { InspectionStatusEnergeticComponent } from './components/inspection-status-energetic/inspection-status-energetic.component';
import { StatusEnergeticComponent } from './components/search-bar/status-energetic/status-energetic.component';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    InspectorAssignmentDialogComponent,
    MergeReportDialogComponent,
    RemainingDaysDirective,

    AggregateIssuesComponent,
    AggregateTableComponent,
    AggregateValidationsComponent,
    ConfirmDialogComponent,
    InspectionActionsComponent,
    InspectionDetailComponent,
    InspectionProgressComponent,
    InspectionStatusComponent,
    InspectionsPageComponent,
    InspectionStatusHygieneComponent,
    InspectionStatusEnergeticComponent,
    InspectionProgressHygieneComponent,
    InspectionProgressEnergeticComponent,
    InspectorAssignmentComponent,
    InspectorAssignmentDialogComponent,
    PlannedInspectionsPageComponent,
    PlanningExcelUploadButtonComponent,
    RecurrentAggregateTableComponent,
    RecurrentInspectionDetailComponent,
    RecurrentInspectionsPageComponent,
    SearchBarComponent,
    StatusHygieneComponent,
    StatusEnergeticComponent,
    SubnavigationComponent,
    PlanningExcelDownloadButtonComponent,
    StatusComponent,
    StartDateAssignmentComponent,
    InternalNotesComponent,
    InspectionActionsHygieneComponent,
    InspectionActionsEnergeticComponent,
  ],
  imports: [BrowserAnimationsModule, CommonModule, InspectionsRoutingModule, ReactiveFormsModule, MaterialModule, SharedModule],
})
export class InspectionsModule {}
